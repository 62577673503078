.hamburger-menu-wrapper {
}
.hamburger-menu {
    border: 0;
    margin: 0 auto;
    display: block;
    position: absolute;
    overflow: hidden;
    padding: 0;
    width: 36px;
    height: 36px;
    font-size: 0;
    cursor: pointer;
    z-index: 9999;
    cursor: pointer;
    background: transparent;
}
.open-arrow-inpt {
    margin-left: 130px;
    max-width: 500px;
}
.open-arrow {
    position: absolute;
    left: 0;
}
.open-arrow > a {
    font-size: 50px !important;
    line-height: 100px !important;
    top: -36px !important;
    position: relative !important;
    width: 100px !important;
    height: auto !important;
    color: #013368 !important;
}
.four-blk .item.crc-incld-new {
    text-align: left;
    background: #fff;
}
.hamburger-menu-wrapper.bounce-effect {
    animation: bounce 0.3s ease 1;
}
.menu-overlay {
    transition: margin 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    display: none;
    position: absolute;
    top: -100px;
    bottom: 0;
    right: 0;
    left: 0;
    background: url(../images/popup-bg.png) !important;
    height: 100vh;
    width: 100%;
    background-size: cover !important;
    z-index: 99999;
}
.top-bg #menu-overlay1 {
    top: -135px;
}
.menu {
    position: relative;
    min-width: 220px;
    text-align: center;
    top: -100px;
}
.new-mn {
    position: absolute;
    top: 135px;
    width: 100%;
    left: 0px;
    height: 100vh;
}
.menu-list {
    display: none;
    position: absolute;
    top: calc(100% + 103px);
    width: 100%;
    text-align: center;
    z-index: 9999;
}
.menu-list a {
    color: #013368;
    text-decoration: none;
    font-size: 18px;
    display: inline-block;
    margin: 15px 0;
    transition: all 0.5s ease;
}
.menu-list a:hover {
    color: #02d5fd;
}
.hamburger-menu:focus {
    outline: none;
}
.hamburger-menu span {
    display: block;
    position: absolute;
    top: 17px;
    left: 5px;
    right: 5px;
    height: 2px;
    background: #02d5fd;
}
.hamburger-menu span:before,
.hamburger-menu span:after {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #02d5fd;
    content: "";
}
.hamburger-menu span:before {
    top: -7px;
}
.hamburger-menu span:after {
    bottom: -7px;
}
.hamburger-menu span:before,
.hamburger-menu span:after {
    transition-duration: 0.3s, 0.3s;
    transition-delay: 0.3s, 0s;
}
.hamburger-menu span:before {
    transition-property: top, transform;
}
.hamburger-menu span::after {
    transition-property: bottom, transform;
}
.hamburger-menu.active span {
    background: none;
}
.hamburger-menu.active span:before {
    top: 0;
    transform: rotate(225deg);
}
.hamburger-menu.active span:after {
    bottom: 0;
    transform: rotate(135deg);
}
.hamburger-menu.active span:before,
.hamburger-menu.active span:after {
    transition-delay: 0s, 0.3s;
}
.find_records {
    margin: 2px;
}
@keyframes bounce {
    0% {
        transform: rotate(0);
    }
    45% {
        transform: rotate(15deg);
    }
    90% {
        transform: rotate(-7deg);
    }
    100% {
        transform: rotate(0);
    }
}
