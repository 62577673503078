@font-face {
    src: local("Arial"), format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    font-family: "Arial", sans-serif;
}
* {
    padding: 0px;
    margin: 0px;
}
button:focus {
    outline: none;
}
ul {
    padding: 0px;
    margin: 0px;
}
li {
    list-style: none;
}
html {
    scroll-behavior: smooth;
}
body {
    font-family: Arial, sans-serif;
    transition: all 0.3s ease 0s;
}

.vert-move2 {
    -webkit-animation: mover 1s infinite alternate;
    animation: movernew 1s infinite alternate;
}
@-webkit-keyframes movernew {
    0% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes movernew {
    0% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0px);
    }
}
.vert-move {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
}
.vert-move {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
}
@-webkit-keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-10px);
    }
}
@keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-10px);
    }
}
.vert-move1 {
    -webkit-animation: moverr 1s infinite alternate;
    animation: mover 1s infinite alternate;
}
.vert-move1 {
    -webkit-animation: moverr 1s infinite alternate;
    animation: moverr 1s infinite alternate;
}
@-webkit-keyframes moverr {
    0% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes moverr {
    0% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(1);
    }
}
#back-to-top {
    display: inline-block;
    position: fixed;
    bottom: 30px;
    right: 70px;
    transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    transform: rotate(270deg);
}
#back-to-top:hover {
    cursor: pointer;
}
#back-to-top:active {
    opacity: 0.5;
}
#back-to-top.show {
    opacity: 1;
    visibility: visible;
    z-index: 999999;
}
.height-50 {
    height: 50px;
}
.height300 {
    height: 300px;
}
.ctc-btn {
    transition: all 1s;
}
.ctc-btn:hover {
    box-shadow: none;
}
.container {
    width: 100%;
    max-width: 1310px;
}
a {
    color: #141541;
    transition: all 0.3s ease 0s;
    text-decoration: none !important;
}
h1,
h2,
h3,
h4,
h5 {
    font-family: Arial, sans-serif;
    font-weight: bold;
}
.rating {
    display: inline-block;
    position: relative;
    height: 50px;
    line-height: 50px;
    font-size: 50px;
}
.rating label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    cursor: pointer;
}
.rating label:last-child {
    position: static;
}
.rating label:nth-child(1) {
    z-index: 5;
}
.rating label:nth-child(2) {
    z-index: 4;
}
.rating label:nth-child(3) {
    z-index: 3;
}
.rating label:nth-child(4) {
    z-index: 2;
}
.rating label:nth-child(5) {
    z-index: 1;
}
.rating label input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}
.rating label .icon {
    float: left;
    color: transparent;
}
.rating label:last-child .icon {
    color: #000;
}
.rating:not(:hover) label input:checked ~ .icon,
.rating:hover label:hover input ~ .icon {
    color: #09f;
}
.rating label input:focus:not(:checked) ~ .icon:last-child {
    color: #000;
    text-shadow: 0 0 5px #09f;
}
.our-professionals.professionals-profile li {
    width: 24%;
}
.review-cont-blk ul li {
    display: inline-block;
    float: left;
}
.navbar {
    padding: 20px 0;
}
.navbar-light .navbar-nav .nav-link {
    color: #344356;
    font-size: 18px;
    font-weight: bold;
    margin: 0 5px;
}
.bdg-nav {
    background: #ffd700;
    color: #344356;
    font-size: 14px;
    padding: 1.5px 8px;
    border-radius: 20px;
    position: relative;
    top: -7px;
}
.top-bg {
    /*background:url(../images/bg-1.png);*/
    background-size: cover;
    background-position: top right;
    position: relative;
    background-attachment: fixed;
    background: transparent;
}
.top-bg-banner {
    /* background: url(../images/bg-1.png) no-repeat; */
    background-size: 120% 120%;
    background-position: top right;
    position: relative;
    background-attachment: fixed;
}
.top-bg::after {
    content: "";
    background: url(../images/dotedt-bg-1.png);
    position: absolute;
    left: 0;
    top: 34%;
    width: 62px;
    z-index: 999999;
    height: 100%;
    background-repeat: no-repeat;
}
.navbar-nav {
    margin-top: 18px;
}
.top-hed .sinc {
    color: #013368;
    font-size: 20px;
    z-index: 99;
    text-transform: uppercase;
    font-family: Arial, sans-serif;
    font-weight: bold;
    position: relative;
    padding-right: 20px;
    bottom: -8px;
}
.top-hed .sinc::after {
    content: "";
    position: absolute;
    background: #78ffd1;
    width: 100%;
    height: 23px;
    left: 0;
    bottom: 5px;
    z-index: -9;
}
.top-hed h1 {
    color: #013368;
    font-size: 47px;
    text-transform: uppercase;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 47px;
}
.ttl-arrw h5 {
    padding-right: 20px;
    color: #013368;
    font-size: 42px;
    text-transform: uppercase;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 44px;
}
.arro-img {
    position: absolute;
    right: -48px;
    top: 0px;
    z-index: 999;
}
.top-hed {
    margin-top: 0px;
}
.img-bdg {
    margin-top: 0px;
    width: 300px;
}
.yl-botbod {
    z-index: 99;
    position: relative;
}
.yl-botbod::after {
    content: "";
    position: absolute;
    width: 105%;
    height: 10px;
    background: #ffd700;
    left: -8px;
    bottom: 6px;
    border-radius: 10px;
    z-index: -9;
}
.bnr-cont {
    color: #013368;
    font-size: 21px;
    font-weight: normal;
    margin: 0px 0 0 0;
    line-height: 23px;
}
.arro-blk {
    position: relative;
    margin-top: 30px;
}
.spn-bdg {
    position: relative;
    color: #013368;
    font-family: Arial, sans-serif;
    font-size: 15px;
    line-height: 20px;
    background: #ffea78 !important;
    text-transform: uppercase;
    padding: 2px 13px;
    border-radius: 20px;
    bottom: 0px;
}
.three-blk {
    position: relative;
    min-height: 155px;
    max-height: 155px;
    -webkit-box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
}
.yel-blk {
    background: #ffea78;
}
.three-blk h5 {
    color: #013368;
    font-size: 16px;
    text-transform: uppercase;
    font-family: Arial, sans-serif;
    font-weight: bold;
    padding: 12px;
    max-width: 135px;
    line-height: 28px;
    z-index: 9999999;
    position: relative;
}
.thre-rghimg img {
    max-width: 65%;
}
.thre-rghimg {
    position: absolute;
    right: -70px;
    top: -51px;
}
.blue-blk {
    background: #93d8f3;
}
.grn-blk {
    background: #78ffd1;
}
.butnr {
    position: relative;
    margin: 20px 0px;
}
.butnr a {
    color: #013368;
    font-size: 20px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    padding: 10px 20px;
    background: #78ffd1;
    border-radius: 40px;
    -webkit-box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
}
.contr-section {
    margin: 0;
    background: url(../images/bg-2.png);
    background-position-x: 0%;
    background-position-y: 0%;
    background-size: auto;
    background-size: cover;
    background-position: top right;
    position: relative;
    min-height: 250px;
    align-content: center;
    display: flex;
}
.contr-section-lg {
    margin: 0;
    background: url(../images/bg-2.png);
    background-position-x: 0%;
    background-position-y: 0%;
    background-size: auto;
    background-size: cover;
    background-position: top right;
    position: relative;
    min-height: 250px;
    align-content: center;
    display: flex;
}
.contr-section::after {
    content: "";
    background: url(../images/dotedt-bg-2.png);
    background-repeat: repeat;
    position: absolute;
    left: 0;
    top: 23%;
    width: 67px;
    z-index: 999999;
    height: 100%;
    background-repeat: no-repeat;
}
.contr-section::before {
    content: "";
    background: url(../images/dotedt-bg-3.png);
    position: absolute;
    right: 0;
    top: 45%;
    width: 80px;
    z-index: 999999;
    height: 100%;
    background-repeat: no-repeat;
}
.count {
    position: relative;
    padding: 10px;
    display: flex;
    align-items: center;
}
.count img {
    float: left;
    margin-right: 20px;
    width: auto;
    height: auto;
}
.count-hd h5 {
    color: #013368;
    font-size: 32px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
}
.count-hd p {
    color: #013368;
    font-size: 23px;
    font-family: Arial, sans-serif;
    line-height: 25px;
    margin: 0;
    position: relative;
    top: -7px;
}
.why-any-section {
    background: url(../images/background-2.png);
    background-size: cover;
    background-position: top right;
    position: relative;
    color: #e3e3e3;
    padding-bottom: 40px;
}
.title-42 {
    font-size: 42px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    margin: 80px 0 20px 0;
}
.why-li {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    float: left;
    padding: 10px 10px;
}
.why-li p {
    font-size: 20px;
    padding: 10px 0px 0px 20px;
    font-weight: bold;
}
.font-48 {
    color: #013368;
    font-size: 48px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    line-height: 50px;
}
.blue-italics {
    color: #013368;
    font-style: italic;
}
button.owl-next,
button.owl-prev {
    font-size: 20px !important;
    margin: 0 20px;
}
.owl-nav {
    text-align: center;
    display: none;
}
.start-tr {
    display: inline-block;
    font-size: 18px;
    color: #fbe0b7;
    cursor: pointer;
}
.rat-count {
    color: #dbdbdb;
    font-weight: bold;
    cursor: auto;
}
.rated {
    color: #f39b17;
}
.about-innerblk {
    -webkit-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    min-height: 355px;
    padding: 0 30px;
}
.about-innerblk-video {
    -webkit-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 10px 10px;
}
.item.about-blk {
    padding: 20px;
}
.std-img {
    display: flex;
    width: 100%;
    align-content: center;
    align-items: center;
}
.std-img img {
    width: 89px !important;
    height: auto !important;
    display: inline-block !important;
    border-radius: 10px;
}
.std-img a {
    float: right;
    display: block;
    width: 100%;
    text-align: right;
}
.std-detail {
    clear: both;
    text-align: left;
}
.std-detail h4 {
    color: #1f1f1f;
    font-size: 18px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    margin: 0;
    padding: 15px 0 0 0;
}
.std-detail h5 {
    color: #1f1f1f;
    font-size: 13px;
    margin: 5px 0;
}
.std-detail p {
    color: #767676;
    font-size: 14px;
    line-height: 25px;
    margin: 0 0 10px 0;
    max-height: 70px;
    overflow: hidden;
}
.sign_in_box_parent .std-detail p {
    max-height: 58px;
}
.std-detail a {
    font-size: 15px;
    display: block;
    font-size: 20px;
    line-height: 29px;
    width: 100%;
    color: #0951d4;
    text-decoration: underline !important;
}
.placement-link {
    color: #0951d4;
    cursor: pointer;
    text-decoration: underline !important;
}
.placement-bold {
    color: #013368;
    font-weight: bold;
}
.placement-ul li {
    color: #2f2f2f;
    line-height: 20px;
    margin: 10px 0px;
    position: relative;
    padding-left: 25px;
}
.placement-ul li::after {
    top: 0px;
    content: "\2022";
    position: absolute;
    left: 0 !important;
    font-size: 36px;
    color: #27b2e7;
}
.placement-ul li.blcr::after {
    color: #013368;
}
.lnk-img {
    width: 75px !important;
    height: auto !important;
}
.linkd {
    position: absolute;
    top: -50px;
    right: -55px;
}
.checkout-mr {
    position: relative;
}
.checkout-mr a {
    color: #013368;
    font-size: 22px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    padding: 10px 30px;
    background: #78ffd1;
    line-height: 40px;
    border-radius: 40px;
    -webkit-box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    display: block;
    max-width: 310px;
    margin: 0 auto;
}
.say-about {
    position: relative;
}
.say-about::after {
    content: "";
    background: url(../images/dotedt-bg-2.png);
    background-repeat: repeat;
    background-repeat: repeat;
    position: absolute;
    left: 0;
    top: 40px;
    width: 67px;
    z-index: 999999;
    height: 100%;
    background-repeat: no-repeat;
}
.say-about::before {
    content: "";
    background: url(../images/dotedt-bg-3.png);
    background-repeat: repeat;
    background-repeat: repeat;
    position: absolute;
    left: 0;
    top: 43%;
    width: 80px;
    z-index: 999999;
    height: 100%;
    background-repeat: repeat-x;
    z-index: -99999;
}
.blk-cont {
    color: #013368;
    font-size: 25px;
    font-weight: normal;
    text-align: left;
    line-height: 30px;
}
.modes-blk {
    position: relative;
}
.modes-cont p {
    color: #858585;
    font-size: 15px;
    line-height: 20px;
    margin: 0px;
}
.ttl-arrw .arro-img {
    right: 15px;
    top: 25px;
}
.modes-innerblk {
    position: relative;
}
.modes-innerblk h2 {
    position: absolute;
    bottom: 6px;
    left: 20px;
    color: #fff;
    font-family: Arial, sans-serif;
    font-weight: bold;
}
.modes-cont {
    padding: 20px;
    border: 1px solid #e1e1e1;
    margin-top: -1px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.upcoming-batch {
    position: relative;
    margin: 100px 0px;
}
.upcmg-btcblk {
    -webkit-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 30px 60px;
    position: relative;
}
.upcmg-btcblk ul li {
    display: inline-block;
    position: relative;
}
.upcmg-img {
    max-width: 148px;
    position: relative;
    top: 0px;
    left: -10px;
}
.up-date {
    text-align: center;
    float: left;
    width: 84px;
    font-size: 42px;
    font-weight: bold;
    color: #013368;
}
.up-date span {
    display: block;
    margin: 0;
    padding: 0;
    line-height: 0;
    font-size: 25px;
    color: #27b2e7;
}
.up-detail {
    padding-left: 20px;
    float: left;
    text-align: left;
}
.up-detail h5 {
    padding-top: 10px;
    font-size: 30px;
    color: #013368;
    font-weight: bold;
}
.up-detail p {
    font-size: 25px;
    color: #858585;
    margin: -10px 0 0 0;
}
.logos-head {
    color: #013368;
    font-size: 33px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    padding: 15px;
    text-transform: uppercase;
}
.all-logos {
    position: relative;
    margin: 100px auto;
    text-align: center;
    margin-bottom: 50px;
}
.all-logos-img {
    margin-top: 40px;
    width: 100%;
    height: 100%;
    max-width: 1108px;
}
.all-logos:after {
    content: "";
    background: url(../images/dotedt-bg-3.png);
    background-repeat: repeat;
    position: absolute;
    right: 0;
    top: 25%;
    width: 80px;
    z-index: 999999;
    height: 100%;
    background-repeat: no-repeat;
}
.all-logos::before {
    content: "";
    background: url(../images/dotedt-bg-2.png);
    background-repeat: repeat;
    background-repeat: repeat;
    background-repeat: repeat;
    position: absolute;
    left: 0;
    top: -20px;
    width: 67px;
    z-index: 999999;
    height: 100%;
    background-repeat: no-repeat;
}
.many-mor {
    color: #013368;
    font-size: 33px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    padding: 15px;
    text-decoration: none;
    margin: 40px 0;
    display: block;
}
.contact-form {
    position: relative;
}
.contact-form::before {
    content: "";
    background: url(../images/dotedt-bg-3.png);
    background-repeat: repeat;
    background-repeat: repeat;
    position: absolute;
    left: 0;
    top: 25%;
    width: 80px;
    z-index: 999999;
    height: 180px;
}
.call-usblk {
    position: relative;
    background: url(../images/call-us-form.png);
    background-repeat: no-repeat !important;
    width: 700px;
    min-height: 708px;
    background-size: 100%;
}
.call-inner {
    margin: 0 40px;
    text-align: center;
}
.call-inner-text {
    text-align: center;
    font-size: "20px";
    padding: 30px;
    padding-top: 160px;
    padding-bottom: 0px;
}
.call-usblk h4 {
    color: #013368;
    font-size: 52px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    padding: 15px 0 0 0;
    text-decoration: none;
    margin: 4px;
}
.call-usblk h5 {
    color: #013368;
    font-size: 38px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    text-decoration: none;
}
.call-usblk p {
    color: #013368;
    font-size: 22px;
    line-height: 25px;
    text-decoration: none;
}
.call-inner {
    margin: 0px 0px 0px 20px;
    text-align: center;
    max-width: 600px;
    padding-top: 43px;
}
.call-usbtn {
    color: #013368;
    font-size: 22px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    padding: 10px 20px;
    background: #78ffd1;
    line-height: 40px;
    border-radius: 40px;
    -webkit-box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    display: block;
    max-width: 258px;
    margin: 50px auto;
}
.call-usbtn-lg {
    color: #ffffff;
    font-size: 22px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: initial;
    background: #ff4800;
    line-height: 40px;
    border-radius: 40px;
    -webkit-box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    display: block;
    margin: 50px auto;
    width: 100%;
}
.pg-diploma-signup-usbtn{
    color: #ffffff;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: initial;
    background: #25B2E7;
    padding: 5px 0px;
    border-radius: 6px;
    -webkit-box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    display: block;
    margin: 50px auto;
    width: 70%;
}
.call-bck-form {
    -webkit-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    min-height: 355px;
    padding: 0 30px;
}
.blog-section {
    margin: 100px auto;
    position: relative;
}
.blogs-blk {
    -webkit-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 0 0 15px 0;
    display: block;
    margin: 11px;
    min-height: 300px;
}
.blogs-blk h2 {
    color: #013368;
    font-size: 23px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    text-decoration: none;
    padding: 15px;
    margin: 0 0 15px 0;
    max-height: 100px;
    overflow: hidden !important;
    height: 100px;
}
.blogs-link {
    font-size: 21px;
    line-height: 24px;
    color: #2b5fcc;
    text-decoration: none;
    padding: 15px;
}
.industr-blk {
    -webkit-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 0 0 10px 0;
    display: block;
    margin: 11px;
}
.industr-blk h5 {
    color: #1b1e21;
    font-size: 13px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    text-decoration: none;
    padding: 15px;
    margin: 0;
    min-height: 80px;
}
.industr-blk .industr-link {
    color: #013368;
    font-size: 19px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    padding: 15px;
    min-height: 40px;
    display: block;
    width: 198px;
    background: #ffd700;
    margin: 0 auto 20px;
    border-radius: 40px;
}
.contact-cta {
    background: url(../images/cont-cta.png);
    max-width: 1311px;
    background-size: cover;
    background-repeat: no-repeat;
}
.contact-cta-img {
    position: absolute;
    top: -160px;
    left: -33px;
    max-width: 170px;
}
.contact-cta-detail h5 {
    color: #fff;
    font-size: 32px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 36px;
}
.contact-cta-detail h4 {
    color: #fff;
    font-size: 28px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 36px;
}
.contact-cta-detail p {
    color: #fff;
    font-size: 25px;
    font-family: Arial, sans-serif;
    line-height: 36px;
    font-style: italic;
    margin: -10px 0 0 0;
}
.footer-section {
    margin: 90px auto 45px;
}
.footer-ul li {
    display: inline-block;
    color: #939393;
    font-size: 17px;
    line-height: 30px;
    position: relative;
    width: 203px;
    float: left;
    margin-right: 25px;
}
.footer-ul li h4 {
    bottom: 6px;
    color: #013368;
    font-family: Arial, sans-serif;
    font-weight: bold;
    margin-bottom: 20px;
}
.ft-link {
    display: block;
    font-size: 17px;
    color: #939393;
}
.footer-ul {
    margin: 50px 0;
}
.copy-right {
    color: #939393;
    font-size: 17px;
    line-height: 30px;
}
.copy-right span {
    font-weight: bold;
    color: #013368;
}
.right-ft h5 {
    color: #013368;
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
}
.right-ft h5 > span {
    color: #5ac7ff;
}
.ft-call {
    background: #ffd700;
    padding: 10px 25px;
    padding-left: 25px;
    border-radius: 40px;
    margin: 5px 0 25px;
    display: block;
    max-width: 235px;
    color: #013368;
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    position: relative;
}
.ft-call-lg {
    background: #ffd700;
    padding: 10px 25px;
    padding-left: 25px;
    border-radius: 40px;
    margin: 5px 0 25px;
    display: block;
    max-width: 30%;
    color: #013368;
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    position: relative;
}
.ft-call-pg-diploma {
    background: #FFBB00;
    padding: 10px 25px;
    padding-left: 25px;
    border-radius: 10px;
    margin: 5px 0 25px;
    display: block;
    max-width: 255px;
    color: #003368;
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    position: relative;
}
.ft-call:after {
}
.soc-net li {
    display: inline-block;
    margin-right: 40px;
    font-size: 38px;
    color: #51abff;
}
.ct-soc-net li {
    margin-right: 30px;
}
.soc-net li a {
    color: #51abff;
}
.location-bl {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    align-content: center;
    max-width: 380px;
    height: 70px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 23px;
    padding-left: 29px;
    border-radius: 10px;
    cursor: pointer;
}
.yl-lc {
    background: #ffea78;
}
.gr-lc {
    background: #78ffd1;
}
.bl-lc {
    background: #93d8f3;
}
.soc-net {
    margin: 10px 0;
}
.learning-modes {
    position: relative;
}
.upcoming-batch::after {
    content: "";
    background: url(../images/dotedt-bg-3.png);
    background-repeat: repeat;
    position: absolute;
    left: 0;
    top: -95px;
    width: 80px;
    z-index: 999999;
    height: 140px;
    background-repeat: repeat-x;
    z-index: -99999;
}
.learning-modes::before {
    content: "";
    background: url(../images/dotedt-bg-2.png);
    background-repeat: repeat;
    background-repeat: repeat;
    background-repeat: repeat;
    background-repeat: repeat;
    position: absolute;
    right: 0;
    top: -50px !important;
    width: 65px;
    z-index: 999999;
    height: 120px;
    background-repeat: repeat-x;
    z-index: -99999;
    transform: rotate(180deg);
}
.blog-section::before {
    content: "";
    background: url(../images/dotedt-bg-2.png);
    background-repeat: repeat;
    background-repeat: repeat;
    background-repeat: repeat;
    background-repeat: repeat;
    position: absolute;
    left: 0;
    top: 50%;
    width: 67px;
    z-index: 999999;
    height: 100%;
    background-repeat: no-repeat;
}
.blog-section::after {
    content: "";
    background: url(../images/dotedt-bg-3.png);
    background-repeat: repeat;
    background-repeat: repeat;
    position: absolute;
    right: 0;
    top: 100px;
    width: 80px;
    z-index: 999999;
    height: 140px;
    background-repeat: repeat-x;
    z-index: -99999;
}
.modes-blk {
    max-width: 320px;
}
.bnd-three-blk {
    margin: 50px 18px 0;
}
.course-title {
    color: #013368;
    font-size: 37px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 44px;
    text-transform: uppercase;
}
.title-pg-diploma {
    color: #013368;
    font-family: Arial, sans-serif;
    font-weight: bold;
}
.title-pg-diploma-call {
    color: #013368;
    font-size: 20px;
    font-family: Arial, sans-serif;
    line-height: 34px;
}
.menu-title-pg-diploma {
    color: #013368;
    font-weight: bold;
    font-family: Arial, sans-serif;
}
.sub-menu-title-pg-diploma {
    color: #000;
    font-weight: bold;
    font-family: Arial, sans-serif;
}
.sub-menu-title-pg-diploma-analytics {
    color: #000;
    font-weight: bold;
    font-family: Arial, sans-serif;
}
.menu-title-pg-diploma-analytics {
    color: #013368;
    font-weight: bold;
    font-family: Arial, sans-serif;
}
.row-box{
    display: flex;
    flex-direction: row;
}
.terms-title-pg-diploma {
    color: #000;
    font-weight: bold;
    font-family: Arial, sans-serif;
}
.light-blue-color{
    color: #25B2E7;
}
.question-title-pg-diploma {
    color: #25B2E7;
    font-size: 27px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 35px;
}
.question-title-pg-diploma-analytics {
    color: #25B2E7;
    font-size: 27px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 35px;
}
.term-title-pg-diploma {
    color: #fff;
    font-size: 18px;
    font-family: Arial, sans-serif;
    line-height: 35px;
}
.term-info-pg-diploma {
    color: #000;
    font-size: 15px;
    font-family: Arial, sans-serif;
    line-height: 35px;
}
.black-bold{
    color: #000;
    font-weight: bold;
}
.skills-tools{
    padding: 30px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-weight: bold;
    background-color: #D6F0FB;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.blue-bm{
    border-bottom: 1px solid #25B2E7;
}
.career-graph-main {
    background-color: #003368;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.career-graph-title{
    display: flex;
    color: #FFBB00;
}
.career-graph-list-outer{
    flex-direction: row;
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: space-between;
}
.career-graph-list-inner{
    background-color: #25B2E7;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 20px;
    width: 30%;
    border-radius: 10px;
    margin-right: 5px;
}
.career-graph-list-image-outer{
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 0.3;
}
.career-graph-list-info-outer{
    display: flex;
    flex: 0.7;
    align-items: center;
    flex-direction: column;
}
.career-info-above-text{
    display: flex;
    justify-content: flex-start;
    color: #fff;
    font-size: large;
    margin-bottom: 7px;
}
.career-info-below-text{
    display: flex;
    margin-top: 7px;
    font-size: x-large;
    color: #fff;
    font-weight: bold;
    justify-content: flex-end;
}
.ct-career-info-below-text{
    font-size: 22px;
}
.course-structure-title {
    color: #FFBB00;
    font-size: 37px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 44px;
}
.course-structure-short-description {
    color: #fff;
    font-size: 22px;
    font-family: Arial, sans-serif;
    line-height: 44px;
}
.course-structure-normal-title {
    color: #fff;
    font-size: 20px;
    font-family: Arial, sans-serif;
    line-height: 44px;
    font-weight: normal;
}
.career-title-lg {
    color: #013368;
    font-size: 40px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: bold;
    text-align: center;
    margin-bottom: 5%;
}
.career-title-lg-higlight {
    font-size: 35px;
}
.career-sub-title-lg-higlight {
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
}
.career-title-lg-download-box {
    color: #ff4800;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: bold;
    text-align: center;
    font-size: 35px;
    margin-bottom: 2%;
}
.career-sub-title-lg-download-box {
    color: #007bd0;
    font-size: 30px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2%;
}
.watch-part {
    /* background: url(../images/AppliedAI.jpg); */
    background-color: #02132b !important;
    background-size: 100% 100%;
    background-position: top right;
    position: relative;
}
.watch-part-pg-diploma {
    /* background: url(../images/AppliedAI.jpg); */
    background: linear-gradient(to top,  #fff 0%,#fff 80%,#CAE4F9 20%,#CAE4F9 100%);
    background-size: 100% 100%;
    background-position: top right;
    position: relative;
}
.cour-head h1 {
    margin-top: 65px;
    color: #fff;
    font-size: 45px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 48px;
    text-transform: uppercase;
}
.cour-head h2 {
    color: #d7d7d7;
    font-weight: normal;
    margin-bottom: 16px;
    font-size: 17px;
    line-height: 26px;
    max-width: 400px;
}
.cour-head-lg h1 {
    margin-top: 40px;
    color: #fff;
    font-size: 50px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 60px;
    text-transform: uppercase;
}
.cour-head-lg h2 {
    color: #d7d7d7;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    max-width: 90%;
    margin: 0px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.cour-head-pg h1 {
    margin-top: 65px;
    color: #fff;
    font-size: 45px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 48px;
    text-transform: uppercase;
}
.cour-head-pg h2 {
    color: #000;
    font-weight: normal;
    margin-bottom: 16px;
    font-size: 21px;
    line-height: 26px;
    max-width: 400px;
}
.cour-sub-head-pg h2 {
    color: #003368;
    margin-bottom: 16px;
    font-size: 21px;
    line-height: 26px;
    max-width: 400px;
    font-weight: 600;
}
.hour li {
    display: inline-block;
    color: #d7d7d7;
    font-size: 17px;
    line-height: 20px;
}
.watch-banner li {
    float: left;
}
.watch-banner-pg-diploma li {
    float: left;
}
.banner-theblk {
    margin-top: -60px;
}
.last-img {
    float: right !important;
    margin-right: 3%;
}
.last-img-pg-diploma {
    float: right !important;
}
.watch-banner-l1 {
    max-width: 40%;
}
.watch-banner-l1-pg-diploma {
    max-width: 65%;
}
.watch-banner-l1-lg {
    max-width: 40%;
    margin-bottom: 30px;
}
.last-img img {
    margin-top: 48px;
    max-width: 410px;
}
.watch-banner {
    margin-left: 5%;
}
.free_demo_main_icon {
    position: absolute;
    border-radius: 11px;
    margin: 10 auto;
    text-align: center;
    right: 0;
    left: 0;
    justify-content: center;
    padding: 0px;
}
.watch-ply a {
    margin-top: 115px;
    color: #fff;
    font-size: 22px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    text-transform: capitalize;
    display: block;
}
.tools-sec {
    position: absolute;
    min-width: 440px;
    background: #fff;
    border-radius: 11px;
    -webkit-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    margin: 0 auto;
    text-align: center;
    right: 0;
    left: 0;
    max-width: 440px;
    bottom: 0px;
    padding: 20px;
}
.tools-sec-lg {
    position: absolute;
    min-width: 440px;
    background: #fff;
    -webkit-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    margin: 0 auto;
    text-align: center;
    right: 0;
    left: 0;
    max-width: 440px;
    bottom: 0px;
    padding: 20px 10px;
}
.tools-sec-pg-diploma {
    min-width: 440px;
    background: #fff;
    -webkit-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    margin: 0 auto;
    text-align: center;
    right: 0;
    left: 0;
    bottom: 0px;
    padding: 20px 10px;
    width: 100%;
    border-radius: 10px;
}
.tools-sec-pg-analytics {
    min-width: 440px;
    background: #fff;
    border-radius: 11px;
    -webkit-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    text-align: center;
    right: 0;
    left: 0;
    max-width: 440px;
    bottom: 0px;
    padding: 20px;
}
.tools-sec h5 {
    margin-bottom: 10px;
    color: #013368;
    font-size: 26px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    text-transform: capitalize;
}
.tools-sec-lg h5 {
    margin-bottom: 10px;
    color: #013368;
    font-size: 26px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    text-transform: capitalize;
}
.tools-sec-pg-analytics h5 {
    margin-bottom: 10px;
    color: #013368;
    font-size: 26px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    text-transform: capitalize;
}
.tools-sec h5 > img {
    padding-right: 15px;
}
.tools-sec-lg h5 > img {
    padding-right: 15px;
}
.tools-sec-pg-analytics h5 > img {
    padding-right: 15px;
}
.tools-sec ul li {
    margin-bottom: 10px;
    display: inline-block;
    width: 24%;
}
.tools-sec-lg ul li {
    margin-bottom: 10px;
    display: inline-block;
    width: 24%;
}
.tools-sec-pg-diploma ul li {
    margin-bottom: 10px;
    display: inline-block;
    width: 24%;
}
.tools-sec-pg-analytics ul li {
    margin-bottom: 10px;
    display: inline-block;
    width: 24%;
}
.tools-sec ul li a > img {
    max-width: 92%;
}
.tools-sec-lg ul li a > img {
    max-width: 92%;
}
.tools-sec-pg-diploma ul li a > img {
    max-width: 92%;
}
.tools-sec-pg-analytics ul li a > img {
    max-width: 92%;
}
.watch-banner-l2 {
    margin-left: 45px;
}
.date-ttl {
    color: #013368;
    font-size: 26px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
}
.dat-cnt h5 {
    color: #013368;
    font-size: 34px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 34px;
}
.dat-cnt h5 span strong {
    color: #013368;
    font-size: 34px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 34px;
}

.dat-cnt p {
    color: #013368;
    font-size: 16px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 18px;
    margin: 20px 0 0 0;
}
.dat-cnt h5 > span {
    display: block;
    color: #013368;
    font-size: 18px;
    font-weight: normal;
    text-transform: uppercase;
    line-height: 18px;
}
.count-ul-inr {
    background: url(../images/date-bg.png) no-repeat;
    width: 381px;
    height: 147px;
}
.count-ul-inr > li {
    width: 33%;
    float: left;
    padding: 20px;
}
.live-vd ul li {
    display: inline-block;
    margin-right: 10px;
}
.thin-fnt {
    color: #858585;
    font-size: 18px;
    line-height: 20px;
    margin: 15px 0;
}
.count-li h4 {
    color: #27b2e7;
    font-size: 35px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
}
.counting-sec {
    margin: 50px 0;
}
.left-menu-ul {
    min-width: 245px;
    max-width: 245px;
    background: #013368;
    border-radius: 10px;
    padding: 0px;
    margin-bottom: 50px;
}
.left-menu-li {
    padding: 0 35px;
}
.left-menu-li a {
    color: #fff;
    font-size: 15px;
    transition: all 1s;
}
.left-menu-li a:hover {
    color: #87ffd6;
    text-decoration: none;
    font-weight: bold;
}
.left-menu-li:hover a {
    color: #013368;
}
.left-menu-li:hover {
    background: #87ffd6;
    color: #013368;
}
.left-menu-li a {
    color: #fff;
    font-size: 15px;
    transition: all 1s;
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}
.left-menu-li:last-child > a {
    border: 0;
}
.right-block-inner {
    width: 100%;
    margin-bottom: 15px;
}
.right-block-inner-lg {
    width: 100%;
    margin-bottom: 15px;
}
.overvw-blk {
    text-align: center;
    color: #013368;
    margin: 20px 0 40px;
}
.overvw-blk p {
    color: #013368;
    font-size: 17px;
    margin: 15px 0 1px 0px;
}
.overvw-blk h4 {
    color: #27b2e7;
    font-size: 24px;
    font-family: Arial, sans-serif;
    font-weight: bold;
}
.course_ap_main {
    display: flex;
    justify-content: center;
    align-items: center;
}
.counrse-contn {
    color: #2f2f2f;
    font-size: 14px;
    line-height: 20px;
}
.counrse-contn-pg-diploma {
    color: #000;
    font-size: 18px;
    line-height: 21px;
}
.counrse-contn-pg-diploma li{
    list-style: unset !important;
    margin-left: 20px;
}
.counrse-contn-pg-diploma-analytics {
    color: #000;
    font-size: 18px;
    line-height: 21px;
}
.counrse-contn-pg-diploma-analytics li {
    list-style: unset !important;
    margin-left: 20px;
}
.counrse-contn a {
    font-style: italic;
    color: #40b8e9;
    text-decoration: underline !important;
}
.dnld-btn a {
    color: #013368;
    font-size: 15px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    padding: 10px 20px;
    background: #78ffd1;
    line-height: 25px;
    border-radius: 40px;
    -webkit-box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    display: block;
    max-width: 258px;
    margin: 0 auto;
    text-align: center;
}
.pg-dnld-btn a {
    color: #FFFFFF;
    font-family: Arial, sans-serif;
    font-weight: bold;
    padding: 15px 25px;
    background: #00B881;
    line-height: 25px;
    border-radius: 10px;
    display: block;
    max-width: 258px;
    margin: 2px 0 15px;
    text-align: center;
    height: 90%;
}
.dnld-btn span span {
    color: #013368;
    font-size: 15px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    padding: 10px 20px;
    background: #78ffd1;
    line-height: 25px;
    border-radius: 40px;
    -webkit-box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    display: block;
    max-width: 258px;
    margin: 0 auto;
    text-align: center;
}
.dnld-btn button {
    color: #013368;
    font-size: 15px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    padding: 10px 20px;
    background: #78ffd1;
    line-height: 25px;
    border-radius: 40px;
    -webkit-box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    display: block;
    max-width: 258px;
    margin: 0 auto;
    text-align: center;
    border: none;
    min-width: 120px;
}
.faq > .card {
    margin: 7px;
    border-radius: 8px !important;
    padding: 0;
    -webkit-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
}
.faq > .pg-card {
    margin: 7px;
    border-radius: 8px !important;
    padding: 0;
    -webkit-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
}
.accordion > .card .card-header {
    margin-bottom: 0px;
}
.faq > .card .card-header {
    padding: 5px 10px;
    color: #2f2f2f;
    background: transparent;
    display: block;
}
.faq > .card .card-header h2 {
    position: relative;
}
.faq > .card .card-header .btn {
    padding: 3px 10px;
    color: #013368;
    font-size: 15px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 16px;
    text-decoration: none;
    width: 100%;
    text-align: left;
}
.faq > .card .card-header .fa {
    color: #28aee2;
    font-weight: normal;
    font-size: 15px;
    float: right;
    padding: 10px;
    position: absolute;
    right: 0px;
}
.accordion > .pg-card .pg-card-header {
    margin-bottom: 0px;
}
.faq > .pg-card .pg-card-header {
    padding: 10px 10px;
    color: #2f2f2f;
    background: transparent;
    display: block;
    margin-bottom: 15px;
}
.faq > .pg-card .pg-card-header h2 {
    position: relative;
}
.faq > .pg-card .pg-card-header .btn {
    padding: 3px 10px;
    color: #013368;
    font-size: 15px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 16px;
    text-decoration: none;
    width: 100%;
    text-align: left;
}
.faq > .pg-card .pg-card-header .fa {
    color: #28aee2;
    font-weight: normal;
    font-size: 15px;
    float: right;
    padding: 10px;
    position: absolute;
    right: 0px;
}
.vew-mr {
    color: #013368;
    font-size: 24px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 26px;
    text-decoration: underline !important;
    display: block;
    text-align: center;
    margin: 50px 0;
}
.cours-ovr-ul li {
    color: #2f2f2f;
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
    margin: 20px 0px;
    position: relative;
    padding-left: 25px;
}
.cours-ovr-ul li::after {
    top: 0px;
    content: "\2022";
    position: absolute;
    left: 0 !important;
    font-size: 30px;
    color: #27b2e7;
}
.cours-ovr-ul li.blcr::after {
    color: #013368;
}
.key-skls-li {
    color: #454545;
    font-size: 12px;
    margin-bottom: 20px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    float: left;
    width: 50%;
    padding-left: 40px;
    line-height: 40px;
    position: relative;
}
.key-skls-li::after {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    background: url(../images/circle-icon.png) no-repeat #000;
    left: -12px;
    text-align: center;
    margin: 0 auto;
    background-position: center;
    border-radius: 100%;
    -webkit-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
}
.bl-bg::after {
    background-color: #a2dbfa;
}
.bl-gr::after {
    background-color: #87ffd6;
}
.bl-yl::after {
    background-color: #ffec87;
}
.bl-pk::after {
    background-color: #ff7f9d;
}
.bl-dgr::after {
    background-color: #bbf279;
}
.counrse-contn-2 {
    color: #2f2f2f;
    font-size: 18px;
    line-height: 22px;
}
.dlformt-blk {
    padding: 10px 25px;
}
.dlformt-blk-lg {
    padding: 0px;
}
.dlformt-center {
    align-items: center;
    display: flex;
    flex-direction: column;
}
.dlformt-blk p {
    font-size: 13px;
    color: #7e7e7e;
    line-height: 18px;
    margin-bottom: 20px;
}
.dlformt-blk h5 {
    font-size: 15px;
    color: #454545;
    margin: 25px 0 10px 0;
    font-family: Arial, sans-serif;
    font-weight: bold;
    align-self: baseline;
}
.dlformt-blk img {
    width: 70px;
    height: 70px;
}
.dlformt-blk-lg p {
    font-size: 17px;
    color: #013368;
    line-height: 20px;
    margin-bottom: 20px;
    font-weight: 500;
    margin-right: 40px;
}
.dlformt-blk-lg h5 {
    font-size: 20px;
    color: #013368;
    margin: 25px 0 10px 0;
    font-family: Arial, sans-serif;
    font-weight: bold;
    align-self: baseline;
}
.dlformt-blk-lg img {
    width: 70px;
    height: 70px;
}
.img-certifct {
    display: block;
    width: 100%;
    max-width: 332px;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.img-certifct-lg {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 80%;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.counrse-contn-21 {
    font-size: 21px;
    line-height: 26px;
    color: #2f2f2f;
    font-weight: normal;
    margin: 11px 0 0 0;
}
.fee-dtltbl {
    -webkit-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    padding: 24px 30px;
    border-radius: 14px;
    position: relative;
    margin-top: 25px;
    margin-bottom: 50px;
}
.no-gtrs {
    margin: 0;
    padding: 0 10px;
}
.fee-hed {
    font-size: 16px;
    color: #454545;
    font-family: Arial, sans-serif;
    font-weight: bold;
    margin: 0 0 20px 0;
    padding: 0;
}
.fee-ttl {
    font-size: 27px;
    color: #27b2e7;
    font-family: Arial, sans-serif;
    font-weight: bold;
    margin: 0;
    padding: 0;
}
.fee-hed img {
    max-width: 40px;
}
.feecont-1 {
    font-size: 12px;
    color: #858585;
    line-height: 16px;
    margin-bottom: 25px;
}
.feecont-2 {
    font-size: 11px;
    color: #7e7e7e;
    line-height: 15px;
}
.fee-dtltbl li {
    font-size: 11px;
    line-height: 14px;
    color: #7e7e7e;
    padding-left: 15px;
    margin: 15px 0;
}
.fee-dtltbl li:after {
    font-size: 9px;
    line-height: 14px;
    top: 0px;
}
.fee-cta {
    position: absolute;
    right: 0;
    left: 0;
    text-align: center;
    bottom: -32px;
}
.fee-dtltbl ul {
    margin-bottom: 50px;
}
.car-bg1 {
    background-color: #a2dbfa;
}
.car-bg2 {
    background-color: #94ffdb;
}
.car-bg3 {
    background-color: #ffee94;
}
.car-bg4 {
    background-color: #ffcfda;
}
.two-creer {
    padding: 40px;
    margin: 25px 10px 25px;
    border-radius: 15px;
    min-height: 290px;
}
.two-creer img {
    text-align: center;
    display: block;
    margin: 0 auto 10px;
}
.two-creer h4 {
    font-size: 22px;
    color: #013368;
    font-family: Arial, sans-serif;
    font-weight: bold;
    margin: 0;
    padding: 7px;
    text-align: center;
}
.two-creer p {
    font-size: 15px;
    color: #2d2d2d;
    margin: 0;
    line-height: 21px;
}
.car-comn h4 {
    color: #013368;
    font-size: 24px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 30px;
    text-transform: uppercase;
    margin: 46px 0 10px 25px;
}
.about-blk-2 {
    padding: 15px 0px !important;
}
@media all and (min-width: 700px) {
    .about-blk-2 {
        padding: 15px !important;
    }
}
.about-blk-2 .lnk-img {
    width: 53px !important;
    padding-top: 6px;
}
.count-ul-inr-2 {
    background: url(../images/date-bg-2.png) no-repeat;
    width: 550px;
    height: 199px;
}
.count-ul-inr-2 .dat-cnt h5 {
    color: #013368;
    font-size: 50px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 54px;
}
.count-ul-inr-2 .dat-cnt p {
    color: #013368;
    font-size: 24px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 28px;
    margin: 20px 0 0 0;
}
.count-ul-inr-2 .dat-cnt h5 > span {
    display: block;
    color: #013368;
    font-size: 26px;
    font-weight: normal;
    text-transform: uppercase;
    line-height: 28px;
}
.count-ul-inr-2 li {
    padding: 30px;
}
.take-demo > h5 {
    color: #013368;
    font-size: 40px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 48px;
}
.contact-form-course {
    background-attachment: fixed !important;
    background: url(../images/course-bg2.jpg) no-repeat;
    padding: 60px 0px;
    margin: 0 auto;
    background-position: center;
    background-size: cover;
}
.contact-form-course .Call-head {
    color: #fff;
}
.new-cont-crc {
    background: url(../images/cor-cont-cta.png) no-repeat;
    min-height: 146px;
    max-width: 919px;
}
.new-cont-crc .ctc-btn {
    font-size: 15px;
}
.new-cont-crc img {
    max-width: 130px;
    top: -160px;
}
.dis-blk {
    max-width: 403px;
    text-align: center;
}
.dis-blk h5 {
    color: #858585;
    font-size: 18px;
    line-height: 20px;
    margin: 15px;
}
.dis-detl {
    background: #fff;
    -webkit-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    margin-bottom: 70px;
    position: relative;
    border-radius: 5px;
}
.dis-detl img {
    position: absolute;
    top: 25px;
    left: 45px;
}
.dis-ttl {
    padding: 20px;
    position: relative;
    margin-left: 190px;
}
.dis-ttl h5 {
    color: #013368;
    font-size: 24px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 28px;
    margin: 0px 0 0 0;
}
.dis-ttl p {
    color: #858585;
    font-size: 13px;
    line-height: 18px;
    margin: 5px 0 20px 0;
}
.next img {
    left: -37px !important;
    top: 18px;
}
.next {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
}
.curr-ul-li {
    display: inline-block;
    margin: 30px 0px 20px 15px;
}
.curr-ul-li a {
    display: inline-block;
    margin-right: 10px;
}
.curr-ul-li a {
    display: inline-block;
    margin-right: 10px;
    color: #013368;
    font-size: 13px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 25px;
}
.pg-curr-ul-li {
    display: inline-block;
    margin: 30px 0px 20px 15px;
}
.pg-curr-ul-li a {
    display: inline-block;
    margin-right: 10px;
}
.pg-curr-ul-li a {
    display: inline-block;
    margin-right: 10px;
    color: #013368;
    font-size: 13px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 25px;
}
.car-bg1-btn.active {
    background-color: #78ffd1;
    border: 2px solid #013368;
}
.car-bg1-btn {
    background-color: #78ffd1;
}
.car-bg2-btn.active {
    background-color: #ffee94;
    border: 2px solid #013368;
}
.car-bg2-btn {
    background-color: #ffee94;
}
.car-bg3-btn.active {
    background-color: #ff8da8;
    border: 2px solid #013368;
}
.car-bg3-btn {
    background-color: #ff8da8;
}
.car-bg4-btn.active {
    background-color: #a2dbfa;
    border: 2px solid #013368;
}
.car-bg4-btn {
    background-color: #a2dbfa;
}
.courselist-blk {
    min-width: 20%;
    padding: 3px;
}
.courselist-blk p {
    cursor: pointer;
    color: #013368;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    box-shadow: 0px 1px 10px 1px rgba(69, 65, 78, 0.1);
    padding: 10px;
    margin-bottom: 2px;
}
.courselist-blk p:hover {
    border-color: #013368;
}
.cour-border1-link {
    border: 2px solid #ffee94;
    border-radius: 10px;
}
.cour-border2-link {
    border: 2px solid #a2dbfa;
    border-radius: 10px;
}
.cour-border3-link {
    border: 2px solid #78ffd1;
    border-radius: 10px;
}
.recomnd {
    position: absolute;
    top: -11px;
    right: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;
    background: #fe7e9d;
    color: #013368;
    font-size: 11px;
    font-weight: 600;
    height: 23px;
    width: 113px;
    line-height: 23px;
    border-radius: 25px;
    -webkit-box-shadow: 0px 3px 15px 5px rgba(153, 18, 18, 0.2);
    -moz-box-shadow: 0px 3px 15px 5px rgba(153, 18, 18, 0.2);
    box-shadow: 0px 3px 15px 5px rgba(153, 18, 18, 0.2);
}
.faq-sect {
    background: #f2f2f2;
    margin-bottom: 50px;
}
#sidebar {
    z-index: 999999;
}
.sidebar-m {
    display: block;
}
.side-mob-menu {
    display: none;
}
/*.mobile-menu{display:none;}*/
.mobile-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #043368;
    padding: 3px 0;
    text-align: center;
    z-index: 999999;
    opacity: 0;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
}

.mobile-menu.show {
    opacity: 1;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    z-index: 9999999;
}
.mobile-menu ul {
    margin: 0 auto;
    float: none;
    /*display: inline-block; */
    display: flex;
    justify-content: space-around;
    text-align: center;
    width: auto;
}
.mobile-menu ul li {
    float: left;
    width: auto;
    display: inline-block;
}
.mobile-menu ul li.icon_only a {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 30px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.mobile-menu ul li .dnld-btn a {
    padding: 3px 20px;
    background: transparent;
    color: #fff;
}
.mobile-menu ul li .pg-dnld-btn a {
    padding: 3px 20px;
    background: transparent;
    color: #fff;
}
.mobile-menu ul li.icon_only a {
    background: transparent;
    color: #fff;
}
#back-to-top.show img {
    width: 60px;
}
.none-ng {
    background: none;
}
.none-ng:after {
    top: 200px !important;
}
.top-point li {
    color: #454545;
    font-size: 22px;
    margin-bottom: 25px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    float: left;
    width: 42%;
    padding-left: 50px;
    position: relative;
    margin-right: 8%;
}
.top-point li > p {
    font-size: 15px;
    font-weight: normal;
    color: #8e8e8e;
    display: block;
    margin: 0px;
    line-height: 20px;
}
.top-point li.top-point1::after {
    background:/*url(../images/sm-icon.png) no-repeat */ #a2dbfa;
}
.top-point li.top-point2::after {
    background:/*url(../images/sm-icon2.png) no-repeat */ #ffec87;
}
.top-point li.top-point3::after {
    background:/*url(../images/sm-icon3.png) no-repeat */ #87ffd6;
}
.top-point li.top-point4::after {
    background:/*url(../images/sm-icon4.png) no-repeat */ #ff7f9d;
}
.top-point li::after {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    left: -12px;
    text-align: center;
    margin: 0 auto;
    background-position: center !important;
    border-radius: 100%;
    -webkit-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    top: 10px;
}
.extra-point {
    margin: 60px 0;
}
.hour li {
    margin-right: 20px;
}
.green-ng {
    position: relative;
    color: #013368;
    font-family: Arial, sans-serif;
    font-size: 23px;
    line-height: 25px;
    background: #78ffd1;
    padding: 2px 13px;
    border-radius: 20px;
    left: -14px;
    bottom: 9px;
}
.item.crc-incld-new {
    -webkit-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 0;
    display: block;
    margin: 20px 12px;
    position: relative;
    max-width: 244px;
    margin: 15px auto;
}
.crc-incld-img {
    position: relative;
    background: url(../images/small-mainbg.png) no-repeat;
    width: 100%;
    left: 0;
    right: 0;
    min-height: 129px;
}
.crc-incld-img2 {
    background: url(../images/small-mainbg2.png) no-repeat;
}
.crc-incld-img img {
    width: auto !important;
    position: absolute;
    right: 20px;
    top: 30px;
}
.crc-incld-new img {
    width: auto !important;
}
.crc-incld-new h2 {
    color: #013368;
    font-size: 15px;
    font-weight: bold;
    padding: 20px 0 0 20px;
    font-family: Arial, sans-serif;
    font-weight: bold;
}
.crc-incld-new h4.crc-incld-new-title {
    color: #013368;
    font-size: 24px;
    font-weight: bold;
    padding: 20px 0 0 20px;
    font-family: Arial, sans-serif;
    font-weight: bold;
}
.timing-blk ul li {
    font-size: 10px;
    font-weight: bold;
    color: #013368;
    margin: 0;
    line-height: 20px;
}
.timing-blk {
    padding: 20px 25px 10px 25px;
}
.timing li {
    color: #9a9a9a !important;
    font-size: 11px !important;
    float: left;
    width: 48%;
    position: relative;
    margin-right: 2% !important;
}
.timing-blk ul li img {
    float: left;
    padding-right: 7px;
}
.smal-greenbg {
    font-size: 12px;
    margin-left: 20px;
    top: -12px;
}
.timing {
    margin-bottom: 10px;
}
.con-dnld-btn a {
    display: inline-block;
    margin: 30px 5px 0;
    width: 210px;
}
.con-dnld-btn p {
    margin-bottom: 5px;
    color: #858585;
    font-size: 12px;
    font-weight: bold;
    padding: 10px 20px;
    font-family: Arial, sans-serif;
    font-weight: bold;
}
.con-email a {
    color: #013368;
    font-size: 15px;
    font-weight: bold;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 16px;
}
.con-email a > .fa {
    color: #3cbae9;
    font-size: 20px;
}
.con-email {
    margin-left: 20px;
    float: left;
    width: 90%;
}
.meet-us::after {
    top: 50%;
}
.meet-us::before {
    right: 0px;
    left: inherit;
}
.contus-blk img {
    width: auto !important;
}
.contus-blk {
    -webkit-box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.05);
    max-width: 444px;
    margin: 14px;
    padding: 40px;
    border-radius: 16px;
}
.cnt-mimg {
    text-align: center;
    display: block;
    margin: 0 auto;
    min-height: 100px;
}
.contus-blk h4 {
    color: #303030;
    font-size: 34px;
    line-height: 40px;
    text-align: center;
    padding: 10px;
}
.contus-blk ul li {
    display: block;
    float: left;
    margin-bottom: 15px;
}
.contus-blk ul li img {
    float: left;
    height: 100%;
    display: block;
}
.contus-blk ul li a {
    float: right;
    display: block;
    font-size: 20px;
    font-weight: bold;
    font-family: Arial, sans-serif;
    font-weight: bold;
    color: #606060;
    padding-left: 15px;
}
.contus-blk ul li p {
    margin-left: 45px;
    font-size: 11px;
    color: #606060;
    line-height: 20px;
    min-height: 100px;
    margin-bottom: 5px;
}
.contus-blk ul {
    padding: 0 15px;
}
.ctn-active {
    background: #013368 !important;
    color: #78ffd1 !important;
}
.cont-two {
    -webkit-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 40px;
    background: #fff;
}
.cont-two img {
    text-align: center;
    margin: 0 auto 20px;
    display: block;
}
.cont-two p {
    color: #606060;
    font-size: 10px;
    line-height: 18px;
    margin-bottom: 25px;
}
.cont-two h1 {
    color: #013368;
    font-size: 28px;
    line-height: 34px;
    font-weight: bold;
    font-family: Arial, sans-serif;
    font-weight: bold;
}
.cont-two span span {
    color: #013368;
    font-size: 28px;
    line-height: 34px;
    font-weight: bold;
    font-family: Arial, sans-serif;
    font-weight: bold;
}
.cont-twdvs li {
    float: left;
    width: 43%;
    margin-right: 30px;
}
.cont-twdvs li:last-child {
    margin-right: 10px;
    margin-top: 40px;
}
.con-dnld-btn ul li {
    float: left;
    display: block;
    clear: none;
}
.new-locatli h5 {
    margin-bottom: 25px;
    max-width: 380px;
    min-height: 70px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 23px;
    padding-left: 29px;
    border-radius: 10px;
    transition: all 1s;
    cursor: pointer;
}
.new-locatli {
    overflow: hidden;
    margin-bottom: 10px;
    display: block;
    transition: ease all 2s;
    position: relative;
    height: 40px;
}
.new-locatli:hover {
    height: 100%;
}
.innerdiv {
    display: none;
}
.outdiv h1 {
    max-width: 380px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 23px;
    padding-left: 15px;
    cursor: pointer;
}
.outdiv {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    float: left;
    width: 100%;
}
.outdiv span span {
    max-width: 380px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 23px;
    padding-left: 15px;
    cursor: pointer;
}

/* .banner-theblk .outdiv {
    margin-bottom: 40px;
} */
.innerdiv {
    font-size: 15px;
    line-height: 20px;
    font-weight: normal;
    margin-top: 10px;
    color: #013368;
}
.course-card-text {
    display: -webkit-box;
    max-width: 200px;
    height: 100px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.why-bg {
    background: url(../images/why-bg.png) no-repeat;
    background-size: auto;
    background-position: center right;
    position: relative;
}
.ahed-curve {
    background: url(../images/why-bg1.png) no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: top right;
    position: relative;
    color: #fff;
}
.why-h4 {
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 25px;
    color: #0fffeb;
    text-transform: uppercase;
    line-height: 30px;
    margin-top: 50px;
    margin-bottom: 10px;
}
.ahy-p {
    font-size: 20px;
    line-height: 26px;
    color: #c8c8c8;
    margin: 10px auto 50px;
}
.why-block-innr h4 {
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 25px;
    color: #013368;
    margin-top: 20px;
}
.why-block-innr p {
    font-size: 13px;
    line-height: 20px;
    color: #525252;
    margin-bottom: 0px;
}
.why-block-innr {
    -webkit-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    padding: 25px 35px;
    border-radius: 25px;
    margin-bottom: 25px;
    max-width: 362px;
    background: #fcfcfc;
    min-height: 350px;
}
.why-container {
    max-width: 1120px !important;
}
.why-block {
    margin: 80px auto 0;
    position: relative;
}
.why-block::after {
    content: "";
    background: url(../images/dotedt-bg-2.png);
    background-repeat: repeat;
    background-repeat: repeat;
    background-repeat: repeat;
    position: absolute;
    left: -19px;
    top: -102px;
    width: 66px;
    z-index: 999999;
    background-repeat: no-repeat;
    transform: rotate(223deg);
    height: 119px;
}
.aftbfr-none:after,
.aftbfr-none:before {
    display: none;
}
.with-arrow .owl-nav {
    display: block;
    position: absolute;
    top: 50%;
    width: 100%;
}
.with-arrow .owl-nav .owl-next {
    float: right;
    position: absolute;
    right: -17px;
    width: 25px;
    height: 25px;
    background: #d0ecf6 !important;
    line-height: 25px !important;
    border-radius: 100%;
    color: #013368;
    font-size: 25px !important;
}
.with-arrow .owl-nav .owl-prev {
    float: right;
    position: absolute;
    left: -17px;
    width: 25px;
    height: 25px;
    background: #d0ecf6 !important;
    line-height: 25px !important;
    border-radius: 100%;
    color: #013368 !important;
    font-size: 25px !important;
}
.searchpag-ul {
    padding-top: 20px;
}
.searchpag-ul li {
    text-align: left;
    width: 100%;
}
.menu-list .search-bar {
    margin: 0;
}
.hamburger-menu-apn {
    font-size: 20px !important;
    color: #fff;
    height: 50px !important;
    width: 152px !important;
    border: 0px !important;
    background: #013368 !important;
    margin: 0 auto !important;
    text-align: center;
    border-radius: 49px;
    right: 0px !important;
    left: 0px !important;
}
.close-btn > .open-arrow {
    right: 0;
    top: -30px;
}
.searchpag-ul li a {
    color: #013368;
    font-size: 15px;
    line-height: 20px;
    text-transform: uppercase;
    font-family: Arial, sans-serif;
}
.exporl-ttl h1 {
    color: #27b2e7;
    font-size: 40px;
    text-transform: uppercase;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 44px;
}
.new-hover > .innerdiv {
    display: block !important;
}
.bnd-three-blk .card-new {
    overflow: unset;
}
.bnd-three-blk .cours-ovr-ul li {
    font-size: 12px;
    line-height: 15px;
    padding: 0 16px;
    margin: 9px 0;
}
.new-hover .inner-total {
    padding: 0 20px;
}
.bg-non {
    background: none;
}
.exp-banner {
    padding: 80px 0 40px;
}
.search-bar {
    position: relative;
    margin: 30px 0px;
}
.search-bar-innr,
.search-bar-innr-mb {
    position: relative;
    width: 100%;
    max-width: 500px;
    /* height:-200px; */
}
.search-bar-innr input {
    -webkit-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    border: 0;
    min-height: 100px;
    padding: 20px 90px 20px 40px;
    border-radius: 45px;
    width: 100%;
    font-size: 18px;
    color: #bfc4ce;
    position: relative;
}
.search-bar-innr-mb input {
    -webkit-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    border: 0;
    min-height: 50px;
    padding: 20px 50px 20px 40px;
    border-radius: 45px;
    width: 100%;
    font-size: 18px;
    color: #bfc4ce;
    position: relative;
}
.search-bar-innr-mb button {
    position: absolute;
    right: 10px;
    border: 0;
    background: transparent;
    top: 3px;
}
.search-bar-innr button {
    position: absolute;
    right: 10px;
    border: 0;
    background: transparent;
    top: 13px;
}
.exp-page:after {
    top: 20%;
}
.expand-block {
    position: relative;
    background: #e5f7fe;
    padding: 0;
}
.lear-point li {
    font-size: 15px;
    line-height: 18px;
    color: #013368;
    margin: 10px 0px;
}
.h-auto {
    max-height: none;
}
.h-auto img {
    width: auto !important;
}
.expand-h6 {
    font-size: 15px;
    line-height: 20px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    padding: 0 0 12px 0;
    color: #013368;
    width: 100%;
    display: block;
    position: relative;
    top: 10px;
}
.countpay li {
    width: 100%;
    font-size: 11px;
    line-height: 15px;
    position: relative;
    padding-right: 100px;
    margin-bottom: 10px;
}
.countpy-detal {
    width: 62%;
}
.countpay {
    display: block;
    width: 100%;
}
.pay-ttl {
    font-weight: bold;
    position: absolute;
    right: 0;
    top: 0;
}
.countpay-totlpay-ttl {
    text-align: right;
    font-size: 11px;
    line-height: 15px;
    position: relative;
    position: relative;
    padding-top: 3px;
}
.total-head-prc {
    font-size: 25px;
    line-height: 30px;
    color: #013368;
    font-family: Arial, sans-serif;
    font-weight: bold;
    text-align: center;
    padding: 20px 0;
    width: 100%;
    float: left;
}
.totl-buton {
    color: #78ffd1;
    font-size: 16px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    background: #013368;
    line-height: 47px;
    border-radius: 47px;
    -webkit-box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    display: inline-block;
    max-width: 185px;
    margin: 0 auto;
    text-align: center;
    width: 100%;
    float: left;
}
.innerdiv-2 {
    padding: 0px 15px 15px 15px;
}
.countpay-totlpay-ttl::after {
    content: "";
    position: absolute;
    background: #000;
    height: 1px;
    width: 63px;
    right: 0;
    top: -6px;
}
.countpay-totlpay-ttl::before {
    content: "";
    position: absolute;
    background: url(../images/plus-2.png) !important;
    height: 20px;
    width: 20px;
    right: 18px;
    top: 0;
}
.countpay-totl {
    width: 50%;
    text-align: right;
    float: right;
}
.min-ht {
    padding: 20px 0px;
}
.min-ht .item.bnd-three-blk {
    padding: 40px 0;
}
.min-ht1 {
    margin-top: 56px;
}
.eax-2 h4 {
    color: #013368;
    font-size: 42px;
    line-height: 44px;
    font-family: Arial, sans-serif;
    font-weight: bold;
}
.eax-2-p {
    color: #013368;
    font-size: 27px;
    line-height: 30px;
}
.eax-2 {
    margin-bottom: 20px;
}
.expl-mainimg {
    width: 90%;
    float: right;
}
.expl-tools2 {
    width: 100%;
    max-width: 593px;
}
.expl-tools {
    position: absolute;
    left: -40px;
    bottom: 7px;
}
.all-logos2:after,
.all-logos2:before {
    display: none;
}
.term-blk {
    position: relative;
    max-width: 244px;
}
.term-blk {
    -webkit-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    margin: 35px 10px;
}
.container-3 {
    max-width: 1200px;
    margin: 0 auto;
}
.smal-greenbg2 {
    background: #ffea78;
    font-size: 12px;
    margin-left: 20px;
    top: -12px;
}
.smal-greenbg3 {
    background: #93d8f3;
    font-size: 12px;
    margin-left: 20px;
    top: -12px;
}
.smal-greenbg4 {
    background: #78ffd1;
    font-size: 12px;
    margin-left: 20px;
    top: -12px;
}
.tools-block h4 {
    color: #013368;
    font-size: 16px;
    margin-bottom: 25px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    padding: 0px 0 0 20px;
}
.tools-block img {
    top: 55px;
}
.term-blk h1 {
    text-align: center;
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 28px;
    line-height: 30px;
    color: #013368;
}
.term-blk span span span {
    text-align: center;
    font-family: Arial, sans-serif;
    font-weight: bold;
    font-size: 28px;
    line-height: 30px;
    color: #013368;
    display: block;
}
.term-blk .timing-blk5 {
    padding: 0 8px 8px 8px;
}
.countpay-5 .countpy-detal {
    width: 75% !important;
    padding: 0px;
    font-size: 9px;
    line-height: 12px;
    margin-bottom: 10px;
}
.countpay-5 .countpy-detal .pay-ttl {
    position: absolute;
    right: -65px;
    top: 0;
    font-size: 11px;
    color: #27b2e7;
    font-family: Arial, sans-serif;
    font-weight: bold;
}
.countpay-5 .countpay-totl {
    width: 100%;
    text-align: right;
    position: relative;
    right: -12px;
}
.countpay-5 .countpay-totl li {
    font-size: 11px;
    color: #27b2e7;
    font-family: Arial, sans-serif;
    font-weight: bold;
}
.countpay-5-block .total-head-prc {
    padding: 0;
    text-align: left;
}
.countpay-5-block {
    position: relative;
    top: 15px;
    margin-bottom: 10px;
}
.countpay-5-block a img {
    position: absolute;
    right: -24px;
    top: 0px;
}
.countpay-5-block span img {
    position: absolute;
    right: -24px;
    top: 0px;
}
.tools-block h1 {
    padding: 23px;
}
.tools-block span span span {
    padding: 30px;
}
.pop-search {
    background: url(../images/popup-bg.png) !important;
    height: 100vh;
    width: 100%;
    background-size: cover !important;
}
.pop-search .search-bar-innr .search-bar-innr-mb {
    width: 70%;
    max-width: none;
    margin-left: 50px;
    display: inline-block;
}
#four-blk .crc-incld-new {
    background: #fff;
}
.sear-arrow {
    font-size: 59px;
    display: inline-block;
    margin: 26px;
}
.popsearch {
    margin: 0;
    padding: 0 !important;
    z-index: 9999999;
}
.popsearch .modal-dialog {
    width: 100%;
    max-width: none;
    margin: 0;
}
.term-modules {
    padding: 15px;
    /*float: left;
    width: 100%;
    background-image: none !important;
    position: relative;
    margin: 0 auto;
    background-color: #fff;*/
}
.card-container {
    cursor: pointer;
    position: relative;
}
.card-new {
    position: relative;
    width: 100%;
    border: 0px;
    overflow: hidden;
}
.card-new .side {
    height: 100%;
    position: relative;
    width: 100%;
}
.card-new .back {
    position: absolute;
    left: 0px;
    transition: all 0.3s;
    top: 100%;
    width: 100%;
    height: 100%;
}
.card-new .term-blk {
    background: #fff;
    margin: 35px auto;
    height: calc(100% - 70px);
}
.card-new:hover .back {
    top: 0px;
    opacity: 1 !important;
}
.card-new:hover .side {
    opacity: 0;
    transition: all 0.3s;
}
.mb-show,
.mobile-card {
    display: none;
}
.dskt-show {
    display: block;
    position: relative;
    transition: all 0.5s;
    transform: scale(1);
}
.dskt-show:hover {
    transform: scale(0.9);
}
.owl-dots {
    display: none;
}
.bnd-three-blk .card-new > .back {
    opacity: 0;
}
.new-hover {
    border-radius: 10px;
}
.new-ctasection {
    background-attachment: fixed !important;
    background: url(../images/course-bg2.jpg) no-repeat;
    padding: 60px 0px;
    margin: 0 auto;
    background-position: center;
    background-size: cover;
    min-height: 200px;
}
.desktop-show {
    display: block;
}
.desktop-hide {
    display: none !important;
}
.mobile-show {
    display: block;
}
.meet-us:after,
.meet-us:before {
    display: none;
}
.left-menu-li.active {
    background: #87ffd6;
    color: #013368;
}
.left-menu-li.active > a {
    color: #013368;
}
.ctc-btn-spc {
    margin-top: 80px;
}
.single_slide .green-ng {
    margin-left: 100px !important;
}
.about-innerblk.single_slide {
    min-height: auto;
    padding: -0 30px 30px;
}
.about-innerblk.single_slide .std-img {
    margin-top: -40px;
}
.single_slide_title {
    float: left;
    left: 0;
    width: auto;
    position: absolute;
    margin-top: 20px;
    padding-left: 150px;
}
.about-innerblk.single_slide .single_slide_title h4 {
    color: #1f1f1f;
    font-size: 20px;
    margin: 0;
}
.about-innerblk.single_slide .single_slide_title h5 {
    color: #1f1f1f;
    font-size: 13px;
    margin: 5px 0;
    font-weight: normal;
}

.badge_bg_green {
    color: #fff;
    background: green;
    font-size: 12px;
    padding: 3px 15px;
    border-radius: 20px;
    top: 12px;
    position: relative;
    left: 15px;
    margin: 0 auto;
}
.Applied_now span {
    left: 0;
    right: 0;
    padding: 10px 25px;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 0rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.Applied_now {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    width: auto;
}
.badge_bg_white {
    color: #000;
    background: white;
    font-size: 12px;
    padding: 3px 15px;
    border-radius: 20px;
    top: 12px;
    position: relative;
    left: 15px;
    margin: 0 auto;
    box-shadow: 0 0rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.badge_bg_red {
    color: #fff;
    background: red;
    font-size: 12px;
    padding: 3px 15px;
    border-radius: 20px;
    top: 12px;
    position: relative;
    left: 15px;
    margin: 0 auto;
}
.badge_bg_blue {
    color: #fff;
    background: blue;
    font-size: 12px;
    padding: 3px 15px;
    border-radius: 20px;
    top: 12px;
    position: relative;
    left: 15px;
    margin: 0 auto;
}

.badge_bg_orange {
    color: #000;
    background: orange;
    font-size: 12px;
    padding: 3px 15px;
    border-radius: 20px;
    top: 12px;
    position: relative;
    left: 15px;
    margin: 0 auto;
}
.badge_bg_yellow {
    color: #000;
    background: yellow;
    font-size: 12px;
    padding: 3px 15px;
    border-radius: 20px;
    top: 12px;
    position: relative;
    left: 15px;
    margin: 0 auto;
}
.badge_bg_red {
    color: #fff;
    background: rgb(139,0,0);
    font-size: 12px;
    padding: 3px 15px;
    border-radius: 20px;
    top: 12px;
    position: relative;
    left: 15px;
    margin: 0 auto;
}
.badge_bg_pink {
    color: #000;
    background: pink;
    font-size: 12px;
    padding: 3px 15px;
    border-radius: 20px;
    top: 12px;
    position: relative;
    left: 15px;
    margin: 0 auto;
}
.business_anl ul {
    float: left;
    width: 100%;
    text-align: left;
}
.business_anl ul li {
    font-size: 14px;
    line-height: 20px;
    color: #013368;
    margin: 5px 0;
    float: left;
    width: 100%;
}
.business_anl ul li span {
    float: left;
    width: calc(100% - 25px);
    margin-left: 5px;
}
.business_anl ul li i {
    font-size: 14px;
    line-height: 20px;
    width: 20px;
    display: flex;
    float: left;
}
.business_box {
    float: left;
    width: 100%;
    margin: 0;
    padding: 25px 15px;
    border-radius: 10px;
}
.business_box.green_box {
    background-color: #78ffd1;
}
.business_box.yellow_box {
    background-color: #ffec87;
}
.business_box.pink_box {
    background-color: #ff7f9d;
}

.accordion .card {
    border-radius: 0px !important;
    margin: 0px !important;
}
.accordion .card {
    border-radius: 0px !important;
    margin: 0px 0px !important;
    border-left: 1px solid rgba(0, 0, 0, 0.125) !important;
    box-shadow: none;
    padding: 0px;
    border-right: 1px solid rgba(0, 0, 0, 0.125) !important;
}
.accordion > .card:first-of-type {
    border-radius: 5px 5px 0 0 !important;
}
.accordion > .card:last-of-type {
    border-radius: 0 0 5px 5px !important;
}
.accordion .pg-card {
    border-radius: 0px !important;
    margin: 0px !important;
}
.accordion .pg-card {
    border-radius: 0px !important;
    margin: 0px 0px !important;
    background-color: #F9F9F9;
    box-shadow: none;
    padding: 0px;
    width: 80%;
}
.accordion > .pg-card:first-of-type {
    border-radius: 5px 5px 0 0 !important;
}
.accordion > .pg-card:last-of-type {
    border-radius: 0 0 5px 5px !important;
}
.business_anl .business_check {
    position: absolute;
    right: 15px;
    top: 35px;
}
.curr_ul_tab li {
    margin: 0px 0px 10px 0px;
}
.curr_ul_tab li a.active {
    /*-webkit-box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.3);
    box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.3);*/
    box-shadow: none;
}
.curr_ul_tab li a {
    display: inline-block;
    margin-right: 5px;
    color: #013368;
    font-size: 13px;
    padding: 10px 15px;
    border-radius: 5px 5px 0 0;
    max-width: 212px;
    height: 100%;
    border: 2px solid transparent;
}
.pg-curr_ul_tab li {
    margin: 20px 0px 20px 0px;
}
.pg-curr_ul_tab li a.active {
    /*-webkit-box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.3);
    box-shadow: 0px 3px 5px 1px rgba(0,0,0,0.3);*/
    box-shadow: none;
}
.pg-curr_ul_tab li a {
    display: inline-block;
    margin-right: 15px;
    color: #013368;
    font-size: 13px;
    padding: 10px 15px;
    border-radius: 10px;
    max-width: 412px;
    min-width: 272px;
    height: 100%;
    border: 2px solid transparent;
}

.for-enterprise {
    background: url(../images/background-2.png);
    background-size: cover;
    background-position: top right;
    position: relative;
    color: #fff;
    padding: 130px 0 300px 0;
}
.enterprise-font {
    font-size: 20px;
}
.our-faculty {
    background: url(../images/background-2.png);
    background-size: cover;
    background-position: top right;
    position: relative;
    color: #fff;
    padding: 150px 0;
}
.for-enterprise .title-42 {
    margin: 30px 0 20px 0;
}

.wide-array {
    position: relative;
    margin: 100px auto;
    text-align: center;
    margin-bottom: 50px;
    background: url(../images/wid_box_bg.png);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    background-attachment: fixed;
}
.wide-array:after {
    content: "";
    background: url(../images/dotedt-bg-3.png);
    background-repeat: repeat;
    position: absolute;
    right: 0;
    top: 25%;
    width: 80px;
    z-index: 999999;
    height: 100%;
    background-repeat: no-repeat;
}
#testimonialList .wide-array:after {
    content: "";
    background: url(../images/dotedt-bg-3.png);
    background-repeat: repeat;
    position: absolute;
    right: 0;
    top: 25%;
    width: 80px;
    z-index: 999999;
    height: 0%;
    background-repeat: no-repeat;
}
.wide-array::before {
    content: "";
    background: url(../images/dotedt-bg-2.png);
    background-repeat: repeat;
    background-repeat: repeat;
    background-repeat: repeat;
    position: absolute;
    left: 0;
    top: -20px;
    width: 67px;
    z-index: 999999;
    height: 100%;
    background-repeat: no-repeat;
}

.reachout_bg::before {
    content: "";
    background: url(../images/dotedt-bg-3.png);
    background-repeat: repeat;
    background-repeat: repeat;
    background-repeat: repeat;
    position: absolute;
    left: 0;
    top: 240px;
    width: 67px;
    z-index: 999999;
    height: 100%;
    background-repeat: no-repeat;
    width: 100%;
    opacity: 0.5;
    z-index: 0;
}
.reachout_bg::after {
    content: "";
    background: url(../images/dotedt-bg-3.png);
    background-repeat: repeat;
    background-repeat: repeat;
    background-repeat: repeat;
    position: absolute;
    right: 0px;
    top: 400px;
    width: 130px;
    z-index: 999999;
    height: 100%;
    background-repeat: no-repeat;
    opacity: 0.5;
    left: auto;
    z-index: 0;
}

.wide-array .fee-hed {
    font-size: 30px;
    color: #013368;
    font-family: Arial, sans-serif;
    font-weight: bold;
    margin: 0 0 20px 0;
    padding: 0;
}
.wide-array .cours-ovr-ul li {
    font-size: 20px;
    color: #013368;
    font-family: Arial, sans-serif;
    font-weight: normal;
    margin: 0 0 20px 20px;
    padding: 0;
    line-height: 30px;
    text-align: left;
}
.wide-array .cours-ovr-ul li::after {
    top: 8px;
    content: "\2022";
    /* font-family: fontawesome; */
    position: absolute;
    left: -20px !important;
    font-size: 30px;
    color: #27b2e7;
}
.wide-array .cours-ovr-ul li.blcr::after {
    color: #013368;
}
.wide-array .fee-dtltbl {
    background: #fff;
}
.array_b1 {
    content: "";
    background: url(../images/wid_box1.png);
    background-repeat: no-repeat;
    background-position: bottom -20px right -10px;
    z-index: 999999;
}
.array_b2 {
    content: "";
    background: url(../images/wid_box2.png);
    background-repeat: no-repeat;
    background-position: bottom -20px right -10px;
    z-index: 999999;
}
.array_b3 {
    content: "";
    background: url(../images/wid_box3.png);
    background-repeat: no-repeat;
    background-position: bottom -20px right -10px;
    z-index: 999999;
}
.enterprises-box-pos {
    position: absolute;
    top: -280px;
    width: 100%;
}

.enterprises-box-pos.ourfaculty {
    position: relative;
    top: 0;
}

.enterprises-box-pos .business_box {
    min-height: 320px;
    border-radius: 15px;
    position: relative;
}
.enterprises-box-pos .business_box .linkd {
    position: absolute;
    top: 0;
    right: 0;
}
.enterprises-box-pos .business_box.green_border {
    border: 8px solid #78ffd1;
}
.enterprises-box-pos .business_box.yellow_border {
    border: 8px solid #fff2aa;
}
.enterprises-box-pos .business_box.pink_border {
    border: 8px solid #ff8da8;
}
.why-block .why-block-innr.green_border {
    border: 5px solid #78ffd1;
}
.why-block .why-block-innr.yellow_border {
    border: 5px solid #fff2aa;
}
.why-block .why-block-innr.pink_border2 {
    border: 5px solid #f44c74;
}
.why-block .why-block-innr.green_border2 {
    border: 5px solid #31c894;
}
.why-block .why-block-innr.yellow_border2 {
    border: 5px solid #e1ca45;
}
.why-block .why-block-innr.pink_border {
    border: 5px solid #ffbccc;
}
.enterprises-box-pos .business_box div {
    font-size: 15px;
    color: #013368;
    font-weight: normal;
    margin: 0 20px;
    padding: 0;
    line-height: 23px;
    text-align: left;
}
.wide-array .inner_img {
    margin-top: 150px;
}
.wide-array .inner_img img {
    position: absolute;
    top: -45px;
    left: auto;
    right: auto;
    margin: 0 auto;
    margin-left: auto;
    margin-left: -78px;
    z-index: 9999999;
}
.wide-array .inner_img .cours-ovr-ul li {
    font-size: 20px;
    color: #013368;
    font-family: Arial, sans-serif;
    font-weight: normal;
    margin: 0 0 20px;
    padding: 0;
    line-height: 30px;
    text-align: center;
    list-style-type: none;
}
.wide-array .inner_img .cours-ovr-ul li::after {
    content: none;
}
.dashed-line-fix {
    position: absolute;
    height: 100px;
    border: 1px dashed #000;
    width: 1px;
    left: auto;
    top: -170px;
    right: auto;
}
.dashed-line-fix::before {
    content: "";
    position: absolute;
    height: 30px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    -webkit-box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.1);
    left: auto;
    top: -6px;
    right: auto;
    margin-left: -5px;
    z-index: 9;
    background: #78ffd1;
}

.inner_img.dashed-line::after {
    content: "";
    border: 1px solid;
    position: absolute;
    height: 30px;
    border: 1px dashed #000;
    width: 1px;
    left: auto;
    top: -65px;
    right: auto;
}
.inner_img.dashed-line::before {
    content: "";
    position: absolute;
    height: 30px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    -webkit-box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.1);
    left: auto;
    top: -67px;
    right: auto;
    margin-left: -4px;
    z-index: 9;
}
.inner_img.dashed-line.green_box::before {
    background-color: #78ffd1;
}
.inner_img.dashed-line.yellow_box::before {
    background-color: #ffec87;
}
.inner_img.dashed-line.pink_box::before {
    background-color: #ff7f9d;
}
.light_blue_box {
    background-color: #E2F0FF;
}
.dark_green_box {
    background-color: #B8F5EE;
}
.light_yellow_box {
    background-color: #FFF5DE;
}
.dashed-horizontal {
    border: 1px dashed;
    position: relative;
    left: 0px;
    right: 0;
    width: auto;
    top: 88px;
    flex: 66.7%;
    max-width: 66.7%;
    left: 17%;
}
.pg-dashed-horizontal {
    border: 1px #707070 dotted;
    position: relative;
    left: 0px;
    right: 0;
    width: auto;
    top: 0px;
    flex: 66.7%;
    max-width: 80%;
    left: 5%;
}
.wide-array .reachout_content .fee-hed {
    border-left: 5px solid #78ffd1;
    padding-left: 30px;
}
.form-control::placeholder {
    /*opacity: 0.2;*/
    color: #6d6d6d;
    font-size: 20px;
    letter-spacing: 0;
    font-weight: normal;
}
.form-control-lg::placeholder {
    /*opacity: 0.2;*/
    color: #6d6d6d;
    font-size: 15px;
    letter-spacing: 0;
    font-weight: normal;
}
.form-signin .form-control,
.form-signin .form-control:focus,
.form-signin .form-control:hover {
    border: none;
    border-radius: 3px;
    background-color: #f5f5f5;
    padding: 12px 30px;
    font-size: 20px;
    letter-spacing: 0;
    outline: none;
    box-shadow: none;
    resize: none;
    font-weight: normal;
    height: auto;
    margin-bottom: 40px;
}
.left-menu-ul.list-group .left-menu-li {
    padding: 0 !important;
    border-bottom: 1px solid #fff;
}
.left-menu-ul.list-group li a.active {
    background: #87ffd6 !important;
    border-radius: 0px !important;
    border: none !important;
    color: #013368 !important;
}
.left-menu-ul.list-group li a {
    background: transparent !important;
    border-radius: 0px !important;
    border: none !important;
    padding: 10px 35px !important;
}

@keyframes shadow-pulse {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }
    100% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
}
.owl-carousel .owl-nav button {
    animation: shadow-pulse 2s infinite;
    background: #ffea78 !important;
    height: 32px;
    width: 32px;
    border-radius: 50%;
}
.header-bar.header-fixed {
    background: #043368;
    width: 100%;
    padding: 8px 0;
}

.sub_annoucement_head {
    color: white;
    position: relative;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
}

.center_section {
    float: left;
    display: table;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
}
.middle_section {
    display: table-cell;
    height: 100vh;
    float: none;
    vertical-align: middle;
    text-align: center;
}

.sidebar_in {
    position: fixed !important;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 1px solid #013368;
    background-color: #013368;
    background: url("../images/login_bg.jpg") no-repeat;
    background-size: cover;
}
.check_bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 1px solid #013368;
}
.check_bg span.bg_blur {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 1px solid #013368;
    background-color: #013368;
    background: url("../images/login_bg.jpg") no-repeat;
    background-size: auto;
    background-size: cover;
    filter: blur(5px);
    width: 100%;
}

.sidebar_in {
    padding-left: 0;
    padding-right: 0;
}
.sidebar_in .nav {
    margin-bottom: 20px;
}
.sidebar_in .nav-item {
    width: 100%;
    float: left;
    margin: 5px 0;
}
.sidebar_in .nav-item .nav-link {
    width: 100%;
    float: left;
    text-align: center;
    margin: 0 auto;
    color: #fff;
    font-size: 20px;
}
.sidebar_in .nav-item .nav-link img {
    float: none;
    text-align: center;
}

/*sign up*/
.form-signin {
    float: left;
    width: 100%;
    margin: 0;
    padding: 0;
}
.form-signin .course-title {
    font-size: 30px;
}
.form-signin .yl-botbod::after {
    content: "";
    position: absolute;
    width: 105%;
    height: 9px;
    background: #ffd700;
    left: -8px;
    bottom: 3px;
    border-radius: 10px;
    z-index: -9;
}
.center_section p.form-signin-heading {
    color: #013368;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    text-transform: uppercase;
}
.form-signin label {
    float: left;
    width: 100%;
    margin: 0 0 10px;
    padding: 0;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    text-align: left;
    position: relative;
}
.form-signin label .eye_icon {
    position: absolute;
    right: 15px;
    top: 48px;
    cursor: pointer;
    z-index: 99;
}
.say-about .ttl-arrw {
    float: left;
    width: 100%;
}
.say-about .ttl-arrw h5 {
    font-size: 24px;
    float: left;
    width: auto;
    margin-top: 10px;
}
.say-about .ttl-arrw .checkout-mr {
    float: left;
    width: auto;
}
.say-about .blk-cont {
    float: left;
    width: 100%;
    margin-top: 15px;
}
.business_anl .business_box::before {
    content: "";
    right: 15px;
    top: 0;
    background: url(../images/loc_pin.png) no-repeat;
    background-size: auto;
    background-size: auto;
    z-index: 99999;
    top: 24px;
    z-index: 999999;
    position: absolute;
    height: 80px;
    width: 55px;
    background-size: cover;
    opacity: 0.2;
}
.say-about.say-about-next {
    position: relative;
    margin: 100px auto 0;
    /*text-align: center;*/
    background: url(../images/wid_box_bg.png);
    background-color: rgba(0, 0, 0, 0);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-attachment: scroll;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    background-attachment: fixed;
    background-color: #e4fff4;
    padding: 10px 0px 100px;
}
.say-about.say-about-next .about-innerblk.single_slide {
    background: #fff;
}
.find_records .inactive {
    display: none;
}
.zero_records,
.multi_records {
    display: none;
}
.zero_records.active,
.multi_records.active {
    display: block;
}
.term-modules.we_offers {
    position: relative;
    margin: 0 auto;
    text-align: center;
    background: url(../images/wid_box_bg.png);
    background-color: rgba(0, 0, 0, 0);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-attachment: scroll;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    background-attachment: fixed;
    background-color: #e4fff4;
    padding: 10px 0px 100px;
}
.free_resources.we_offers {
    position: relative;
    margin: 0 auto;
    text-align: center;
    background: url(../images/wid_box_bg.png);
    background-color: rgba(0, 0, 0, 0);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-attachment: scroll;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
    background-attachment: fixed;
    background-color: #e4fff4;
    padding: 10px 0px 100px;
}
.free_resources {
    position: relative;
    margin: 0 auto;
    text-align: center;
    background-color: #fff;
    padding: 10px 0px 100px;
    background-attachment: fixed !important;
    background: url(../images/course-bg2.jpg) no-repeat;
    background-position-x: 0%;
    background-position-y: 0%;
    background-attachment: scroll;
    background-size: auto;
    background-position: center;
    background-size: cover;
}
.free_resources .industr-blk {
    float: left;
    width: 100%;
    margin: 0 auto 50px;
    padding: 0;
    background: #fff;
}
.free_resources .industr-blk.feature {
    border: 5px solid #f8f8f8;
}
.free_resources .blogs-innerblk {
    float: left;
    width: 100%;
    margin: 0 auto;
}
.free_resources .blogs-innerblk img {
    float: left;
    width: 100%;
}
.free_resources .industr-blk h5 {
    font-size: 14px;
    min-height: 90px;
    line-height: 24px;
    float: left;
    width: 100%;
}
.free_resources .industr-link {
    float: none;
    display: inline-block;
    margin: 0 auto 25px;
}
.para_set h2 {
    color: #013368;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
}
.wide-array .cours-ovr-ul.para_set li::after {
    top: 0;
    content: "\2022";
    /* font-family: fontawesome; */
    position: absolute;
    left: -20px !important;
    font-size: 30px;
    color: #27b2e7;
}
.wide-array .cours-ovr-ul.para_set li.para_li::after {
    display: none;
}
.wide-array .cours-ovr-ul.para_set li.para_li {
    color: #2f2f2f;
    font-size: 1rem;
    line-height: 24px;
    font-family: Arial, sans-serif;
}
.wide-array .cours-ovr-ul.para_set li.para_li_normal {
    color: #2f2f2f;
    font-size: 1rem;
    line-height: 24px;
    font-family: Arial, sans-serif;
}
.para_set h2 .yl-botbod::after {
    content: "";
    position: absolute;
    width: 105%;
    height: 5px;
    background: #ffd700;
    left: -8px;
    bottom: 2px;
    border-radius: 10px;
    z-index: -9;
}
.navbar-nav.mx-auto.logo_fix {
    position: fixed;
    left: 0;
    top: 0;
    right: auto;
    /*width: 25%;*/
}
.checkout_box {
    float: left;
    width: 100%;
    margin: 0;
    padding: 0;
}
.checkout_box li {
    color: #013368;
    font-size: 20px;
    text-transform: capitalize;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 20px;
    float: left;
    width: 100%;
}
.say-about.say-about-next.checkout {
    padding: 50px 0 100px;
    margin: 0 auto;
}
.say-about.say-about-next.checkout .ttl-arrw h5 .check_text_bg {
    color: #043368;
    transition: all 0.3s ease 0s;
    text-decoration: none !important;
    background: #ffd700;
    padding: 10px 15px;
    border-radius: 10px;
}

.form-signin .new-cont-crc {
    background: url(../images/cor-cont-cta.png) no-repeat;
    background-position: center;
    min-height: 100%;
    max-width: 100%;
    float: left;
    width: 100%;
    height: 100%;
    background-size: cover;
}
.form-signin .contact-cta-detail h5,
.form-signin .contact-cta-detail h4 {
    color: #fff;
    font-size: 16px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    line-height: 36px;
}
.sidebar_in_box {
    float: left;
    width: 100%;
    margin-top: 80px;
}
.sidebar_in_box h5 {
    color: #013368;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 30px;
}
.sidebar_in_box::before {
    content: "";
    background: url(../images/dotedt-bg-2.png);
    background-repeat: repeat;
    background-repeat: repeat;
    background-repeat: repeat;
    background-repeat: repeat;
    background-repeat: repeat;
    position: absolute;
    right: 10px;
    top: 0px;
    width: 70px;
    z-index: 999999;
    height: 100%;
    background-repeat: no-repeat;
    transform: rotate(180deg);
    height: 130px;
    opacity: 0.5;
}
.sidebar_in_box.course_demo_style::before {
    transform: rotate(0deg);
    z-index: 9;
}
.sidebar_in_box.course_demo_style ul li {
    margin: 0 0 30px;
}
.sidebar_in_box ul {
    float: left;
    margin: 0;
    padding: 0;
    list-style-type: none;
    list-style: none;
    padding: 0;
}
.sidebar_in_box ul li strong {
    text-transform: uppercase;
    float: left;
    width: 100%;
}
.sidebar_in_box ul li {
    float: left;
    margin: 0 0 10px;
    padding: 0;
    padding-left: 0px;
    list-style-type: none;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    color: #013368;
    position: relative;
    padding-left: 30px;
    text-align: left;
    width: 100%;
}
.sidebar_in_box ul li::before {
    content: "";
    width: 20px;
    height: 16px;
    position: absolute;
    background-image: url("../images/arrow.png");
    background-size: cover;
    background-position: center right;
    left: 0;
    top: 10px;
    transform: translateY(-50%);
}

.nav-link.cust_btn {
    background: #ffd700;
    color: #003368 !important;
    border-radius: 25px;
    float: left;
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.offer_right_card .timing-blk ul li {
    float: left;
    width: auto;
    margin: 0 0 15px;
}

.timing-blk ul li.drc-blcr,
.timing-blk ul li.blcr {
    font-size: 12px;
    font-weight: bold;
    color: #013368;
    line-height: 24px;
}
.offer_right_card .timing-blk ul li.drc-blcr,
.offer_right_card .timing-blk ul li.blcr {
    width: 100% !important;
}

.offer_right_card .timing-blk ul.timing li {
    font-size: 15px !important;
    color: #013368 !important;
}

.crc-incld-new.w-100.float-left.bg-white {
    min-height: 385px;
}
.term-modules.we_offers2 .crc-incld-new.w-100.float-left.bg-white {
    background: #e4fff4 !important;
    margin-bottom: 50px;
}
.term-modules.we_offers2 .free_resources.we_offers {
    background: #fff;
}
/*.sidebar_in .institute_inner_content ul {padding: 0 100px !important;}*/

.owl-carousel .owl-item .modes-innerblk img {
    width: 100%;
}

.footer-section .new-locatblk .outdiv {
    position: relative;
    z-index: 99;
}
.footer-section .new-locatblk .outdiv::before {
    content: "";
    right: 0px;
    top: 0;
    background: url(../images/loc_pin.png) no-repeat;
    background-size: auto;
    background-size: auto;
    background-size: auto;
    z-index: 99999;
    top: 1px;
    z-index: 999999;
    position: absolute;
    height: 39px;
    width: 25px;
    background-size: cover;
    opacity: 0.2;
}
.contact-card {
    width: 27%;
    margin-left: 0px;
}
.sign_in_box_parent .sign_in_box_w100 {
    float: left;
    width: 100%;
}
.sign_in_box_parent .sign_in_box_w100::before {
    display: none;
}
.sign_in_box_parent .sign_in_box_w33 {
    float: left;
    width: calc(33.33% - 30px);
    border: 3px solid #fff;
    padding: 10px 15px 10px 30px;
    margin: 15px;
    border-radius: 10px;
}
.sidebar_in_box ul li.sign_in_box_w33::after {
    background-color: #fff;
    content: "";
    width: 40px;
    height: 40px;
    position: absolute;
    top: -20px;
    left: -20px;
    border-radius: 50%;
}
.sidebar_in_box ul li.sign_in_box_w33.green_border::after {
    background-color: #78ffd1;
}
.sidebar_in_box ul li.sign_in_box_w33.yellow_border::after {
    background-color: #fff2aa;
}
.sidebar_in_box ul li.sign_in_box_w33.pink_border::after {
    background-color: #ff8da8;
}

.sidebar_in_box ul li.sign_in_box_w33::before {
    z-index: 999;
    top: 0;
    left: -8px;
}
.sidebar_in_box ul li.sign_in_box_w33.green_border {
    border: 3px solid #78ffd1;
}
.sidebar_in_box ul li.sign_in_box_w33.yellow_border {
    border: 3px solid #fff2aa;
}
.sidebar_in_box ul li.sign_in_box_w33.pink_border {
    border: 3px solid #ff8da8;
}

.sign_in_box_parent .std-detail p,
.sign_in_box_parent .std-detail a {
    font-size: 13px;
    line-height: 18px;
}
.sign_in_box_parent .sidebar_in_box ul li.sign_in_box_w100 strong {
    text-align: center;
}
/*.sign_in_box_parent .sidebar_in_box ul li.sign_in_box_w100::before {left: 145px;}*/
.sign_in_box_parent .single_slide_title {
    margin-top: 18px;
    padding-left: 125px;
}
.sign_in_box_parent .single_slide_title h4 {
    font-size: 14px !important;
    line-height: 20px !important;
}
.sign_in_box_parent .single_slide_title h5 {
    font-size: 12px !important;
    line-height: 14px !important;
}
.sign_in_box_parent .about-innerblk.single_slide {
    min-height: auto;
    padding: 0 15px 15px;
}
.sign_in_box_parent .linkd {
    position: absolute;
    top: -55px;
    right: -40px;
}
.sign_in_box_parent .sidebar_in_box ul li strong {
    font-size: 13px;
    line-height: 20px;
}
.sign_in_box_parent .sidebar_in_box ul li small {
    font-size: 94%;
    letter-spacing: 1px;
}

.sidebar_content_out {
    /* background: url(../images/bg-1.png); */
    background-attachment: fixed;
    background-size: cover;
    background-position: top right;
    position: relative;
}
.sidebar_content_out .bg-white.layer-white {
    background: rgba(255, 255, 255, 0.8) !important;
}
.counrse-contn ul li {
    list-style-type: disc;
    color: #2f2f2f;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    margin-left: 20px;
}
.card-body ul li {
    list-style-type: disc;
    color: #2f2f2f;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
}
.jc-ac {
    justify-content: center;
    align-content: center;
}

.width-120-height-70 {
    width: 70px;
    height: 70px;
    align-items: flex-end;
    display: flex;
}
.card-body ul > ul > li,
.card-body ul > li > ul > li,
.counrse-contn ul > ul > li,
.counrse-contn ul > li > ul > li {
    list-style-type: circle !important;
    color: #2f2f2f;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    margin-left: 10px;
}
.tools-sec ul li a img {
    max-height: 40px;
}
.tools-sec-lg ul li a img {
    max-height: 40px;
}
.tools-sec-pg-diploma ul li a img {
    max-height: 45px;
}
.tools-sec-pg-analytics ul li a img {
    max-height: 40px;
}
.proj_50 li {
    float: left;
    width: 100%;
}
.cours-ovr-ul.proj_50 li {
    color: #2f2f2f;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin: 0 0 15px;
    position: relative;
    padding-left: 25px;
    min-height: 50px;
}
.cours-ovr-ul.certi_small li {
    color: #2f2f2f;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin: 0 0 15px;
    position: relative;
}
.form-signin span.hs-form-required {
    color: red;
    position: relative;
    left: 2px !important;
    top: 4px;
    font-size: 22px;
}
.form-signin .hs-form {
    padding: 0;
    margin: 0;
    box-shadow: none;
}
.form-signin .hs-form-field {
    margin-bottom: 25px;
}
.form-signin .hs-form-field label,
.form-signin .hs-input {
    font-size: 16px;
    line-height: 20px;
    font-weight: normal !important;
    text-align: left;
}
.form-signin .form-columns-2 {
    max-width: 100%;
}
.form-signin .input > .hs-input {
    float: left;
    width: 100%;
}

.form-signin .input > input,
.form-signin .input > select,
.form-signin .input > .hs-input select,
.form-signin .input > textarea,
.form-signin .input > .hs-input input {
    padding: 15px !important;
    color: #000;
    margin: 0;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal !important;
    outline: none;
    background-color: rgba(207, 215, 223, 0.1);
    border: 1px solid #ecedf2;
}
.form-signin input,
.form-signin optgroup,
.form-signin select,
.form-signin textarea,
.form-control,
.form-control:focus,
.form-control:hover {
    font-family: Arial, sans-serif;
    resize: none;
    font-weight: normal;
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal !important;
    outline: none;
    background-color: rgba(207, 215, 223, 0.1);
    border: 1px solid #ecedf2;
}
.form-control-lg,
.form-control-lg:focus,
.form-control-lg:hover {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    resize: none;
    font-weight: normal;
    width: 100%;
    font-size: 12px;
    line-height: 20px;
    font-weight: normal !important;
    outline: none;
    background-color: #ededed;
    border: 1px solid #ecedf2;
    padding: 0px 10px;
}
.hbspt-form fieldset,
.hbspt-form .hs-input.hs-fieldtype-intl-phone,
.hbspt-form fieldset.form-columns-1 textarea.hs-input {
    width: 100% !important;
    max-width: 100% !important;
}
fieldset.form-columns-1 {
    margin-bottom: 25px !important;
}
.hbspt-form .actions input {
    color: #013368;
    font-size: 15px;
    font-family: Arial, sans-serif;
    font-weight: bold !important;
    padding: 10px 20px;
    background: #78ffd1;
    line-height: 25px;
    border-radius: 40px;
    -webkit-box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 25px 28px 1px rgba(0, 0, 0, 0.1);
    display: block;
    max-width: 258px;
    margin: 0 auto;
    text-align: center;
}

.tools_parent {
    text-align: center;
    width: 100%;
    margin-top: -30px;
}
.crc-incld-img img.tools_img {
    width: auto !important;
    position: relative;
    right: auto;
    top: 0;
    left: auto;
    height: 25px;
    padding: 3px 5px;
}

.full_shadow_cover {
    padding: 90px 120px;
    margin: 0;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 2px 50px 0 rgba(51, 72, 53, 0.2);
}
.thank_you {
    position: relative;
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;
}
.thank_you p {
    color: #000000;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 30px;
    font-weight: normal;
    width: 100%;
}
.thank_you h3 {
    color: #000000;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 38px;
    margin-bottom: 20px;
    float: left;
    width: 100%;
}

.thank_you label {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
}
.back .term-blk h1 small {
    font-size: 13px;
    width: 100%;
    float: left;
    margin: 0;
    line-height: 20px;
}
.back .term-blk span span span small {
    font-size: 13px;
    width: 100%;
    float: left;
    margin: 0;
    line-height: 20px;
}
.back .term-blk ul li {
    color: #013368;
    font-weight: normal;
    margin-left: 20px;
}
.say-about .ttl-arrw h2.font-48,
.say-about .ttl-arrw h2.font-48 a {
    color: #013368 !important;
}

ul.start-rtg {
    display: none;
}
.about-innerblk .rating {
    padding-top: 20px;
}
/* .contact-cta .contact-cta-detail {
    max-width: 680px !important;
} */
li.mb-1::before {
    /*content: "\f099";*/
    /*background: url(../images/arrow.png) no-repeat;*/
    /*background-size: contain;*/
    color: #26b2e7;
    position: absolute;
    left: 10px;
    width: 20px;
    height: 13px;
    margin-top: 5px;
    text-align: center;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 14px;
    font-size: inherit;
    text-rendering: auto;
}
.crc-incld-new li.mb-1:first-child::before {
    content: "\f19d";
}
.crc-incld-new li.mb-1:nth-child(2)::before {
    content: "\f02e";
}
.crc-incld-new li.mb-1:nth-child(3)::before {
    content: "\f201";
}

/*svg setup*/
.count svg {
    float: left;
    margin-right: 15px;
    width: auto;
    height: auto;
    width: 56px;
    fill: #21b3ea;
    height: 56px;
}

.top-point li .icon {
    position: absolute;
    width: 30px;
    height: 30px;
    fill: #002651;
    left: -2px;
    top: 20px;
    z-index: 99;
}
.why-liblk .icon_bg {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
    box-shadow: 0 0.5rem 2rem rgba(242, 242, 242, 0.25) !important;
}
.why-liblk .icon_bg_why {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 10px;
    box-shadow: 0 0.5rem 2rem rgba(242, 242, 242, 0.25) !important;
}
.why-liblk .icon_why {
    width: 70px;
    height: 70px;
    padding: 14px;
    margin-bottom: 25px;
    fill: #002651;
}
.why-liblk .icon {
    width: 100px;
    height: 100px;
    padding: 20px;
    margin-bottom: 25px;
    fill: #002651;
}
.overvw-blk .icon {
    width: 60px;
    height: 60px;
    fill: #002651;
}

.wide-array .inner_img .icon_bg {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-bottom: 20px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9999999;
    top: -35px;
}
.wide-array .inner_img .icon {
    width: 120px;
    height: 120px;
    padding: 25px;
    fill: #002651;
}
.watch-banner-l1 .hour .icon {
    width: 26px;
    height: 26px;
}
.arro-img img {
    width: 30px;
    height: 25px;
}
.ttl-arrw .arro-img {
    right: 10px;
    top: 10px !important;
}
.sitemap .crc-incld-new {
    width: calc(50% - 60px);
    margin-bottom: 30px;
}
.sitemap .timing-blk {
    float: left;
    width: 100%;
    margin: 0;
    padding-top: 0px;
}
.sitemap .timing-blk ul li.drc-blcr,
.sitemap .timing-blk ul li.blcr {
    font-size: 16px;
    font-weight: bold;
    color: #013368;
    line-height: 30px;
}

@media all and (-ms-high-contrast: none) {
    .tools-sec {
        margin-left: calc(50% - 230px) !important;
    }
    .tools-sec-lg {
        margin-left: calc(50% - 230px) !important;
    }
    .tools-sec-pg-diploma {
        margin-left: calc(50% - 230px) !important;
    }
    .tools-sec-pg-analytics {
        margin-left: calc(50% - 230px) !important;
    }
    .wide-array .inner_img img {
        margin-left: 25% !important;
    }
    .inner_img.dashed-line::before {
        margin-left: 45%;
    }
    .inner_img.dashed-line::after {
        margin-left: 46%;
    }
    .dashed-line-fix {
        margin-left: 46%;
    }
    .std-img img {
        min-width: 89px !important;
    }
    .single_slide_title {
        top: 10px;
    }
    .sidebar_in_box.col-md-9 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .sidebar_in_box ul li {
        font-size: 15px;
        line-height: 20px;
    }
}

@supports (-ms-ime-align: auto) {
    .tools-sec {
        margin-left: calc(50% - 230px) !important;
    }
    .tools-sec-lg {
        margin-left: calc(50% - 230px) !important;
    }
    .tools-sec-pg-diploma {
        margin-left: calc(50% - 230px) !important;
    }
    .tools-sec-pg-analytics {
        margin-left: calc(50% - 230px) !important;
    }
}

@media only screen and (min-width: 1600px) {
    .contact-card {
        width: 27%;
        margin-left: 0px;
    }
    .container {
        max-width: 1520px;
    }
}
@media only screen and (max-width: 1366px) {
    .container {
        max-width: 1180px;
    }
    .contact-cta-detail h5,
    .contact-cta-detail h4 .count-hd h5 {
        font-size: 26px;
    }
    .count-hd p {
        font-size: 19px;
    }
    .footer-ul li {
        margin-right: 0px;
    }
    .soc-net li {
        margin-right: 30px;
    }
    .ct-soc-net li {
        margin-right: 20px;
    }
    .tools-list-outer{
        bottom: 50px !important;
    }
    .pg-diploma-header{
        margin-bottom: 250px !important;
    }
    .pg-diploma-header-analytics {
        margin-bottom: 250px !important;
    }
    .multi-batch-box{
        font-size: 12px !important;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .ttl-arrw h5 {
        font-size: 30px;
        width: calc(100% - 45px);
    }
}
@media only screen and (min-width: 765px) and (max-width: 820px) {
    .contact-card {
        width: 49%;
        margin-left: -150px;
    }
    .tools-sec {
        margin: 10px auto;
        min-width: 350px;
        right: auto;
        left: auto;
        max-width: 350px;
    }
    .tools-sec-lg {
        margin: 10px auto;
        min-width: 350px;
        right: auto;
        left: auto;
        max-width: 350px;
    }
    .tools-sec-pg-diploma {
        margin: 10px auto;
        min-width: 350px;
        right: auto;
        left: auto;
        max-width: 350px;
    }
    .tools-sec-pg-analytics {
        margin: 10px auto;
        min-width: 350px;
        right: auto;
        left: auto;
        max-width: 350px;
    }
    .watch-ply a {
        margin-top: 70px;
    }
    .last-img-img img {
        height: 605px;
    }
    .header-lg {
        min-height: 730px;
    }
    .form-lg {
        top: 50%;
        bottom: unset !important;
    }
    .form-pg-diploma {
        top: 50%;
        bottom: unset !important;
    }
    .watch-banner-l1-lg {
        max-width: none;
    }
    .tools-sec-lg {
        top: 90%;
        bottom: unset;
    }
    .tools-sec-pg-diploma {
        top: 90%;
        bottom: unset;
    }
}
@media only screen and (min-width: 821px) and (max-width: 913px) {
    .header-lg {
        min-height: 765px !important;
    }
    .form-lg {
        top: 50%;
        bottom: unset !important;
        right: 35px;
        width: auto;
    }
    .form-pg-diploma {
        top: 50%;
        bottom: unset !important;
        right: 35px;
        width: auto;
    }
    .watch-banner-l1-lg {
        max-width: none;
    }
    .tools-sec-lg {
        top: 92%;
        bottom: unset;
        left: 50px;
        right: unset;
    }
    .tools-sec-pg-diploma {
        top: 92%;
        bottom: unset;
        left: 50px;
        right: unset;
    }
}
@media only screen and (min-width: 1020px) and (max-width: 1030px) {
    .watch-banner li {
        float: none;
    }
    .watch-banner-pg-diploma li {
        float: none;
    }
    .watch-ply a {
        margin-top: 20px;
    }
    .tools-sec {
        margin: 30px auto;
        right: auto;
        left: auto;
    }
    .tools-sec-lg {
        margin: 30px auto;
        right: auto;
        left: auto;
    }
    .tools-sec-pg-diploma {
        margin: 30px auto;
        right: auto;
        left: auto;
    }
    .tools-sec-pg-analytics {
        margin: 30px auto;
        right: auto;
        left: auto;
    }
    .contact-card {
        width: 40%;
        margin-left: -100px;
        top: 65px;
    }
    .last-img img {
        margin-top: -180px;
    }
    .free_demo_main_icon {
        right: auto;
        left: auto;
    }
}
@media only screen and (max-width: 1199px) {
    .container {
        width: 100%;
        max-width: 1160px;
    }
    .heade-nav .nav-link {
        font-size: 13px;
    }
    .contact-cta-detail h5,
    .contact-cta-detail h4,
    .count-hd h5 {
        font-size: 28px;
    }
    .count-hd p {
        font-size: 20px;
    }
    .footer-ul li {
        margin-right: 0px;
    }
    .soc-net li {
        margin-right: 30px;
    }
    .ct-soc-net li {
        margin-right: 20px;
    }
    .multi-batch-box{
        font-size: 10px !important;
    }
}
@media only screen and (min-width: 1450px) {
    .blogs-blk h2 {
        font-size: 25px;
    }
    .container {
        width: 100%;
        max-width: 1380px;
    }
}
@media only screen and (max-width: 1024px) {
    .yl-botbod:after,
    .all-logos::after,
    .upcoming-batch::after,
    .contr-section::before,
    .contr-section::after,
    .top-bg::after,
    .say-about::before,
    .arro-img {
        display: none;
    }
    .footer-ul li {
        margin-right: 0px;
        margin-left: 0 !important;
        width: 100%;
    }
    .contact-cta-detail h5,
    .contact-cta-detail h4,
    .count-hd h5 {
        font-size: 20px;
    }
    .contact-cta-detail p {
        font-size: 15px;
        line-height: 18px;
    }
    .call-usblk {
        width: 600px;
        min-height: 580px;
        background-size: 654px;
        padding: 0;
        margin: 0 auto;
    }
    .call-bck > .login-form-container {
        max-width: 500px;
        margin: 0 auto;
    }
    .call-usblk h4 {
        font-size: 35px;
    }
    .call-usblk h5 {
        font-size: 20px;
    }
    .Call-head {
        text-align: center;
        width: 100%;
        font-size: 35px;
        font-weight: bold;
        margin: 15px auto;
    }

    .col-md-3.sidebar_in {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .col-md-9.sidebar_content_out {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0 0 30px;
    }
    .courselist-blk {
        min-width: 30%;
    }
    .navbar-nav.mx-auto.logo_fix {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        width: 100%;
    }
    .multi-batch-box{
        font-size: 7px !important;
    }
}

@media only screen and (max-width: 992px) {
    .navbar-nav {
        background: #fff;
    }
    .font-48,
    .title-42,
    .top-hed h1 {
        font-size: 35px;
        line-height: 40px;
    }
    .bnr-cont {
        font-size: 15px;
        line-height: 17px;
    }
    .ttl-arrw h5 {
        font-size: 30px;
        line-height: 35px;
    }
    .navbar {
        padding: 20px 0;
    }
    .navbar-brand {
        background: url("../images/footer-logo.png") no-repeat;
        background-size: 180px;
        width: 180px;
        height: 40px;
    }
    .navbar-brand > img {
        display: none;
    }
    .navbar-toggler {
        z-index: 99999999;
        background: #fff;
    }
    .blog-section::after,
    .blog-section::before,
    .yl-botbod:after,
    .all-logos::after,
    .upcoming-batch::after,
    .contr-section::before,
    .contr-section::after,
    .top-bg::after,
    .say-about::before,
    .arro-img,
    .say-about::after,
    .learning-modes::before,
    .all-logos::before,
    .contact-form::before {
        display: none;
    }
    .contact-cta-detail h5,
    .contact-cta-detail h4,
    .count-hd h5 {
        font-size: 20px;
    }
    .count-hd p {
        font-size: 15px;
        line-height: 17px;
    }
    .count {
        align-items: flex-start;
    }
    .why-li {
        width: 32%;
    }
    .upcmg-btcblk {
        padding: 10px;
    }
    .upcmg-img {
        left: 0%;
        right: 0px;
    }
    .upcmg-btcblk ul li {
        float: none !important;
    }
    .upcmg-btcblk ul li:last-child {
        width: 100%;
        text-align: center;
    }
    .footer-ul li {
        margin-right: 0px;
        margin-left: 0 !important;
        width: 100%;
    }
    .teblet-pb-1 {
        padding-bottom: 50px;
    }
    .left-menu-ul {
        min-width: auto;
    }
    .left-menu-li {
        padding: 0 10px;
    }
    .extra-point {
        padding: 0 20px;
    }
    .course-title {
        font-size: 25px;
        line-height: 30px;
    }
    .title-pg-diploma-call {
        font-size: 20px;
        line-height: 30px;
    }
    .question-title-pg-diploma {
        font-size: 25px;
        line-height: 30px;
    }
    .question-title-pg-diploma-analytics {
        font-size: 25px;
        line-height: 30px;
    }
    .term-title-pg-diploma {
        font-size: 15px;
        line-height: 30px;
    }
    .term-info-pg-diploma {
        font-size: 12px;
        line-height: 30px;
    }
    .course-structure-title {
        font-size: 25px;
        line-height: 30px;
    }
    .course-structure-short-description {
        font-size: 25px;
        line-height: 30px;
    }
    .course-structure-normal-title {
        font-size: 25px;
        line-height: 30px;
    }
    .career-title-lg {
        font-size: 25px;
        line-height: 30px;
    }
    .key-skls-li {
        line-height: normal;
    }
    .key-skls-li::after {
        top: 0px;
    }
    .dlformt-blk img,
    .dlformt-blk {
        padding: 0px;
    }
    .dlformt-blk h5 {
        align-self: center;
    }
    .dlformt-blk-lg img,
    .dlformt-blk-lg {
        padding: 0px;
    }
    .dlformt-blk-lg h5 {
        align-self: center;
    }
    .fee-dtltbl {
        padding: 15px;
    }
    .checkout-mr a {
        max-width: 200px;
        line-height: 30px;
    }
    .fee-cta {
        bottom: -20px;
    }
    .fee-cta > .ctc-btn {
        width: 150px;
        line-height: 16px !important;
        font-size: 15px !important;
        padding: 12px 5px !important;
    }
    .fee-hed img,
    .fee-hed,
    .fee-ttl {
        font-size: 18px;
        padding-bottom: 0px;
        display: block;
        width: 100%;
    }
    .two-creer {
        padding: 20px;
        margin: 0px 0 30px 0px;
    }
    .teblet-down {
        max-width: 250px !important;
    }
    .new-cont-crc img {
        max-width: 100px;
        top: -53px;
        left: 5px;
    }
    .new-cont-crc > ul > li:nth-child(2) > .contact-cta-detail h5 > br {
        display: none;
    }
    .right-block {
        width: 100%;
        padding: 0 15px;
    }
    .faq-sect,
    .discover-cors {
        padding: 0 20px;
    }
    .left-menu-li a {
        padding: 5px 0px;
    }
    .open-arrow-inpt {
        max-width: 500px !important;
    }
    /*.term-modules{padding:20px;margin:0 70px;}*/
    .cont-twdvs li {
        width: 90%;
    }
    .contact-cta-img {
        position: absolute;
        top: -50px;
        left: 0;
        max-width: 100px;
    }
    .why-bg {
        background-size: 60%;
    }
    .why-bg .top-point {
        padding: 0 30px;
    }
    .why-block-innr {
        padding: 25px;
    }
    .hour li {
        padding-bottom: 20px;
    }
    .call-usblk {
        width: 600px;
        min-height: 580px;
        background-size: 654px;
        padding: 0;
        margin: 0 auto;
    }
    .call-usblk h4 {
        font-size: 35px;
    }
    .call-usblk h5 {
        font-size: 20px;
    }
    .Call-head {
        text-align: center;
        width: 100%;
        font-size: 35px;
        font-weight: bold;
        margin: 15px auto;
    }

    .mobile_fix_box .contus-blk ul li p {
        min-height: auto;
    }
    .sidebar_in {
        position: relative;
        padding: 30px 0;
        margin: 0 0 50px;
    }
    .bg-faded.sidebar_in .navbar-brand {
        background: none;
        height: auto;
        width: auto;
    }
    .bg-faded.sidebar_in .navbar-nav {
        background: transparent;
    }
    .bg-faded.sidebar_in .navbar-brand > img {
        display: block;
        height: auto;
    }
    .center_section {
        display: block;
        height: auto;
    }
    .middle_section {
        display: block;
        height: auto;
        margin-bottom: 30px;
    }
    .form-signin {
        padding: 15px !important;
    }
    .form-signin .form-control.mb-3 {
        margin-bottom: 30px !important;
    }
    .col-md-3.sidebar_in {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
    .col-md-9.sidebar_content_out {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0 0 30px;
    }
    .navbar-nav.mx-auto.logo_fix {
        position: relative;
        left: 0;
        top: 0;
        right: 0;
        width: 100%;
    }
    .sidebar_in_box {
        margin-top: 0px;
    }
    .upcmg-btcblk ul li {
        float: left !important;
    }
    .new-cont-crc .ctc-btn {
        padding: 10px 30px !important;
    }
    .curr_ul_tab li {
        margin: 0 0 10px 5px;
    }
    .pg-curr_ul_tab li {
        margin: 0 0 10px 5px;
    }
    .sidebar_in_box .float-left {
        float: none !important;
    }
    .mobi_hide {
        display: none !important;
    }
    .mh-500 {
        min-height: auto !important;
    }
    .career-title-lg-higlight {
        font-size: 25px;
    }
    .career-sub-title-lg-higlight {
        font-size: 15px;
    }
    .thank_you_sub_title {
        font-size: 25px !important;
        line-height: 30px !important;
    }
    .bottom-number{
        padding: 0 20px;
    }
}
.call-bck > .login-form-container {
    max-width: 500px;
    margin: 0 auto;
}

@media only screen and (max-width: 1024px) {
    .say-about.say-about-next.checkout .ttl-arrw h5 .check_text_bg {
        float: left;
        width: 100%;
    }
    .checkout_box li {
        font-size: 14px;
        line-height: 20px;
    }
    .check_bg {
        margin-top: 0;
    }
    .upcmg-btcblk {
        padding-top: 15px;
        background-size: cover;
        position: relative;
        display: block;
        float: left;
    }
    .term-modules {
        margin: 0px;
    }
    .faq {
        margin: 0;
    }

    .free_resources .industr-blk {
        width: auto;
        margin: 15px;
    }
    .free_resources.we_offers {
        padding: 30px 0;
    }
    .right-block {
        float: left;
        width: 100%;
        margin: 0 auto;
        padding: 0 0 0 15px;
    }
    .col-md-4.ipad_fix,
    .say-about .business_anl .ipad_fix {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        margin: 0 0 25px;
    }
    .say-about .business_anl {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0 0 25px;
    }
    .ipad_full_wid {
        max-width: 100% !important;
        width: 100% !important;
        flex: 100% !important;
        margin: 0 auto;
    }

    .col-md-4.ipad_fix,
    .say-about .business_anl .ipad_fix,
    .say-about .business_anl,
    .col-md-4.ipad_fix .p-0.float-left {
        -ms-flex: 0 0 100% !important;
        flex: 0 0 100% !important;
        max-width: 100% !important;
        width: 100%;
    }
    .footer-ul li {
        padding: 0 !important;
        margin-bottom: 25px;
    }
    .sidebar_in {
        position: relative !important;
    }
    .sidebar_in.sign_in_box_parent.ipad_full_wid,
    main.sidebar_content_out.ipad_full_wid {
        position: relative !important;
        margin: auto !important;
    }
    .full_shadow_cover .float-left.border-bottom {
        float: left !important;
        width: 100% !important;
        text-align: center;
    }
    .full_shadow_cover .float-left.border-bottom a {
        float: none !important;
    }
    .full_shadow_cover .dnld-btn.float-right {
        float: none;
        width: 100% !important;
    }
    .ct-float-right-none{
        float: none !important;
    }
}
@media only screen and (max-width: 920px) {
    .watch-banner-pg-diploma li {

        max-width: none !important;
        margin: 0px;
    }
    .last-img-pg-diploma {
        float: none !important;
        width: 100%;
        min-width: 100%;

    }
    .ct-contact-card{
        margin: auto;
        width: 100%;
    }
    .ct-float-right-none{
        float: none !important;
        margin-top: 8px;
    }
}
@media only screen and (max-width: 767px) {
    .contact-card {
        width: 90%;
        margin-left: 0px;
    }
    .down-arrow-mb {
        position: absolute;
        right: 0;
    }
    .navbar-nav {
        background: #fff;
    }
    .desktop-hide {
        display: block;
    }
    .mobile-hide {
        display: none;
    }
    .navbar {
        padding: 20px 0;
    }
    .navbar-brand {
        background: url("../images/footer-logo.png") no-repeat;
        background-size: 180px;
        width: 180px;
        height: 40px;
    }
    .navbar-brand > img {
        display: none;
    }
    .mb-ht {
        padding-top: 0 !important;
        margin-top: 15px !important;
    }
    .top-bg {
        background-size: 700px 50%;
        background-position: top right;
        background-repeat: no-repeat;
        background: none;
    }
    .owl-nav {
        text-align: center;
        display: block;
        top: calc(50% - 35px);
        position: absolute;
        left: 0;
        right: 0;
        font-size: 20px;
        margin-top: 15px;
    }
    .owl-carousel .owl-nav .owl-next {
        position: absolute;
        right: 0;
    }
    .owl-carousel .owl-nav .owl-prev {
        position: absolute;
        left: 0;
    }
    .navbar-toggler {
        background: #fff;
    }
    .navbar-nav {
        background: #fff;
    }
    .top-hed {
        margin-top: 0;
    }
    .font-48,
    .title-42,
    .top-hed h1 {
        font-size: 35px;
        line-height: 40px;
    }
    .top-hed .sinc {
        font-size: 20px;
        line-height: 25px;
    }
    .img-bdg {
        margin: 10px 0 20px;
        width: 82%;
    }
    .Call-head,
    .call-usblk h4,
    .ttl-arrw h5 {
        font-size: 32px;
        line-height: 38px;
    }
    .say-about::before,
    .arro-img {
        display: none;
    }
    .bnr-cont {
        font-size: 20px;
        line-height: 25px;
    }
    .why-li {
        width: 50%;
        padding: 20px 0px;
    }
    .m-b-full {
        width: 100%;
        padding: 0 25% 30px !important;
    }
    .contact-cta-detail p,
    .why-li p,
    .blk-cont {
        font-size: 15px;
        line-height: 18px;
    }
    .why-li p {
        padding: 0;
    }
    .call-usblk p,
    .count-hd p,
    .spn-bdg {
        font-size: 15px;
    }
    .yl-botbod::after {
        height: 6px;
        bottom: 2px;
        left: -2px;
        display: none;
    }
    .top-bg::after,
    .blog-section::after,
    .blog-section::before,
    .contact-form::before,
    .all-logos::after,
    .all-logos::before,
    .upcoming-batch::after,
    .learning-modes::before,
    .say-about::after,
    .contr-section::after,
    .contr-section::before {
        width: 40px;
        background-size: 40px;
    }
    .thre-rghimg img {
        width: 45% !important;
        float: right;
    }
    .thre-rghimg {
        right: 25px;
        top: 30px;
    }
    .three-blk {
        margin-bottom: 50px;
    }
    .arro-blk {
        margin: 20px 10px;
    }
    .courselist-blk {
        min-width: 140px;
        width: 50%;
    }
    .upcmg-btcblk {
        padding: 10px;
    }
    .upcmg-img {
        left: 0%;
        right: 0px;
    }
    .contact-cta-detail h5,
    .contact-cta-detail h4,
    .count-hd h5 {
        font-size: 15px;
        line-height: 17px;
    }
    .up-detail {
        padding-left: 11px;
        padding-top: 20px;
    }
    .checkout-mr a,
    .call-usbtn {
        line-height: 30px;
        font-size: 18px;
    }
    .Call-head {
        margin: 0px auto !important;
        font-size: 32px !important;
        width: 175px !important;
    }
    .call-usblk h5,
    .many-mor {
        font-size: 20px;
        margin: 10px 0px;
    }
    .count-hd {
        margin-top: 10px;
    }
    .upcmg-btcblk ul li {
        float: none !important;
        min-width: auto !important;
        right: 0;
        left: 0;
        margin: 0 auto;
        top: 0;
        text-align: center;
        width: 100%;
    }
    .soc-net li {
        margin-right: 15px;
    }
    .footer-ul li {
        width: 100%;
        margin: 0px !important;
    }
    .upcoming-batch {
        margin: 50px auto;
    }
    .footer-logo {
        max-width: 200px;
        width: 100%;
    }
    .contact-cta,
    .call-usblk {
        width: 100%;
        background-repeat: no-repeat !important;
        min-height: auto;
        text-align: center;
    }
    .call-inner {
        padding-top: 10px;
    }
    .call-inner-text {
        margin: 50px 40px;
        text-align: center;
        font-size: "20px";
        padding: 30px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .all-logos {
        margin: 25px auto;
    }
    .contact-cta-img {
        position: relative;
        top: 0px !important;
        left: 0 !important;
        right: 0px !important;
        max-width: 111px;
        margin: 0 auto !important;
    }
    .watch-banner li {
        width: 100% !important;
        max-width: none !important;
        margin: 0px;
    }
    .watch-banner-pg-diploma li {
        /* width: 100% !important; */
        max-width: none !important;
        margin: 0px;
    }
    .cour-head h1 {
        font-size: 30px;
        line-height: 35px;
        margin-top: 20px;
    }
    .cour-head h2 {
        font-size: 17px !important;
        line-height: 26px !important;
    }
    .cour-head-lg h1 {
        font-size: 30px;
        line-height: 35px;
        margin-top: 20px;
    }
    .cour-head-lg h2 {
        font-size: 17px !important;
        line-height: 26px !important;
        max-width: 100% !important;
    }
    .cour-head-pg h1 {
        font-size: 30px;
        line-height: 35px;
        margin-top: 20px;
    }
    .cour-head-pg h2 {
        color: #C70000 !important;
        font-size: 17px !important;
        line-height: 26px !important;
    }
    .cour-sub-head-pg h2 {
        font-size: 17px !important;
        line-height: 26px !important;
        font-weight: 600;
    }
    .hour li {
        width: 50% !important;
    }
    .watch-ply a {
        margin-top: 20px;
    }
    .last-img img {
        width: 100%;
        margin-bottom: 93%;
    }
    .last-img-img img {
        /* margin-bottom: 0% ; */
        min-height: 450px;
    }
    .tools-sec {
        min-width: auto;
        max-width: 440px;
        margin: 0 10px;
        padding: 10px;
    }
    .tools-sec-pg-analytics {
        min-width: auto;
        max-width: 440px;
        margin: 0 10px;
        padding: 10px;
    }
    .tools-sec ul li {
        width: 48%;
    }
    .tools-sec-pg-analytics ul li {
        width: 48%;
    }
    .tools-sec-top {
        top: 75%;
        bottom: auto;
    }
    .tools-sec-lg {
        min-width: auto;
        max-width: 440px;
        margin: 0 10px;
        padding: 10px;
    }
    .tools-sec-pg-diploma {
        min-width: auto;
        max-width: 440px;
        margin: 0 0px;
        padding: 10px;
    }
    .tools-sec-lg ul li {
        width: 48%;
    }
    .tools-sec-pg-diploma ul li {
        width: 48%;
    }
    .tools-sec-top-lg {
        top: 100%;
        bottom: auto;
    }

    .count-ul-inr {
        width: 100%;
        max-width: 90%;
        height: auto;
        float: left;
        background-size: cover;
        margin-left: 5%;
    }
    .dat-cnt p {
        font-size: 13px;
        line-height: 10px;
        margin: 7px 0 0 0;
    }
    .course-title {
        font-size: 25px;
        line-height: 30px;
        text-align: center;
    }
    .title-pg-diploma {
        text-align: center;
    }
    .title-pg-diploma-call {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
    }
    .menu-title-pg-diploma {
        text-align: center;
    }
    .sub-menu-title-pg-diploma {
        text-align: center;
    }
    .sub-menu-title-pg-diploma-analytics {
        text-align: left;
    }
    .sub-menu-title-pg-diploma-analytics h4{
        font-size: 18px;
    }
    .menu-title-pg-diploma-analytics {
         text-align: left;
    }
    .question-title-pg-diploma {
        font-size: 25px;
        line-height: 30px;
        text-align: center;
    }
    .question-title-pg-diploma-analytics {
        font-size: 25px;
        line-height: 30px;
        text-align: left;
    }
    .term-title-pg-diploma {
        font-size: 15px;
        line-height: 30px;
        text-align: center;
    }
    .term-info-pg-diploma {
        font-size: 12px;
        line-height: 30px;
        text-align: center;
    }
    .course-structure-title {
        font-size: 25px;
        line-height: 30px;
        text-align: center;
    }
    .course-structure-short-description {
        font-size: 25px;
        line-height: 30px;
        text-align: center;
    }
    .course-structure-normal-title{
        font-size: 25px;
        line-height: 30px;
        text-align: center;
    }
    .career-title-lg {
        font-size: 25px;
        line-height: 30px;
        text-align: center;
    }
    .curr-ul-li {
        margin: 8px 15px;
        display: block;
        border-radius: 0px;
        text-align: center;
    }
    .curr-ul-li a {
        border-radius: 0px;
        display: block;
    }
    .pg-curr-ul-li {
        margin: 8px 15px;
        display: block;
        border-radius: 0px;
        text-align: center;
    }
    .pg-curr-ul-li a {
        border-radius: 0px;
        display: block;
    }
    .key-skls-li {
        width: 100%;
        line-height: 20px;
        padding-top: 23px;
    }
    .key-skls-li::after {
        top: 22px;
        left: -8px;
    }
    .cours-ovr-ul li {
        font-size: 14px;
        line-height: 18px;
        top: 0px;
    }
    .cours-ovr-ul li::after {
        top: 0px;
    }
    .count-ul-inr-2 .dat-cnt h5 {
        font-size: 25px;
        line-height: 28px;
    }
    .count-ul-inr-2 .dat-cnt h5 > span {
        font-size: 17px;
        line-height: 20px;
    }
    .count-ul-inr-2 li {
        padding: 5px;
    }
    .count-ul-inr-2 .dat-cnt p {
        font-size: 15px;
        margin: 4px 0px 0px 0px;
        line-height: 18px;
    }
    .faq-sect {
        padding: 20px;
    }
    .dis-detl img {
        position: relative;
        top: 25px;
        left: 0;
        right: 0;
        margin: 0 auto 20px;
        text-align: center;
        display: block;
    }
    .dis-ttl {
        margin: 10px;
    }
    .next {
        position: relative;
    }
    .next img {
        left: 0px !important;
        position: relative !important;
        top: 100% !important;
        margin: 0 auto !important;
    }
    .right-ft {
        margin-top: 20px;
    }
    .dlformt-blk {
        padding: 0px;
    }
    .dlformt-blk-lg {
        padding: 0px;
    }
    .discover-cors {
        margin: 0 20px;
    }
    .faq > .card .card-header .btn .pg-card-header,
    .img-certifct {
        width: 90%;
    }
    .img-certifct-lg {
        width: 90%;
        height: 90%;
    }
    .faq {
        margin: 0 15px;
    }
    .key-skls,
    .discover-cors {
        display: block;
        margin: 0 15px;
    }
    .mob-dnl {
        font-size: 10px !important;
        padding: 5px 10px !important;
    }
    .mobile-menu {
        padding: 10px;
        margin-bottom: 10px;
        z-index: 99999;
        position: fixed;
        bottom: -11px;
        width: 100%;
        background: #043368;
    }
    #back-to-top {
        right: 0px;
        bottom: 0;
    }
    .mobile-menu ul li {
        display: inline-block;
        float: left;
    }
    .mobile-menu ul li > a {
        color: #93d8f3;
        font-size: 33px;
        text-align: center;
        margin: 0 auto;
        display: inline-block;
        line-height: 33px;
        padding: 0 11px;
    }
    #sidebar {
        display: none;
        position: relative !important;
        z-index: 9999999999;
    }
    .sidebar-m {
        display: block;
    }
    #semimenu {
        width: 100%;
        border: 0;
        margin-bottom: 0;
        height: 37px;
        font-size: 25px;
        background: #013368;
        color: #fff;
        width: 45px;
        border-bottom: 1px solid #fff;
    }
    .left-menu-ul {
        width: 100%;
        max-width: none;
        display: none;
    }
    .side-mob-menu {
        display: block;
        width: 45px;
        margin-top: 30px;
        z-index: 9999999;
        left: 0px;
    }
    #sidebar-m .left-menu-ul {
        width: 45px;
        border-radius: 0px;
        min-width: 0;
        text-align: center;
        padding: 5px;
    }
    #sidebar-m .left-menu-ul .left-menu-li {
        padding: 0;
    }
    .mobile-menu {
        display: block;
    }
    .copy-right {
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 40px;
    }
    .upcmg-btcblk {
        min-height: 150px;
        padding-top: 15px;
        background-size: cover;
    }
    .top-point li {
        width: 100%;
        margin: 10px;
    }
    .blog-section-1::before {
        top: 50px !important;
    }
    .contus-blk {
        padding: 10px;
    }
    .meet-us::after {
        top: 85%;
    }
    .meet-us::before {
        right: 0px;
        left: inherit;
    }
    .cont-twdvs li {
        width: 100%;
        margin-top: 40px;
    }
    .cont-two {
        padding: 20px;
    }
    .con-dnld-btn a {
        margin: 10px;
    }
    .open-arrow-inpt {
        margin-left: 0px !important;
    }
    .open-arrow {
        margin: 0 !important;
        top: -60px;
    }
    .item {
        max-width: 300px;
        margin: 15px auto 20px;
    }
    .bnd-three-blk {
        padding: 0px !important;
        height: 470px !important;
    }
    .hide {
        display: none;
    }
    .blog-section {
        margin: 50px auto 0px;
    }
    .pb-5 {
        padding-bottom: 20px !important;
    }
    .pb-4 {
        padding-bottom: 15px !important;
    }
    .pb-3 {
        padding-bottom: 10px !important;
    }
    .pb-2 {
        padding-bottom: 5px !important;
    }
    .pb-1 {
        padding-bottom: 0px !important;
    }
    .pt-5 {
        padding-top: 20px !important;
    }
    .pt-4 {
        padding-top: 15px !important;
    }
    .pt-3 {
        padding-top: 10px !important;
    }
    .pt-2 {
        padding-top: 5px !important;
    }
    .pt-1 {
        padding-top: 0px !important;
    }
    .mt-5 {
        margin-top: 20px !important;
    }
    .mt-4 {
        margin-top: 15px !important;
    }
    .mt-3 {
        margin-top: 10px !important;
    }
    .mt-2 {
        margin-top: 5px !important;
    }
    .mt-1 {
        margin-top: 0px !important;
    }
    .mb-5 {
        margin-bottom: 20px !important;
    }
    .mb-4 {
        margin-bottom: 15px !important;
    }
    .mb-3 {
        margin-bottom: 10px !important;
    }
    .mb-2 {
        margin-bottom: 5px !important;
    }
    .mb-1 {
        margin-bottom: 0px !important;
    }
    .mobile-card {
        display: block;
    }
    .mb-show {
        display: block;
        text-align: center !important;
        margin: 0 auto !important;
    }
    .dskt-show {
        display: none;
    }
    .ttl-arrw,
    .date-ttl,
    .live-vd,
    .count-li,
    .dlformt-blk,
    .dlformt-blk-lg {
        text-align: center;
    }
    .ft-call {
        background: #ffd700;
        padding: 10px 25px;
        margin: 5px 0 25px;
        display: inline-block;
        max-width: 100%;
        color: #013368;
        position: relative;
    }
    .ft-call-lg {
        background: #ffd700;
        padding: 10px 25px;
        margin: 5px 0 25px;
        display: inline-block;
        max-width: 100%;
        color: #013368;
        position: relative;
    }
    .ft-call-pg-diploma {
        background: #ffd700;
        padding: 10px 25px;
        margin: 5px 0 25px;
        display: inline-block;
        max-width: 100%;
        color: #013368;
        position: relative;
    }
    .ttl-arrw h5 {
        margin: 0 !important;
        padding: 0;
    }
    .innerdiv-2 {
        display: block !important;
    }
    .banner-theblk {
        margin-top: 0px;
    }
    .mb-none,
    .card-dskt-show {
        display: none;
    }
    .mobile-card-block > h3 {
        text-align: center;
        color: #013368;
        font-size: 25px;
        line-height: 30px;
        font-family: Arial, sans-serif;
        font-weight: bold;
    }
    /*.mobile-card .owl-nav{top:4px;}*/
    .dnld-btn2 {
        width: 85px;
    }
    .ctc-mb-item {
        background: #fff;
        margin: 10px 0;
        padding: 9px;
        left: -7px;
        position: relative;
    }
    .ctc-mb-item > .cnt-mimg {
        margin-bottom: 25px;
    }
    .ctc-mb-item > ul {
        padding: 0 5px;
    }
    /*.contact-form-course{background: none !important;background-color: #000;}*/
    .watch-part {
        background: none !important;
        background-color: #02132b !important;
        padding-bottom: 20px;
    }
    .watch-part-pg-diploma {
        background: none !important;
        padding-bottom: 20px;
    }
    .watch-part-lg {
        padding-bottom: 60px;
    }
    .watch-part-lg-color {
        background-image: linear-gradient(
            120deg,
            rgba(2, 19, 43, 1) 0%,
            rgba(99, 182, 211, 1) 50%
        ) !important;
    }
    .vert-move,
    .vert-move2,
    .vert-move1 {
        animation: none !important;
    }

    .float-right.ctc-btn-spc {
        text-align: center;
        width: 100%;
        margin: 25px 0 0;
    }
    .call-usblk {
        background-size: cover;
        height: 100%;
        float: left;
        width: 100%;
    }
    /*.mobile-card .owl-nav {
    top: 4px;
    margin: 25px auto;
    }*/
    .bnd-three-blk {
        height: auto !important;
    }
    .new-mn {
        display: none;
    }
    .for-enterprise {
        padding: 100px 0;
    }
    .enterprises-box-pos {
        position: relative;
        top: 0;
        width: 100%;
    }
    .enterprises-box-pos .business_box {
        min-height: auto;
        border-radius: 15px;
        margin: 15px auto;
        position: relative;
    }
    .enterprises-box-pos .business_box p {
        margin: 0;
    }
    .reachout_bg::before,
    .reachout_bg::after,
    .dashed-line-fix,
    .wide-array::before,
    .wide-array::after,
    .inner_img.dashed-line.green_box::before,
    .inner_img.dashed-line.pink_box::before,
    .inner_img.dashed-line.yellow_box::before,
    .inner_img.dashed-line::after,
    .dashed-horizontal,
    .pg-dashed-horizontal {
        display: none;
    }
    .wide-array .inner_img {
        margin-top: 50px;
    }
    .fee-dtltbl {
        margin: 25px 0;
    }
    .wide-array .inner_img img {
        position: relative;
        top: 10px;
        left: auto;
        right: auto;
        margin: 0 auto;
        margin-left: auto;
        margin: 0 auto;
        z-index: 9999999;
    }
    .reachout_form .bg-white.shadow.p-5 {
        padding: 25px !important;
        width: 100% !important;
        max-width: 100% !important;
    }
    .mobile-menu ul {
        float: left;
    }
    .mobile-menu ul li.icon_only > a {
        font-size: 12px;
        padding: 0;
        width: 34px;
        color: #013368;
    }
    .copy-right {
        margin-bottom: 15px;
    }
    .outdiv.mobile_fix_box {
        overflow: hidden;
    }
    .mobile_fix_box .ctc-mb-item.contus-blk {
        box-shadow: none;
        background: transparent;
    }

    .sidebar_in_box {
        margin-top: 0px;
    }
    .upcmg-btcblk ul li {
        float: left !important;
    }
    .new-cont-crc .ctc-btn {
        padding: 10px 30px !important;
    }

    .check_bg {
        margin-top: 30px;
    }
    .curr_ul_tab li {
        margin: 0 0 10px;
        float: left;
        width: 100%;
        text-align: left;
    }
    .curr_ul_tab li a {
        float: left;
        width: 100%;
    }
    .pg-curr_ul_tab li {
        margin: 0 0 10px;
        float: left;
        width: 100%;
        text-align: left;
    }
    .pg-curr_ul_tab li a {
        float: left;
        width: 100%;
    }
    .term-modules {
        margin: 0px !important;
    }
    .faq {
        margin: 0;
    }
    .free_resources .industr-blk {
        width: auto;
        margin: 15px;
    }
    .free_resources.we_offers {
        padding: 30px 0;
    }
    .col-md-4.ipad_fix,
    .say-about .business_anl .ipad_fix {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0 0 25px;
    }
    .sidebar_in_box .float-left {
        float: none !important;
    }
    .term-modules.free_resources.position-relative {
        position: relative !important;
        float: left !important;
        width: 100% !important;
    }
    .verify_mb_1 {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden auto;
        perspective: 1300;
        background-color: rgb(0, 34, 72);
        transition: opacity 0.3s linear 0s;
        opacity: 1;
    }
    .verify_mb_2 {
        position: relative;
        margin: 0% auto;
        width: unset;
        border: none;
        background: none;
        overflow: auto;
        border-radius: 4;
        outline: none;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 10px;
        transition: all 0.3s linear 0s;
        transform: scale(1);
        opacity: 1;
    }

    .term-modules.free_resources.position-relative .industr-blk {
        position: relative !important;
        float: left !important;
        width: calc(100% - 30px) !important;
        margin: 15px;
    }
    .card-container.text-left.offer_right_card {
        padding: 15px;
    }
    .sidebar_in_box ul li {
        width: 100% !important;
    }
    .signin_v2 .sidebar_in_box .float-right {
        float: left !important;
        width: 100%;
        display: inline-block;
        margin: 30px auto;
    }
    .signin_v2 .form-signin.mobi_show {
        position: relative;
        float: left;
        width: 100%;
        margin: 30px !important;
        padding: 15px !important;
        display: block !important;
    }
    .signin_v2.sidebar_in {
        margin-bottom: 0px;
    }
    .sidebar_in .institute_inner_content ul {
        padding: 0 !important;
    }
    .sign_in_box_parent .sidebar_in_box ul li {
        width: calc(100% - 15px) !important;
    }
    .mobile_hide {
        display: none !important;
    }
    .mobi_hide {
        display: none;
    }
    .proj_50 li {
        width: 100%;
    }
    .fee-dtltbl,
    .two-creer {
        min-height: auto !important;
    }
    .right-block {
        padding: 0;
    }
    .right-block-inner .nav.curr-ul.curr_ul_tab.pg-curr_ul_tab,
    .right-block-inner .tab-content {
        padding: 15px;
        margin: 0;
    }
    .right-block-inner-lg .nav.curr-ul.curr_ul_tab.pg-curr_ul_tab,
    .right-block-inner-lg .tab-content {
        padding: 15px;
        margin: 0;
    }
    .item.crc-incld-new {
        margin: 0 auto 30px;
    }
    .curr_ul_tab li a {
        float: left;
        width: 100%;
        max-width: 100%;
    }
    .right-block br {
        display: inline-block;
        line-height: 20px;
        float: none;
        width: 100%;
    }
    .owl-carousel .owl-stage-outer {
        padding: 25px 0;
    }
    #sidebar-m .left-menu-ul .left-menu-li .fa::before {
        /* content: '\f080' !important; */
        font: normal normal normal 14px/1 FontAwesome !important;
    }
    #sidebar-m .left-menu-ul .left-menu-li .far::before {
        content: "\f152" !important;
        font: normal normal normal 14px/1 FontAwesome !important;
    }
    .full_shadow_cover {
        float: left;
        width: 100%;
        padding: 30px;
    }
    .full_shadow_cover-p-ty {
        padding: 30px 30px 30px 30px !important;
    }
    .full_shadow_cover .float-left.border-bottom {
        float: left !important;
        width: 100% !important;
        text-align: center;
    }
    .full_shadow_cover .float-left.border-bottom a {
        float: none !important;
        width: 100% !important;
        text-align: center;
        margin-bottom: 15px !important;
    }
    .full_shadow_cover .dnld-btn.float-right {
        float: none;
        width: 100% !important;
        text-align: center;
    }

    .mobile-menu ul li .dnld-btn a span {
        display: none !important;
    }
    .mobile-menu ul li .pg-dnld-btn a span {
        display: none !important;
    }
    .mobile-menu ul {
        width: 100%;
    }
    .thre-rghimg {
        top: 15px;
    }
    html,
    body {
        float: left;
        width: 100%;
        overflow-x: hidden;
    }
    .footer-ul {
        float: left;
        width: 100%;
        margin: 15px 0 0;
    }
    .footer-ul li {
        float: left;
        width: 100%;
        margin: 0 0 15px !important;
    }
    .footer-ul li h4 {
        margin-bottom: 10px;
    }
    .font-48,
    .title-42,
    .top-hed h1,
    h1 {
        font-size: 24px !important;
        line-height: 34px !important;
    }
    .Call-head,
    .call-usblk h4,
    .ttl-arrw h5,
    h2,
    h3 {
        font-size: 20px !important;
        line-height: 30px !important;
    }
    .col-md-4.ipad_fix,
    .say-about .business_anl .ipad_fix,
    .say-about .business_anl,
    .col-md-4.ipad_fix .p-0.float-left {
        -ms-flex: 0 0 100% !important;
        flex: 0 0 100% !important;
        max-width: 100% !important;
        width: 100%;
    }
    .mobi_hide {
        display: none !important;
    }
    .top-bg-banner {
        background: transparent;
    }
    .mb-show .three-blk.outdiv.h-auto.mb-0 {
        min-height: 120px !important;
        margin: 0;
        padding: 5px;
    }
    .mb-show .bnd-three-blk {
        border-radius: 15px;
    }
    .mb-show .three-blk.outdiv {
        border-radius: 15px;
    }
    .mb-show .timing li {
        color: #013368 !important;
    }
    .mb-show .three-blk.outdiv .total-head-prc {
        padding: 15px 0 5px;
    }
    .mb-show .three-blk.outdiv:before {
        content: "";
        background: url(../images/arrowdown.png) no-repeat;
        background-size: contain;
        background-position: top center;
        position: absolute;
        width: 13px;
        height: 10px;
        bottom: 10px;
        transform: rotate(90deg);
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: transparent;
        -webkit-animation: action 1s infinite alternate;
        animation: action 1s infinite alternate;
    }

    /*17062020*/
    .top-hed {
        float: left !important;
        width: 100% !important;
        text-align: center !important;
        margin: 0 auto !important;
    }
    .top-bg-banner {
        text-align: center;
    }
    .arro-blk {
        margin: 40px 0 0;
        text-align: center;
        width: 100%;
        padding: 0;
        float: left;
    }
    .arro-blk p {
        text-align: center;
    }
    .spn-bdg {
        font-size: 15px !important;
        font-weight: normal !important;
    }
    .arro-blk span {
        font-size: 20px !important;
        line-height: normal !important;
        color: #013368;
        text-transform: uppercase;
        font-family: Arial, sans-serif;
        font-weight: bold;
        background: transparent;
        left: auto;
        right: auto;
        margin: 0 !important;
    }
    section.contr-section.align-items-center .col-md-3 {
        text-align: center !important;
    }
    section.contr-section.align-items-center .col-md-3 .count {
        display: inline-flex;
        min-width: 230px;
    }
    section.contr-section.align-items-center .col-md-3 .count .count-hd {
        text-align: left;
    }
    .checkout-mr a {
        max-width: 310px;
    }
    .footer-ul li {
        padding: 0 !important;
    }
    .say-about h1.font-48.col-md-12.mt-5.pt-4.pb-5 {
        margin: 25px 0 0 !important;
        padding: 0 !important;
    }
    .checkout-mr.text-center.mb-5.mt-4 {
        margin: 0 !important;
        padding: 0 !important;
    }
    .contact-form-course .Call-head {
        color: #fff;
        float: left;
        padding: 0 0 25px;
        width: 100% !important;
    }
    .crc-incld-img {
        background-size: cover;
    }
    .card-new .term-blk {
        margin: 25px auto;
        position: relative;
        max-width: 280px;
        text-align: center;
    }
    .mobile-card-block .owl-carousel .owl-stage-outer {
        padding: 0;
    }
    .mobile-card-block .owl-carousel .owl-stage-outer .item {
        max-width: 300px;
        margin: 15px auto 40px;
    }
    .mobile-card-block
        .owl-carousel
        .owl-stage-outer
        .owl-carousel
        .owl-item
        img {
        display: inline-block;
    }
    .crc-incld-new h2 {
        padding: 20px;
    }

    .new-locatblk.text-left .outdiv {
        float: left;
        width: 100%;
        display: block;
    }
    .min-ht .item.bnd-three-blk {
        float: left;
        width: 100%;
        display: block;
    }
    .new-locatblk {
        margin: 0 auto;
        display: inline-block;
        width: auto;
        min-width: 300px;
    }
    .totl-buton {
        float: none;
        width: 100%;
        max-width: 100%;
    }

    ul.watch-banner {
        text-align: center;
        margin: 0 auto;
    }
    .counrse-contn {
        text-align: center;
    }
    .counrse-contn-pg-diploma {
        text-align: center;
        font-size: 13px;
        line-height: 18px;
    }
    .counrse-contn-pg-diploma-analytics {
        text-align: left;
        font-size: 13px;
        line-height: 18px;
    }
    .con-dnld-btn ul li {
        float: left;
        display: block;
        clear: none;
        text-align: center;
        width: 100%;
    }
    /*.dnld-btn.con-dnld-btn {
    float: left;
    width: 100%;
    text-align: center;
    margin: auto;
}
.dnld-btn.con-dnld-btn ul {
    width: 100%;
    margin: 0 auto;
    display: inline-block;
    text-align: center;
}*/
    ul.cont-twdvs {
        float: left;
        width: 100%;
        margin: 0 0 50px;
        text-align: center;
    }
    section.say-about.meet-us h1.font-48 {
        text-align: center !important;
        margin: 0 auto !important;
    }
    section.say-about.meet-us {
        padding: 0 !important;
    }
    .for-enterprise {
        text-align: center;
    }
    .for-enterprise .title-42 {
        text-align: center !important;
    }
    span.green-ng.ml-3 {
        margin: 0 !important;
        text-align: center;
        left: auto;
    }
    .enterprises-box-pos {
        position: relative;
        top: 0;
        text-align: center;
        width: 100%;
        display: inline-block !important;
    }
    .wide-array {
        margin: 30px auto;
    }
    h1.logos-head.pt-5,
    h2.fee-hed.pt-5 {
        padding: 0 !important;
    }
    .for-enterprise {
        background: linear-gradient(-45deg, #05203c, #004a9a);
    }
    .enterprise-font {
        font-size: 16px;
    }
    .our-faculty {
        background: linear-gradient(-45deg, #05203c, #004a9a);
    }
    .why-any-section {
        background: linear-gradient(-45deg, #05203c, #004a9a);
        color: #fff;
    }
    .why-bg {
        background: linear-gradient(-45deg, #05203c, #004a9a) no-repeat !important;
        background-size: cover !important;
        background-position: center right;
        position: relative;
        width: 100%;
        text-align: center;
        color: #fff;
    }
    .why-bg .top-hed.mt-5.pt-5,
    .why-bg .top-hed.mt-5.pt-5 h1,
    .why-bg p.bnr-cont.pb-5 {
        color: #fff;
        margin: 0 !important;
    }
    .ahed-curve,
    .why-block-innr {
        text-align: center;
    }
    .crc-incld-new.w-100.float-left.bg-white {
        margin-top: -25px !important;
    }

    .blog-section .arro-blk span {
    }
    .blog-section .expand-h6.mx-4 {
        float: left;
        width: 100%;
        margin: 0 !important;
        padding: 0 0 25px !important;
    }
    .blog-section .countpay {
        float: left;
        width: 100%;
        display: block;
    }
    .blog-section .countpay li {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        position: relative;
        padding-right: 100px;
        margin-bottom: 10px;
        text-align: left;
    }
    .blog-section .pay-ttl {
        font-weight: bold;
        position: absolute;
        right: 0 !important;
        top: 0;
    }
    .blog-section .countpay-totlpay-ttl {
        text-align: right;
        font-size: 14px;
        line-height: 20px;
        position: relative;
        position: relative;
        padding-top: 0px;
    }
    .term-modules.we_offers {
        padding: 15px;
    }
    .mobile-card .card-new:hover .side,
    .mobile-card .card-new:focus .side {
        opacity: 1;
    }
    .mobile-card .card-new .dnld-btn {
        display: none;
    }
    .mobile-card .side ul.pt-4.pb-4 {
        margin: 0 !important;
        padding: 0 !important;
    }
    .mobile-card .card-new .term-blk {
        margin: 25px auto;
        border-radius: 0px;
    }
    .mobile-card .side::before {
        content: "";
        background: url(../images/arrowdown.png) no-repeat;
        background-color: rgba(0, 0, 0, 0);
        background-position-x: 0%;
        background-position-y: 0%;
        background-size: auto;
        background-size: contain;
        background-position: top center;
        position: absolute;
        width: 13px;
        height: 10px;
        bottom: 3px;
        transform: rotate(90deg);
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: transparent;
        z-index: 99;
        -webkit-animation: action 1s infinite alternate;
        animation: action 1s infinite alternate;
    }
    .mobile-card .side .timing-blk {
        padding: 25px;
    }
    .mobile-card .card-new .back .term-blk {
        margin: 0 auto;
        box-shadow: none;
    }
    .mobile-card .card-new:hover .back,
    .mobile-card .card-new:focus .back {
        position: relative;
    }
    .mobile-card .card-new .back .green-ng {
        display: none;
    }
    .mobile-card .tools-block h4 {
        padding: 0;
    }
    .mobile-card .back .crc-incld-img {
        background-size: contain;
        width: 100%;
        margin: 0;
        padding: 0;
        overflow: hidden;
        min-height: 100%;
        background-position: center top;
    }
    .mobile-card .card-new .side:hover .term-blk,
    .mobile-card .card-new .side:focus .term-blk {
        margin: 25px auto 0;
    }
    .mobile-card .tools-block h4 {
        padding: 25px 0 0;
    }
    .mobile-card .card-new:hover .back,
    .mobile-card .card-new:focus .back {
        top: -25px !important;
        margin-bottom: 25px;
    }
    .mobile-card .crc-incld-img2 {
        background: url(../images/bg-11.png) no-repeat;
    }
    .mobile-card .card-new .back .term-blk {
        box-shadow: 0px 15px 15px 5px rgba(0, 0, 0, 0.1);
    }
    .mobile-card .countpay-5-block {
        top: -30px;
    }
    .mobile-card .side .crc-incld-new h2 {
        padding: 25px 0 0 15px;
        position: absolute;
        top: 0;
        text-align: left;
        color: #fff;
        font-size: 18px !important;
        max-width: 190px;
        line-height: normal !important;
    }
    .term-modules.free_resources.position-relative .industr-blk {
        position: relative !important;
        float: left !important;
        width: calc(100% - 0px) !important;
        margin: 0 0 15px;
    }
    .term-modules.we_offers2 .crc-incld-new.w-100.float-left.bg-white {
        margin-bottom: 0px;
    }
    .blog-section .arro-blk {
        margin: 0px;
    }
    section.say-about.meet-us h1.font-48 {
        padding: 0 !important;
    }
    .top-bg-banner .top-hed.mt-5 {
        margin: 0 !important;
    }
    .sidebar_content_out.ipad_full_wid {
        margin: auto !important;
    }
    .sidebar_in {
        position: relative !important;
    }
    .sidebar_in.sign_in_box_parent.ipad_full_wid,
    main.sidebar_content_out.ipad_full_wid {
        position: relative !important;
        margin: auto !important;
        display: block !important;
    }
    .crc-incld-img img.tools_img {
        height: 35px;
    }
    .back .term-blk ul li.mb-1::before {
        left: auto;
        margin-left: -25px;
    }
    .back .term-blk ul li {
        margin-left: 0px;
    }
    .why-block-innr {
        min-height: auto;
    }
    .only-ttl h1.font-48::before {
        content: "";
        background: url(../images/arrowdown.png) no-repeat;
        background-color: rgba(0, 0, 0, 0);
        background-position-x: 0%;
        background-position-y: 0%;
        background-size: auto;
        background-size: contain;
        background-position: top center;
        position: absolute;
        width: 13px;
        height: 10px;
        bottom: 15px;
        transform: rotate(90deg);
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: transparent;
        z-index: 99;
        -webkit-animation: action 1s infinite alternate;
        animation: action 1s infinite alternate;
    }
    @-webkit-keyframes action {
        0% {
            transform: translateY(0);
        }
        100% {
            transform: translateY(-10px);
        }
    }
    @keyframes action {
        0% {
            transform: translateY(0);
        }
        100% {
            transform: translateY(-10px);
        }
    }
    .sign_in_box_parent .sign_in_box_w33 {
        width: calc(100% - 30px) !important;
        min-width: 100%;
        width: 100% !important;
    }
    .sidebar_in,
    .col-md-7.bg-faded.sidebar_in.sign_in_box_parent.ipad_full_wid,
    main.col-md-5.offset-md-7.sidebar_content_out.ipad_full_wid {
        position: relative;
        max-width: 100%;
        width: 100%;
        display: block;
        margin: auto;
    }
    .sitemap .crc-incld-new {
        width: calc(100% - 30px);
        margin-bottom: 30px;
        min-height: auto;
    }
    section.say-about.meet-us .desktop-hide {
        display: inline-block !important;
    }
    .header-lg {
        min-height: 1365px;
    }
    .w-i-t-c {
        width: auto !important;
    }
    .right-block-inner-lg {
        text-align: center;
    }
    .dlformt-blk-lg p {
        margin-right: 0px;
    }
    .career-title-lg-download-box {
        font-size: 25px;
    }
    .career-sub-title-lg-download-box {
        font-size: 20px;
    }
    .form-lg {
        position: relative !important;
    }
    .form-pg-diploma {
        position: relative !important;
    }
    .display-f-h-center{
        display: flex;
        justify-content: center;
        width: 100% !important;
    }
    .pg-admin-icon-center {
        margin-top: 0px !important;
    }
    .pg-admissionn-f-left {
        float: none !important;
    }
    .pg-admissionn-f-right {
        float: none !important;
    }
    .pg-admissionn-t-a-left{
        text-align: center !important;
    }
    .pg-admissionn-t-a-right{
        text-align: center !important;
    }
    .pg-admissionn-right{
        height: auto !important;
        margin-right: 0px !important;
    }
    .pg-admissionn-left{
        height: auto !important;
    }
    .right-download-icon{
        justify-content: center;
    }
    .course-structure-outer{
        background-image: none !important;
        background-color: #1A479C;
        min-height: 360px !important;
        margin-bottom: 10px;
    }
    .course-structure-inner{
        width: auto !important;
    }
    .list-content{
        flex: 0.9;
        text-align: left;
    }
    .list-icon{
        flex: 0.1;
    }
    .pg-diploma-header{
        background-image:none !important;
        background-color: #CAE4F9;
    }
    .pg-diploma-header-analytics {
        background-image: none !important;
        background-color: #CAE4F9;
    }
    .hat-3d{
        top: -24% !important;
        width: 100px;
        left: -45px !important;
    }
    .tools-list-outer{
        bottom: -148px !important;
    }
    .text-format{
        text-align: center !important;
    }
    .nab-bg{
        padding: 10px 10px !important;
    }
    .nav-outer-pg{
        justify-content: center !important;
    }
    .downlaod-brochure-pg-btn{
        margin-right: 0px !important;
    }
    .need-help-pg-btn{
        margin-left: 0px !important;
    }
    .fnt-title-pg-diploma{
        font-size: 13px !important;
    }
    .learning-modes-container {
        flex: auto !important;
    }
    .pg-nav-list{
        border: 1px solid #fff;
        border-radius: 5px !important;
        text-align: center;
        padding: 3px;
        margin-right: 10px;
        margin-bottom: 10px;
    }
    .counrse-contn-pg-diploma li{
        text-align: justify;
    }
    .counrse-contn-pg-diploma-analytics li {
        text-align: justify;
    }
    .mobile-font{
        font-size: 15px !important;
    }
    .tree-card-inqure-mb{
        margin-bottom: 50px !important;
    }
    .thank_you_sub_title {
        font-size: 15px !important;
        line-height: 20px !important;
    }
    .bottom-number{
        padding: 0 20px;
    }
    .pg-cert-over-view-description{
        justify-content: center !important;
        padding-left: none !important;
        margin-top: 10px;
    }
    .pg-cert-over-view-title{
        font-size: 23px !important;
    }
}

@media only screen and (max-width: 480px) {
    .ft-call {
        min-width: 260px;
    }
    .ft-call-lg {
        min-width: 260px;
    }
    .ft-call-pg-diploma {
        min-width: 260px;
    }
    img.ls-is-cached.lazyloaded {
        max-width: 260px;
    }
}
.mapping_img {
    background: none;
    position: relative;
}
.mapping_img li {
    position: relative;
    width: calc(35% - 30px);
    margin: 0 10px;
    border-radius: 10px;
    box-shadow: 0px 3px 15px 3px rgba(81, 78, 78, 0.3);
    padding: 15px;
}
.mapping_img li:before {
    content: "";
    right: 0px;
    top: 0;
    background: url(../images/loc_pin.png) no-repeat;
    z-index: 99999;
    top: 1px;
    z-index: 999999;
    position: absolute;
    height: 90px;
    width: 60px;
    background-size: cover;
    opacity: 0.2;
}
.mapping_img_2 {
    background: none;
    position: relative;
}
.mapping_img_2 li {
    position: relative;
    width: calc(35% - 30px);
    margin: 0 10px;
    border-radius: 10px;
    box-shadow: 0px 3px 15px 3px rgba(81, 78, 78, 0.3);
    padding: 15px;
}
.mapping_img_2 li:before {
    content: "";
    right: 0px;
    top: 0;
    background: url(../images/loc_pin.png) no-repeat;
    z-index: 99999;
    top: 1px;
    z-index: 999999;
    position: absolute;
    height: 60px;
    width: 45px;
    background-size: cover;
    opacity: 0.2;
}
.green-dt {
    background: #78ffd2;
}
.yl-dt {
    background: #ffef96;
}
.pink-dt {
    background: #ff8ea8;
}
.bg-white.business_box.green_border {
    float: left;
    width: 100%;
    margin: 0;
    padding: 25px 15px;
    border-radius: 10px;
    max-height: 450px;
    overflow-x: hidden;
}
@media only screen and (max-width: 767px) {
    .contact-card {
        width: 90%;
        margin-left: 0px;
    }
    .count-ul-inr.mapping_img {
        width: 100%;
        max-width: 100%;
        height: auto;
        float: left;
        background-size: cover;
        margin-left: 0;
    }
    .count-ul-inr.mapping_img_2 {
        width: 100%;
        max-width: 100%;
        height: auto;
        float: left;
        background-size: cover;
        margin-left: 0;
    }
    .mapping_img li::before {
        content: "";
        right: 0px;
        top: 0;
        background: url(../images/loc_pin.png) no-repeat;
        background-size: auto;
        z-index: 99999;
        top: 1px;
        z-index: 999999;
        position: absolute;
        height: 60px;
        width: 40px;
        background-size: cover;
        opacity: 0.2;
    }
    .mapping_img_2 li::before {
        content: "";
        right: 0px;
        top: 0;
        background: url(../images/loc_pin.png) no-repeat;
        background-size: auto;
        z-index: 99999;
        top: 1px;
        z-index: 999999;
        position: absolute;
        height: 60px;
        width: 40px;
        background-size: cover;
        opacity: 0.2;
    }
    .wide-array .inner_img .icon_bg {
        position: relative;
        top: 0;
    }
    .bg-white.business_box.green_border {
        max-height: auto;
    }
    .cancle-icon {
        right: 59px !important;
        top: 3px !important;
    }
    .fz-14 {
        font-size: 14px !important;
    }
    .career-graph-list-outer {
    flex-direction: column;
    }

    .career-graph-list-inner {
        width: 100%;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 480px) {
    .contact-card {
        width: 92%;
        margin-left: 0px;
    }
    img.ls-is-cached.lazyloaded {
        max-width: 300px;
    }
}

.ml-15 {
    margin-left: 15px;
}

.top-locaion-item {
    font-size: 24px !important;
    line-height: 34px !important;
}
.fl-left {
    float: left;
}
@media only screen and (min-width: 768px) and (max-width: 990px) {
    .blog-section {
        margin: 50px auto 0px;
    }
    .blog-section .expand-h6.mx-4 {
        float: none;
        width: 100%;
    }
    .countpay-totl {
        width: 50%;
    }
    .cancle-icon {
        right: 35px !important;
        top: -2px !important;
    }
    .fz-14 {
        font-size: 14px !important;
    }
}

.cancle-icon {
    content: "";
    position: absolute;
    background-image: url(/static/media/plus-2.png) !important;
    background-repeat: no-repeat !important;
    height: 20px;
    width: 55px;
}

@media only screen and (min-width: 990px) {
    .cancle-icon {
        right: 18px !important;
        top: -2px !important;
    }
    .last-img-img img {
        margin-top: 75px;
    }
    .tools-sec-top {
        margin-bottom: 10px;
    }
    .tools-sec-top-lg {
        margin-bottom: 40px;
    }
}
.learning-modes-outer {
    display: flex;
    flex-direction: row;
}
.learning-modes-container {
    flex: 50%;
    display: grid;
    place-items: center;
}
.learning-modes-container-amount {
    color: #27b2e7;
    font-size: 20px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
}
.date-ttl-center {
    text-align: center;
}
.thin-fnt-title {
    color: #013368;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin: 7px 0px;
    font-weight: 500;
    font-family: Arial, sans-serif;
}
.fnt-title-pg-diploma {
    color: #000000;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin: 7px 0px;
    font-weight: normal;
    font-family: Arial, sans-serif;
}
.list-lg {
    list-style: initial;
    font-size: 20px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.download-box-lg {
    background-color: #f4f4f4;
    border-radius: 30px;
    padding: 25px;
}
.header-lg {
    background-size: 100% 100%;
    background-position: top right;
    position: relative;
}
.form-lg {
    display: flex;
    justify-content: center;
    z-index: 1;
    text-align: initial;
    position: absolute;
    border-radius: 10px;
    box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    margin-bottom: 40px;
    bottom: 0;
    max-width: 436px;
}
.form-pg-diploma {
    display: flex;
    justify-content: center;
    z-index: 1;
    text-align: initial;
    border-radius: 10px;
    box-shadow: 0px 3px 15px 5px rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;
    margin-top: 20px;
    bottom: 0;
    max-width: 400px;
    width: 100%;
    margin-bottom: 10%;
}
.w-i-t-c {
    width: fit-content;
}
.mh-500 {
    min-height: 550px;
}
@media only screen and (min-width: 365px) and (max-width: 380px) {
    .form-lg {
        bottom: unset;
        top: 190%;
    }
    .form-pg-diploma {
        bottom: unset;
        top: 190%;
    }
}
@media only screen and (min-width: 381px) and (max-width: 414px) {
    .form-lg {
        bottom: unset;
        top: 185%;
    }
    .form-pg-diploma {
        bottom: unset;
        top: 185%;
    }
}
@media only screen and (max-width: 364px) {
    .form-lg {
        bottom: unset;
        top: 185%;
    }
    .form-pg-diploma {
        bottom: unset;
        top: 185%;
    }
}
@media only screen and (min-width: 416px) and (max-width: 516px) {
    .form-lg {
        bottom: auto;
        top: 175%;
        max-width: none;
    }
    .form-pg-diploma {
        bottom: auto;
        top: 175%;
        max-width: none;
    }
    .tools-sec-lg {
        margin: auto;
    }
    .tools-sec-pg-diploma {
        margin: auto;
    }
    .header-lg {
        min-height: 1300px;
    }
}
@media only screen and (min-width: 517px) and (max-width: 616px) {
    .form-lg {
        bottom: auto;
        top: 165%;
        max-width: none;
    }
    .form-pg-diploma {
        bottom: auto;
        top: 165%;
        max-width: none;
    }
    .tools-sec-lg {
        margin: auto;
    }
    .tools-sec-pg-diploma {
        margin: auto;
    }
    .header-lg {
        min-height: 1360px;
    }
}
@media only screen and (min-width: 617px) and (max-width: 765px) {
    .form-lg {
        bottom: auto;
        top: 158%;
        max-width: none;
    }
    .form-pg-diploma {
        bottom: auto;
        top: 158%;
        max-width: none;
    }
    .tools-sec-lg {
        margin: auto;
    }
    .tools-sec-pg-diploma {
        margin: auto;
    }
    .header-lg {
        min-height: 1400px;
    }
}
@media only screen and (min-width: 993px) and (max-width: 1365px){
    .career-title-lg {
        font-size: 35px;
        line-height: 35px;
    }
}

@media only screen and (min-width: 768px){
    .form-outer{
        width: 30%;
        justify-content: right;
    }
}
.faq-inner{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.react-player {
    border-radius: 20px;
  }

@media only screen and (min-width: 767px) and (max-width: 815px){
    .tab-view-watch-ply a {
        margin-top: 115px !important;
        text-align: end;
    }
    .logo-size{
        width: 35%;
        margin-right: 45px
    }
}
@media only screen and (min-width: 816px) and (max-width: 915px){
    .tab-view-watch-ply a {
        margin-top: 115px !important;
        text-align: end;
    }
    .logo-size{
        width: 35%;
        margin-right: 45px;
        margin-top: 40px;
    }
}
@media only screen and (min-width: 916px){
    .logo-size{
        width: 22%;
        margin-top: 35px;
    }
}
@media only screen and (min-width: 1470px){
    .logo-size{
        width: 17%;
        margin-top: 35px;
    }
}
@media only screen and (min-width: 1365px) and (max-width: 1474px){
    .tools-list-outer{
        bottom: 20px !important;
    }
}
.flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex-1-center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}
.f-0-8{
    flex: 0.8;
}
.f-1{
    flex: 1;
}
.fn-blue{
   color: #013368
}
.h-100-p{
    height: 100%;
}
.w-100-p{
    width: 100%;
}
.w-67-p{
    width: 67%;
}
.w-33-p{
    width: 33%;
}
.mt-8{
    margin-top: 80px;
}
.course-overView-outer-top{
    margin-top: 165px;
}
.learning-modes-pg-diploma{
    background-color: #F7F7F7;
}
.nab-bg{
    background-image: linear-gradient(to top, #135C77 , #24ADE1);
    padding: 10px 80px;
    border-radius: 10px;
    display: block !important;
}
.nav-pg-list{
    color: #fff;
}
.nav-pg-list:hover{
    color: #fff;
    font-weight: bolder;
    text-shadow: 2px 2px 4px #000000;
}
.nav-outer-pg{
    display: flex !important;
    justify-content: space-between;
}
.course-structure-outer{
    background-image:url("../images/mask-group.png");
    background-size: 100% 100%;
    min-height: 500px;
    position: relative;
    border-radius: 10px;
    padding: 50px 30px;
}
.course-structure-inner{
    width: 40%;
}
.dottedb-border-bottom{
    border-bottom: 1px #fff dotted;
}
.pg-car-bg1-btn.active {
    background-color: #D6F0FB;
    border: 2px solid #013368;
}
.pg-car-bg1-btn {
    background-color: #D6F0FB;
}
.pg-car-bg2-btn.active {
    background-color: #FFBB00;
    border: 2px solid #013368;
}
.pg-car-bg2-btn {
    background-color: #FFBB00;
}
.pg-car-bg3-btn.active {
    background-color: #F3A5DC;
    border: 2px solid #013368;
}
.pg-car-bg3-btn {
    background-color: #F3A5DC;
}
.pg-car-bg4-btn.active {
    background-color: #DDFFB7;
    border: 2px solid #013368;
}
.pg-car-bg4-btn {
    background-color: #DDFFB7;
}
.term-title{
    color: #003368;
}
.terms-outer{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 15px 20px;
    border-radius: 10px 10px 0px 0px;
    background-color: #2889E0;
}
.blue-bg{
    background-color: #25B2E7 !important;
}
.admission-icon{
    border-radius: 50%;
    width: fit-content;
    padding: 15px;
}
.pg-admin-icon-center{
    margin-top: -30px;
}
.text-center{
    text-align: center;
}
.text-left{
    text-align: left;
}
.pg-admissionn-f-left{
    float: left;
}
.pg-admissionn-f-right{
    float: right;
}
.pg-admissionn-t-a-left{
    text-align: left;
}
.pg-admissionn-t-a-right{
    text-align: right;
}
.pg-admissionn-t-a-center{
    text-align: center;
}
.pg-admissionn-right{
    height: 16%;
    margin-right: 30%;
}
.pg-admissionn-left{
    height: 16%;
}
.pg-diploma-faq{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 10px 0px !important ;
}
.no-border{
    border: none;
}
.right-download-icon{
    display: flex;
    align-items: center;
}
.pg-diploma-btn-center{
    display: flex;
    justify-content: center;
}
.bg-white{
    background-color: #fff;
}
.hand-cursor{
    cursor: pointer;
}
.pg-diploma-header{
    background-image:url("../images/pg-diploma/BannerCourseHD.jpg");
    background-color: #CAE4F9;
    background-size: 100% 100%;
    background-position: top right;
    position: relative;
    margin-bottom: 165px;
}
.pg-diploma-header-analytics {
    /* background-image: url("../images/pg-diploma/BannerCourseHD.jpg"); */
    background-color: #CAE4F9;
    background-size: 100% 100%;
    background-position: top right;
    position: relative;
    margin-bottom: 165px;
}
.blue-line{
    border: 1px solid #25B2E7;
    width: 50%;
}
.hat-3d{
    background-image:url("../images/pg-diploma/35-355140_graduation-hat-png-free-graduation-cap-png-vector.svg");
    background-size: 100% 100%;
    background-position: top right;
    position: relative;
    z-index: 4;
    height: 165px;
    position: absolute;
    right: 86%;
    left: -11%;
    top: -32%;
}
.tools-list-outer{
    position: absolute;
    bottom: 25px;
    left: 5%;
    right: 5%;
}
.text-format{
    text-align: left;
}
.text-format li{
   list-style: unset;
}
.text-format ol{
   margin-left: 30px;
}
.color-yellow{
    color: #FFD700;
}
.fn-20{
    font-size: 20px;
}
.fn-46{
    font-size: 46px;
}
.course-eligibility-img{
    height: 100%;
    width: 100%;
}
.pg-admissionn-right-header-text{
    margin-right: 20%;
}
.downlaod-brochure-pg-btn{
    margin-right: 40px;
}
.need-help-pg-btn{
    margin-left: 40px;
}
.header-logo{
    max-width: 80px;
    width: 80px;
    height: 81.52px;
}
.term-details-outer{
    background-color: #E8F7FD;
    padding: 15px 20px;
    border-radius: 0px 0px 15px 15px;
}
.term-info-outer{
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 0px 0px 15px 15px;
}
.term-details-inner{
    padding: 10px 0px;
}
.ct-term-details-inner{
    padding: 3px 0px;
}
.term-details-border-b{
     border-bottom: 2px solid #CFEAF6;
}
.info-header-outer{
    border-radius: 10px !important;
    background-color: #003368;
}
.box-sticky{
    position: sticky;
    top: 0;
    z-index: 99;
}
.banner-regulatory-image{
    display: flex;
    flex-direction: row;
    overflow: auto;
}
.tree-card-inqure-mb{
    margin-top: 30px;
    margin-bottom: 10px;
}
.upcmg-head{
    background: #003368;
    border-radius: 10px;
    color: #fff;
    min-height: 113px;
}
.upcmg-head-title{
    display: flex;
    padding: 20px;
    font-weight: bold;
    font-size: large;
    flex-direction: column;
}
.upcmg-table-row{
    border-radius: 10px;
    color: #003368;
    padding: 20px;
    min-height: 113px;
}
.v-section{
    background-color: rgb(83, 83, 83, 0.28);
    border-radius: 3px;
    height: 100px;
    width: 1px;
    margin-right: 20px;
}
.apply-box{
    display: flex;
    align-items: center;
    height: 113px;
    margin-left: 10px;
}
.display-f{
    display: flex;
}
.display-f-v{
    display: flex;
    flex-direction: column;
}
.display-f-h{
    display: flex;
    flex-direction: row;
}
.apply-box-content{
    align-items: baseline;
    margin-bottom: 20px;
    color: #003368;
    font-size: 15px;
}
.mt-3px{
    margin-top: 3px;
}
.table-city-head::before{
    content: "";
    right: 0px;
    top: 0;
    background: url(../images/location-head.png) no-repeat;
    background-size: auto;
    background-size: auto;
    background-size: auto;
    z-index: 99999;
    top: 1px;
    z-index: 999999;
    position: absolute;
    height: 66px;
    width: 53px;
    background-size: cover;
    opacity: 0.2;
}
.header-text{
    color: #003368;
}
.apply-tet-web{
    color: #003368;
}
@media only screen and (max-width: 1465px){
    .fn-46{
        font-size: 36px;
    }
}
.upcmg-head-sub-title{
    display: flex;
    align-items: flex-start !important;
    font-weight: bold;
    font-size: small;
}
.ai-c{
    align-items: center;
}
.thank_you_sub_title {
    color: #013368;
    font-size: 38px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    text-align: center;
    line-height: 40px;
}
.full_shadow_cover-p-ty {
    padding: 0px 120px 80px 120px ;
}
.bottom-number{
    margin-bottom: 50px;
    text-align: center;
    color: #013368;
}
.multi-batch-box{
    justify-content: center;
    font-size: 15px;
}
.blue-gradient-bg{
    background: rgb(145,216,243) !important;
    background: linear-gradient(180deg, rgba(145,216,243,1) 0%, rgba(0,51,104,1) 100%) !important;
}
.blue-bg {
    background: #1C4587 !important;
}
.blue-btn-w{
    max-width: 400px !important;
}
.max-width{
    width: 101%;
}
.font-bold{
    font-weight: bold !important;
}
.font-normal{
    font-weight: normal !important;
}
.font-italic{
    font-style: italic !important;
}
.pg-cert-over-view{
    width: 100%;
    background-color: #D6F0FB !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 20px 40px;
}
.dotted-blue{
    border-right: 1px dotted #003368;
}
.max-width-0{
    max-width: none !important;
}
.pg-cert-over-view-title{
    color: #003368;
    font-weight: bolder;
    font-size: 26px;
}
.pg-cert-over-view-description{
   display: flex;
   justify-content: flex-start;
   align-items: center;
   padding-left: 10%;
}
.display-right{
    display: flex;
    justify-content: flex-end;
}
.box-cta-width{
    width: 85%;
}
.term-main-details-border-b{
    border-bottom: 1px solid #B2CFDC;
}
.preferred-date-time::placeholder{
    font-size: 14px;
}
.pg-table-width{
    width: 65%;
}
/* Home Page, header & Footer Section height */
.owlsliderimage-height {
    height: 150px !important;
}
.industryreport_section {
    height: 520px !important;
}
.industryreport_blogflag_section {
    height: 1003px !important;
}
.TestimonialStudent_section {
    height: 613px !important;
}
.testmonial_owl-slider {
    height: 470px !important;
}
.StudentCard_review-name {
    min-height: 60px !important;
}
.StudentCard_review-designtion {
    min-height: 31px !important;
}
.learningmodes-section{
    height: 563px !important;
}
.learnigmode_img{
    height: 228px !important;
}
.slider_halfsection{
    height: 135px !important;
}
.slider_badge-img{
    width: 100% !important;
}
.courselist-link{
    height: 76.8px !important;
}
.why-li_padding{
    padding: 20px 0px;
}
.footer-logo{
    width: 100% !important;
}
.nav-collapse_height{
    height: 401.8px !important;
}
.navbar_height {
    min-height: fit-content !important;
}
.nav2_float{
    float: left !important;
}
.pg-placement-bold {
    color: #013368;
    font-weight: bold;
}
@media screen and (min-width: 280px) {
    .industryreport_section {
        height: 490px !important;
    }
    .owlsliderimage-height {
        height: 180px !important;
    }
    .learningmodes-section {
        height: 543px !important;
    }
    .learnigmode_img {
        height: 273px !important;
    }
    .slider_halfsection{
        height: 101px !important;
    }
    .courselist-link{
        height: 59.2px !important;
    }
}
@media screen and (min-width: 361px) {
    .why-li_padding{
        padding: 20px 5px;
    }
}
@media screen and (min-width: 767px) {
    .testmonial_owl-slider {
        height: 385px !important;
    }
}
@media screen  and (min-width: 1024px){
    .industryreport_section{
        height: 357px !important;
    }
    .owlsliderimage-height {
        height: 156px !important;
    }
    .industryreport_blogflag_section{
        height: 731px !important;
    }
    .TestimonialStudent_section{
        height: 695px !important;
    }
    .learningmodes-section{
        height: 353px !important;
    }
    .learnigmode_img {
        height: 256px !important;
    }
    .slider_halfsection{
        height: 160px !important;
    }
    .slider_badge-img{
        width: 58.94% !important;
    }
    .footer-logo{
        width: 25% !important;
        height: auto;
    }
    .nav-collapse_height {
        height: 84.2px !important;
    }
    .navbar_height {
        height: 66.2px !important;
    }
    .nav2_float {
        float: right !important;
    }
}
@media screen and (min-width: 767px) and (max-width: 1439px) {
    .StudentCard_review-name {
        min-height: 80px !important;
    }
}
@media screen and (min-width: 767px) and (max-width: 1023px) {
    .industryreport_section {
        height: 351px !important;
    }
    .industryreport_blogflag_section{
        height: 725px !important;
    }
    .owlsliderimage-height {
        height: 150px !important;
    }
    .TestimonialStudent_section{
        height: 705px !important;
    }
    .StudentCard_review-designtion{
        min-height: 46px !important;
    }
    .learningmodes-section {
        height: 353px !important;
    }
    .learnigmode_img {
        height: 248px !important;
    }
    .slider_halfsection{
        height: 157.6px !important;
    }
    .footer-logo{
        width: 65% !important;
    }
    .nav-collapse_height {
        height: 441.8px !important;
    }
    .navbar_height {
        min-height: fit-content !important;
    }
}
.custom-theme-btn:hover, .custom-theme-btn:focus, .custom-theme-btn:active, a.custom-theme-btn {
    color: #ffffff;
}
.custom-span-height span {
    height: 50px !important;
    padding: 20px;
}

.icon-sp-custom{
    background-color: #003368;
    background-size: cover;
    margin-left: 10px;
}
.text-sp-custom{
    background-color: #f8d94b;
    background-size: cover;
    color: #003368;
    font-weight: 700;
}
.box-shadow-custom_theme{
    box-shadow: rgba(67, 71, 85, 0.27) -2px 0px 0.25em, rgba(90, 125,
                188, 0.05) 0px 0.25em 1em ;
}
.custom_theme-heading{
    color: #003368;
    font-size: xxx-large !important;
    padding-bottom: 40px;
}
.ct-bg-yello{
    background-color: #ffea77;
}
.ct-batch-align-section{
    margin: 30px 50px 12px;
    text-align: center;
}
.ct-upcoming_batches{
    margin-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
}
.ct-bg-yello h4{
    color : #003368;
    font-weight: 800;
}
.ct-bg-bluecl{
    background-color: #003368;
}
.ct-blue{
    color: #003368;
}
.ct-bg-white{
        background-color: #ffffff;
}
.ct-font-blue-small{
    font-size: 20px;
    font-weight: 700;
    color: #003368
}
.ct-font-blue{
    font-size: 20px;
    font-weight: 800;
    color: #003368;
}
.ct-font-black{
    font-size: 20px;
    font-weight: 800;
}
.ct-learning-modes-container{
    padding: 20px 0px 20px 10px;
}
.ct-position-absolute{
    position: absolute  !important;
}
.ct-bg-blue{
    background-color: #71eeff;
}
.ct-price-width{
    min-width: 225px;
    max-width: 225px;
    padding: 16px 0;
    bottom: -5px;
}
.ct-circle {
    width: 225px;
    height: 225px;
    background-color: #ffffff;
    border-radius: 50%;
    margin: 5px auto 25px;
}
.ct-border-rd-50{
    border-radius: 100% !important;
}
.ct-background {
    background-image: url(../../assets/images/customui/background_vector.svg);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    height: 650px;
}
.ct-main-image {
    width: 120%;
    height: 800px;
    margin-top: -100px;
    margin-left: -180px;
}
.ct-bg-shadow{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.ct-width-max-min-300{
    max-width: 350px;
    min-width: 350px;
}
.ct-pl-10{
    padding-left: 5px;
}
.ct-pr-10 {
    padding-right: 8px !important;
}
.ct-h-450{
    height: 450px;
}
.ct-vertical-align{
    margin: auto
}
.ct-m-auto{
    margin: auto;
}
.ct-w-275 {
    width: 275px;
}
.ct-help-title h4{
    font-size: 26px;
    font-weight: 900;
    color: #003368
}
.ct-help-title span.ct-span-light{
    color: #7bc5fb;
}
.ct-font-blue-help{
        font-size: 22px;
            font-weight: 800;
            color: #003368;
}
.ct-bg-green{
    background-color: #9ffbd4;
}
.ct-bg-yello-dark{
    background-color: #f8d94b;
}
.ct-bg-yellow{
    background-color: #ffea77;
}
.ct-bg-yellow-maxdark{
    background-color: #ffbe00;
}
.ct-w-full{
    width: 100%;
}
.ct-form-input{
    background: transparent !important;
    border-bottom: 3px solid #9ffbd4 !important;
    padding: 12px 30px 12px 20px !important;
}
.ct-form-select {
    background: transparent !important;
    border-bottom: 3px solid #9ffbd4 !important;
    padding: 0px !important;
    padding-left: 5px !important;

}
.ct-form label.ct-custom{
    font-weight: 800;
    position: absolute;
    top: 5px;
}
.ct-form ::placeholder{
    font-weight: 600 !important;
    color: #000 !important;
}
element.style {
    background: transparent;
}
.ct-wt-hand{
    cursor: pointer;
}
.ct-select-code div.css-1geaky1-control, .ct-select-code div.css-ugc510-control, .ct-select div.css-ugc510-control{
    background: transparent !important;
    padding: 0px !important;
    padding-top: 6px !important;
    font-weight: 600;
    color: #000 !important;
}
.ct-select div.css-1geaky1-control{
    background: transparent !important;
        padding: 0px !important;
        padding-top: 6px !important;
        font-weight: 600;
}
.ct-select div.css-1jqq78o-placeholder{
    color: #000 !important;
}
.ct-select-date input.form-control.ct-form-input.mb-0.react-datepicker-ignore-onclickoutside, .ct-select-date input.form-control.ct-form-input.mb-0 {
    font-weight: 700 !important;
    color: #000 !important;
}
button#ct-btn-square {
    letter-spacing: 3.5px;
    background: #9cfbd4;
    padding: 20px !important;
    margin-top: 30px !important;
    font-weight: 500;
}
img#ct-calendericon-img{
    position: absolute;
    bottom: 8px;
    right: 10px;
    cursor: pointer;
}
.ct-main-title{
        font-size: 56px !important;
        line-height: 60px !important;
}
.ct-checkbox{
    height: 18px !important;
    width: 18px !important;
}
.ct-checkbox-description{
    font-size: 18px;
}
.ct-fs-18{
    font-size: 18px;
}
.ct-fs-16 {
    font-size: 16px;
}
.ct-fs-26{
    font-size: 26px;
}
h2.ct-ft-24 {
    font-size: 22px !important;
}
h4.ct-font-40{
    font-size: 40px !important;
}
.ct-bg-yuftbush {
    background-color: #f9cebd !important;
}
h4.ct-font-40-lg{
    font-size: 40px !important;
}
.ct-bg-image-gradient {
    background-image: url(../../assets/images/customui/gradient_background.svg);
    background-repeat: no-repeat;
    background-position: top top;
    background-size: cover;
}
.ct-w-btn{
    max-width: 300px !important;
    min-width: 300px !important;
    font-size: 18px !important;
}
.ct-media-btn-Brochure{
    max-width: 235px;
    display: block;
}
.ct-ft-call{
    max-width: 235px;
}
.ct-counting-sec {
    margin: 0px;
}

.ctc-btn-container{
    max-width: auto;
    cursor: pointer;
    min-width: 450px;
}
.pg-logo-width{
    width: 240px !important;
}
img.pg-logo-width{
    margin-bottom: 5% !important;
}
.b-red{
    border: 10px solid red;
}
.lg-ctn-active{
    width: 50%;
    background-color: #fec439;
    z-index: 99;
    font-size: 22px;
    font-weight: 700;
    padding: 10px 28px;
    border-radius:36px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.lg-ctn-active:hover{
    color: darkslategrey;
}
a.ct-padding-left-right{
    padding-left: 50px;
    padding-right: 50px;
}
.lg-margin{
    margin-top: 90px;
}
.lg-margin-small{
    margin-top: 30px;
    margin-bottom: 20px;
}
.ct-lg-textcenter{
    text-align: left;
}
.ct-lg-position-relative{
    margin-bottom: 0;
    bottom: 30px;
    left: -85px;
    right: unset;
}
.lg-custom-watch-part{
    background-size: 100% 100%;
    background-position: right top;
    position: relative;
    min-height: 450px;
}
.ct-lg-btn{
    margin: 0px 0 15px;
}
.ct-lg-btn-mt{
    margin: 5px 0 15px;
}
.ct-lg-downloadBTN{
    max-width: 235px;
    display: block;
}
.ct-lg-btn-download{
    max-width: 235px;
    height: 44.8px;
    cursor: 'pointer',
}
.ct-lg-btn-fontsize a{
    font-size: 18px;
}
.ct-lg-btn-fontsize15 a{
    font-size: 15px;
}
.ct-mb-rem{
    margin-bottom: 1rem;
}
.ct-lg-font-size-26{
    font-size: 26px !important;
}
a.ct-lg-linkUpdate{
    color: #25B2E7 ;
    transition: all 0.3s ease 0s ;
    font-style: normal;
}
a.ct-lg-linkUpdate-italic{
    color: #25B2E7 ;
    transition: all 0.3s ease 0s ;
    font-style: italic;
}
span.ct-lg-linkUpdate-italic{
    font-style: italic;
}
a.ct-lg-linkUpdate:hover{
    color: #0006ff !important;
}
a.ct-lg-link-blockquote{
    color: #25B2E7;
    transition: all 0.3s ease 0s ;
}
a.ct-lg-link-blockquote:hover{
    color: #0006ff !important;
}
ol.ct-pg-ol{
 padding-left: 20px;
}
.ct-pg-ol li{
    list-style:decimal;
}
.ct-ml-60{
    margin-left: 60px;
}
.ct-pg-font-h5{
    font-size: 1.5rem;
}
blockquote:before {
    content: "\201c";
    float: left;
    margin-top: 10px;
    font-family: Georgia, Arial, sans-serif;
    font-style: normal;
    font-size: 60px;
    line-height: .41;
    color: #25B2E7;
}
.ct-pg-blockquote-color{
    color: #989898;
}
.ct-lg-normal-text{
    text-transform:none;
}
.pg-lg-center{
    text-align: center;
}
.pg-lg-ul-r-20{
    margin-left: 20px;
}
ol.ct-ol-list-style-decimal li {
    list-style: decimal;
}
.ct-footer-logo-mb{
    margin-bottom: -40px;
}
.ct-footer-logo-mt{
    margin-top: 80px;
    justify-content: center;
}
.ct-footer-forth-heading{
    color: #013368;
    font-family: Arial, sans-serif;
    font-weight: bold;
    margin-bottom: 15px;
}
.ct-fifth-heading-mb{
    margin-bottom: 15px;
}
.ct-footer-center-align{
    margin-top: 38px;
    width: fit-content;
}
.ct-ft-link{
    height: 32px;
}
.ct-footer-logo-ml{
    margin-left: -60px;
}
.ct-w-75{
    width: 85%;
}
.logoHeading-sm{
    margin-top: 20px;
    margin-bottom: 20px;
    display: none;
}
.ct-lg-logo-width{
    width: 240px;
}
.lg-ct-lg-display{
    display: block;
}
.ct-lg-tools-sec{
    bottom: 8px;
    left:245px;
}
.ct-lg-absolute-top{
    top: -8px;
    left: 245px;
}
img.ct-pg-width-img-Nasscom{
    width: auto;
    height: 310px;
}
img.ct-pg-width-img{
    width: auto;
    height: 310px;
}
.lg-last-img img{
    margin-top: 120px;
}
.ct-course-tools-sec{
    bottom: -15px;
}
h2.ct-course-learningModeData-height{
    min-height: 60px;
}
h2.ct-height-changes{
    min-height: 52px;
}
.ct-right-padding{
    margin-right: 56px;
}
.ct-mt-8{
    margin-top: 8px;
}
.ct-last-img img {
    margin-top: 48px;
    max-width: 460px;
}
.ct-position-relative{
    position: relative;
}
.ct-iitLogo-position{
    top: 0;
    left: 50%;
}
.ct-w-100{
    width: 85%;
}
.ct-w-90-md-100{
    width: 85%;
}
.ct-w-50{
    width: 50%;
}
.ct-tools-sec-pg-analytics {
    max-width: 460px;
    min-width: 460px;
}
.ct-slider_badge-img{
    width: 60% !important;
}
.counrse-contn-1{
    color: #2f2f2f;
    font-size: 16px;
    line-height: 21px;
}
p.ct-font-style-normal a{
    font-style:normal;
}
.counrse-contn-3{
    font-size: 20px;
}
.ct-ml-mr-15{
    margin-left: 15px;
    margin-right: 15px;
}
.ct-capitalize, h5.ct-capitalize, h4.ct-capitalize{
    text-transform: capitalize;
}
.ct-lg-w-75{
    width: 90% !important;
}
.ct-watch-banner-l1{
    width: 30%;
}
img.ct-main-logo-float-right{
    float: right;
}
.ct-watch-banner-l1-pg-diploma{
    width: 100%;
}
.ct-color-gray{
    color:#8e8e8e;
}
.ct-pl-pr-sm-15{
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.ct-table-text-white{
    font-size: 11px;
    color: white;
}
div.ct-font-size-18 {
    font-size: 16px;
    color: #8e8e8e;
    margin-bottom: 20px;
}
 .ct-fnt-title {
    font-size: 24px;
    color: #25B2E7;
    margin-top: 10px;
}
.ct-fnt-title-ai-static {
    font-size: 24px;
    color: #25B2E7;
    margin-top: 4px;
}
ol.ct-number-ol-date li{
    list-style: decimal;
    list-style-type: decimal;
    color: #013368;
    line-height: 26px;
    font-weight: 700;
    font-size: 20px;
    margin-left: 25px;
}
ol.ct-number-ol-date{
    list-style: decimal !important;
    list-style-type: decimal !important;
}
.ct-ft-weight-small{
    font-weight: 500;
}
.ct-bg-light{
    background-color: #25B2E7;
}
.ct-bg-dark{
    background-color: #013368;
}
.ct-color-yellow{
    color: #fb0;
}
.ct-display-block-big-screen{
    display: block;
}
.ct-display-block-small-screen{
    display: none;
}
.ct-margin-job-roles{
    margin: 0px;
    margin-bottom: 15px;
}
a.ct-cta-big-yellow{
    font-size: 24px;
    max-width: fit-content;
    padding: 12px 28px;
}
ol.ct-color-blacklist li{
    color: #000;
}
ol.Pay-After-Placement-ol-li li{
    list-style-type: decimal;
    margin-left: 30px;
}
ol.Pay-After-Placement-ol-li-type-spell li{
    list-style-type: lower-alpha;
}
ul.Pay-After-Placement-ul-li li{
    list-style-type: disc;
    margin-left: 30px;
}
.ct-color-light-blue{
    color: #25B2E7;
}
.ct-note-message-table{
    color: #EB1818;
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: 600;
}
.ct-IndustryExperts{
    line-height: 45px;
    padding: 0px 0px 0px 30px;
    color: '#013368';
}
.ct-mt-35{
    margin-top: 35px;
}
.ct-display-flex-career-card{
    display: flex;
    flex-direction: row;
}
img.ct-image-careerList{
    width: 65px;
    height: 65px;
}
.ct-hr {
    border-color: #09f;
    border-width: 2px;
  }
.upcmg-btcblk-li-style{
    min-width: 150px;
}
img.img-vert-move1{
     width: '100%'; 
     max-width: '365px';
}
.ct-course-overView-outer-top {
    margin-top: 120px;
}
.fee-managment-dtltbl{
    min-height: auto;
}
h2.ct-fee-hed-h-auto {
    min-height: 38px;
}
.ct-form-check {
    display: inline-flex;
    flex-direction: row;
    gap: 8px;
    align-items: baseline;
}
.ct-form-check-input{
    width: 8% !important;
    justify-items: flex-start;
}
.ct-form-check-label{
    width: 92% !important;
    text-align: left !important;
    color: #2f2f2f;
    font-size: 14px;
}
p.ct-font-size-12{
    font-size: 14px;
}
.custom-table {
    width: 100%;
    max-width: 720px;
    margin: 0 20px auto;
    border: 2px solid #013368;
    border-collapse: collapse;
  }
  .custom-table th, .custom-table td {
    padding: 10px;
    border: 1px solid #013368;
    text-align: left;
    font-size: 16px;
  }
  .custom-table th {
    background-color: #013368;
    color: white;
  }
  .custom-table td {
    color: #013368;
  }
  .price {
    font-weight: bold;
    color: #27b2e7;
  }
  .ct-CourseCurriculumDetails-table-th{
    color: white;
    background-color: #0e3068;
  }
  .ct-CourseCurriculumDetails-table-td-width-25{
    border: 1pt solid rgb(0, 0, 0); 
    width: 25%; 
    vertical-align: middle;
    padding:5px 10px;
  }
  .ct-CourseCurriculumDetails-table-td-width-75{
    border: 1pt solid rgb(0, 0, 0); 
    width: 75%; 
    vertical-align: middle;
    padding:5px 10px;
  }
  .ct-CourseCurriculumDetails-table-td-width-20{
    border: 1pt solid rgb(0, 0, 0); 
    width: 20%; 
    vertical-align: middle;
    padding:5px 10px;
  }
  .ct-CourseCurriculumDetails-table-td-width-55{
    border: 1pt solid rgb(0, 0, 0); 
    width: 55%; 
    vertical-align: middle;
    padding:5px 10px;
  }
  .ct-color-dark-blue{
    color: #013368;
  }
  .ct-fs-20{
    font-size:20px;
  }
  h3.ct-heading-fs-color{
    font-size:20px; 
    color: #013368; 
    text-align: left;
  }
    span.color-dark-blue{
      color: #013368;
    }
    .tablecover{
        margin: auto;
        width: 720px;
    }
    .ct-blue-btn-w{
        max-width: 370px !important;
        min-width: 370px !important;
    }
    .ct-border-cover{
        border-bottom: 1px solid #00000020;

    }
    .ct-p-text-gen-ai{
        color:#013368; 
        font-size: 1rem;
    }
    span.ct-fs-20-fees-details{
        font-size: 20px;
    }
    .ct-italic{
        font-style: italic !important;
    }
    img.table-logo {
        width: 100%;
        max-width: 125px;
        height: auto;
      }
    .common-table{
        border-collapse: collapse;
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        border: 1px solid rgb(0, 0, 0);
    }
    div.cover-common-table{ 
        display: flex; 
        justify-content: center; 
        padding: 8px; 
    }
    .common-table-cell {
        border: 1px solid rgb(0, 0, 0);
        padding: 8px;
        vertical-align: middle;
        text-align: center;
        height: max-content;
    }
    .nested-table-cell {
        padding: 0;
    }
    .nested-table {
    border-collapse: collapse;
    width: 100%;
    }
@media screen and (max-width:1400px) {
    img.ct-pg-width-img-Nasscom{
        width: auto;
        height: 260px;
    }
    img.ct-pg-width-img{
        width: auto;
        height: 260px;
    }
    .ct-lg-tools-sec{
        bottom: 8px;
    }
}
.ct-course-overView-outer-top{
    margin-top: 120px;
}
@media screen and (min-width:1300px) {
    .ct-main-image {
        width: 115%;
        height: 600px;
        margin-top: 10px;
        margin-left: -180px;
    }
    
}
@media screen and (max-width:1380px) {

}
@media screen and (max-width:1300px) {
    img.ct-pg-width-img-Nasscom{
            height: 240px;
        }
    img.ct-pg-width-img{
        height: 240px;
    }
    .ct-iitLogo-position{
        top: 0;
        left: 50%;
    }
    .ct-w-90-md-100{
        width: 92%;
    }
    div.ct-font-size-18 {
        font-size: 14px;
    }
}
@media screen and (max-width: 1281px) {
    .ct-lg-w-75{
        width: 80% !important;
    }
    .ct-iitLogo-position{
        top: 0;
        left: 50%;
    }
    .ct-slider_badge-img{
        width: 80% !important;
    }
}
@media screen and (max-width: 1200px) {
    .ct-lg-tools-sec{
        bottom: 8px;
    }
    .ct-font-blue, .ct-font-blue-help, .ct-font-blue-small  {
        font-size: 18px;
    }
    .ct-font-black {
        font-size: 18px;
    }
    .ct-footer-logo-ml{
        margin-left: -30px;
    }
    img.ct-pg-width-img-Nasscom{
        height: 210px;
    }
    img.ct-pg-width-img{
        height: 210px;
    }
    .ct-last-img img {
        margin-top: 30px;
        max-width: 400px;
    }
    .ct-lg-w-75{
        width: 75% !important;
    }
    .ct-iitLogo-position{
        top: 0;
        left: 66%;
    }
    .ct-w-90-md-100{
        width: 100%;
    }
    .ct-watch-banner-l1{
        width: 25%;
    }
    .ct-term-title-pg-diploma-font-size{
        font-size: 14px;
    }
    .ct-career-info-below-text{
        font-size: 20px;
    }
}
@media screen and (min-width: 1023px) and (max-width:1800px){
    .ct-special-width-50{
        width: 50%;
        margin-top: 0px !important;
    }
}
@media screen and (max-width: 1023px){
    .ct-media-btn-Brochure {
        max-width: 100%;
    }
    .ct-ft-call {
        max-width: 100%;
    }
    .ct-lg-downloadBTN{
        max-width: 100% !important;
        display: inline-block;
    }
    .ct-lg-btn-download{
        max-width: 100% !important;
    }
    .ct-footer-logo-mb{
        margin-bottom: 10px;
    }
    .footer-logo{
        width: 30% !important;
        height: auto;
    }
    .ct-footer-logo-ml{
        margin-left: -10px;
    }
    .ct-last-img img {
        margin-top: 30px;
        max-width: 380px;
    }
    .ct-slider_badge-img {
        width: 92% !important;
    }
    .ct-iitLogo-position{
        top: 0;
        left: 75%;
    }

}
@media screen and (min-width: 767px) and (max-width:1100px){
    .ct-batch-align-section {
        margin: 0;
    }
    .lg-width-custom{
        width: 50% !important;
    }
}
@media screen and (min-width: 767px) and (max-width:1100px){
    /* for course page Banner Small Screen & Tablet View */
    .ct-watch-banner li{
        float: none !important;
    }
    .ct-watch-banner{
        margin-left: 2.5%;
        margin-right: 2.5%;
    }
    .ct-watch-banner-l1{
        width: 90%;
        min-width: fit-content;
    }
    a.ct-free-demo-BTN{
        margin-top: 30px;
        margin-left: 80px;
    }
    .ct-course-tools-sec{
        left: 0;
        right: auto;
        margin-left: 2.5%;
        margin-top: 2.5%;
        position: relative;
    }
    img.vert-move.ls-is-cached.lazyloaded.ct-sm-w-90{
        height: 360px !important;
    }
    li.last-img {
        float: right !important;
    }
    .free_demo_main_icon {
        right: auto;
        left: auto;
    }

}
@media screen and (min-width: 767px) and (max-width:820px){
    /* for course page Banner Small Screen & Tablet View */
    a.ct-free-demo-BTN{
        margin-left: 34px;
    }
}
@media screen and (max-width: 992px) {
    .ct-price-width {
        bottom: -5px;
    }

    .ct-justify-around{
        justify-content: space-around !important;
    }

    .ct-main-image {
        width: 100%;
        height: 400px;
        margin-top: -110px;
        margin-left: 0 !important;
    }
    .lg-ctn-active{
        font-size: 22px;
        padding: 10px 20px;
    }
    a.ct-padding-left-right{
        padding: 10px 40px;
    }
    .ct-career-info-below-text{
        font-size: 24px;
    }
    .tablecover{
        margin: 10px 15px;
        width: auto;
    }
    .ct-blue-btn-w{
        max-width: 350px !important;
        min-width: 350px !important;
    }
    div.cover-common-table{ 
        padding: 2px; 
    }
}
@media screen and (max-width: 850px) {
        img#ct-calendericon-img {
            height: 20px !important;
            width: 20px !important;
            bottom: 18px;
        }
        .ct-sm-modal-body {
            padding: 0;
        }
        .ct-lg-position-relative{
            position: relative !important;
            top: 0 !important;
            bottom: 0 !important;
        }
        .ct-footer-center-align{
            margin: none;
            width: 100%;
        }
        .ct-footer-logo-ml{
            margin-left: unset;
        }
        .ct-lg-tools-sec{
            bottom: 0px;
        }
        .ct-lg-absolute-top{
            left: 0;
        }
        .custom-table {
            max-width: 600px;
            margin: 20px auto;
        }
}
@media only screen and (min-width: 821px) and (max-width: 913px) {
    .ct-tools-sec-pg-diploma{
        margin-top: 20px;
        max-width: none !important;
        left: unset;
    }
}
@media only screen and (min-width: 765px) and (max-width: 820px) {
    .ct-tools-sec-pg-analytics{
        margin: 0px;
    }
    .ct-tools-sec-pg-diploma{
        margin-top: 20px;
        max-width: none !important;
    }
}
@media screen and (max-width: 767px){
    .ct-term-details-outer,.ct-terms-outer{
        padding: 8px 12px !important;
    }
    .ct-mt-35{
        margin-top: 45px;
    }
    .ct-sm-block{
        display: block;
    }
    a.ct-cta-big-yellow{
        font-size: 18px;
        max-width: fit-content;
        padding: 10px 25px;
    }
    .ct-career-info-below-text{
        font-size: 22px;
    }
    .ct-display-block-big-screen{
        display: none;
    }
    .ct-display-block-small-screen{
        display: block;
    }
    .ct-term-title-pg-diploma-font-size{
        line-height: 24px !important;
    }
    .ct-pl-pr-sm-15{
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .ct-ml-40{
        margin-left: 24px !important;
    }
    .ct-sm-text-center{
        text-align: center;
    }
    .ct-footer-logo-mt{
        margin-top: 0px;
    }
    .footer-logo{
        width: 90% !important;
        height: auto;
    }
    .ct-sm-title-mt{
        margin-top: 15px;
    }
    h2.custom_theme-heading {
            font-size: 28px !important;
            margin-top: 40px;
        }
    .ct-mt-sm-60{
        margin-top: 60px;
    }
    .ct-mb-sm-60{
        margin-bottom: 60px;
    }
    .ct-h-sm-250{
        height: 250px;
    }
    .ct-w-sm-80{
        width: 80%;
    }
    .ct-pr-10{
        padding-right: 0 !important;
    }
    .ct-sm-pr-pl-15 {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .ct-main-title {
        font-size: 30px !important;
        line-height: 38px !important;
    }
    .ct-checkbox {
        height: 16px !important;
        width: 16px !important;
    }

    .ct-checkbox-description {
        font-size: 16px;
    }
    .ct-batch-align-section{
        margin: 0;
    }
    h2.ct-ft-24 {
        font-size: 18px !important;
    }
    h4.ct-font-40 {
        font-size: 32px !important;
    }
    .ct-media-btn-Brochure {
        display: inline-block;
    }
    .ct-w-btn {
        max-width: 275px !important;
        min-width: 275px !important;
        font-size: 16px !important;
    }
    .pg-table-width{
        width: 90%;
    }
    .lg-margin{
        margin-top: 30px;
    }
    .lg-margin-small{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .lg-ctn-active{
        font-size: 22px;
        padding: 10px 20px;
    }
    a.ct-padding-left-right{
        padding: 10px 40px;
    }
    .pg-logo-width{
        width: 230px !important;
    }
    .header-lg-special {
        min-height: 1050px;
    }
    .ct-lg-textcenter{
        text-align: center !important;
    }
    .lg-custom-watch-part{
        background-image: linear-gradient(120deg, rgba(2, 19, 43, 1) 0%, rgba(99, 182, 211, 1) 50%) !important;
    }
    .ct-ml-60{
        margin-left: 30px;
    }
    blockquote:before {
        font-size: 40px;
    }
    .ct-pg-font-h5{
        font-size: 18px;
    }
    h2.ct-pg-mobile{
        font-weight: 700;
        color: #870000;
    }
    h2.ct-pg-fw-normal{
        font-weight: 500;
    }
    .ct-lg-position-relative{
        left: 0;
    }
    .ct-w-75{
        width: 100%;
    }
    .logoHeading-sm{
        display: block;
    }
    .lg-ct-lg-display{
        display: none;
    }
    .ct-lg-tools-sec{
        left: 0;
        right: 0;
    }
    img.ct-pg-width-img-Nasscom , img.ct-pg-width-img{
        width: 100% !important;
        height: auto;
    }
    .ct-course-overView-outer-top{
        margin-top: 270px;
    }
    .ct-mb-3{
        margin-bottom: 18px;
    }
    .ct-mt-15{
        margin-top: 18px;
    }
    .ct-tools-sec-pg-diploma{
        bottom: -45px;
    }
    h5.ct-mb-0{
        margin-bottom: 0px;
    }
    .lg-last-img img{
        margin-top: 00px;
    }
    .course-overView-outer-top{
        margin-top: 285px;
    }
    .ct-course-tools-sec{
        bottom: 0;
    }
    .ct-right-padding{
        margin-right: auto;
    }
    .ct-sm-w-90{
        max-width: 90% !important;
    }
    .ct-last-img img {
        margin-top: 0px;
        width: 100%;
        margin-bottom: 93%;
    }
    .ct-last-img{
        margin-top: 30px !important;
        margin-bottom: 45px !important;

    }
    .ct-lg-w-75{
        width: 100% !important;
    }
    .ct-sm-position-relative{
        position: relative !important;
        text-align: center;
        left: auto;
        top: auto;
    }
    .ct-sm-dimension{
        max-width: 95%;
        min-width: 95%;
    }

    .ct-tools-sec-pg-analytics {
        max-width: 460px;
        min-width: auto;
    }
    .ct-sm-mb-4{
        margin-bottom: 20px;
    }
    .ct-sm-mt-10{
        margin-top: 10px;
    }
    .ct-ml-mr-15{
        margin-left: 0px;
        margin-right: 0px;
    }
    .ct-w-100{
        width: 100%;
    }
    .ct-text-left{
        text-align: left !important;
    }
    .ct-min-width-100{
        min-width: 100%;
    }
    a.ct-font-link-16{
        font-size: 16px !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        padding-left: 0 !important;
    }
    .ct-cours-ovr-ul li{
        line-height: 30px;
        display: block;
    }
    ol.ct-number-ol-date li{
        list-style: decimal;
        list-style-type: decimal;
        margin-left: 20px;
    }
    ol.ct-number-ol-date{
        list-style: decimal !important;
        list-style-type: decimal !important;
    }
    .ct-display-flex-sm{
        display: flex;
        flex-direction: row;
    }
    .countpay li {
        padding-right: 70px;
    }
    .ct-sm-jc-space-around{
        justify-content: space-around !important;
    }
}
@media screen and (max-width: 567px) {
    .ct-mt-10{
        margin-top: 10px !important;
    }
    .ct-background {
        height: 520px;
    }
    .ct-sm-center{
        text-align: center;
    }
    .ct-main-image {
        margin-top: -80px;
    }
    img#ct-calendericon-img {
        height: 20px !important;
        width: 20px !important;
        bottom: 18px;
    }
    .ct-sm-modal-body{
        padding: 0;
    }
    h2.ct-w-100 {
        max-width: 100%;
    }
    .ct-fs-18 {
        font-size: 18px;
    }
    .ct-fs-16 {
        font-size: 18px;

    }
    .ct-letter-space-17{
        letter-spacing: 1.6px !important;
    }
    .ct-fs-26 {
        font-size: 22px;
    }
    .ct-price-width{
    min-width: 190px;
    max-width: 190px;
    padding: 16px 0;
    bottom: -5px;
}
.ct-circle {
    width: 190px;
    height: 190px;
    border-radius: 50%;
    margin: 5px auto 25px;
}
    .pg-table-width{
        width: 95%;
    }
    .lg-ctn-active{
        font-size: 16px;
        padding: 10px 20px;
    }
    a.ct-padding-left-right{
        padding: 10px 34px;
    }
    h4.ct-font-40-lg{
        font-size: 25px !important;
    }
    .pg-page-font-mobile p, .pg-page-font-mobile span{
        font-size: 15px !important;
    }
    .pg-ct-tools-list-outer {
        bottom: -220px !important;
    }
    h4.ct-font-40-lg , h4.ct-font-40-lg span {
        font-size: 26px !important;
    }
    .ct-lg-font-size-26, h5.ct-lg-font-size-26 span {
        font-size: 26px !important;
    }
    .ct-mb-sm-24{
        margin-bottom: 32px !important;
    }
    .ct-sm-center{
     text-align: center !important;
    }
    .ct-sm-left{
        text-align: left !important;
    }
    .ct-float-none{
        float: none !important;
    }
    .ct-sm-mt-40{
        margin-top: 40px;
    }
    .ct-p-sm-2{
        padding: .5rem !important;
    }
    h2.course-title {
        font-size: 25px !important;
        line-height: 30px !important;
        text-align: center;
    }
    img.ct-last-img-data {
        width: 100%;
        margin-bottom: 100% !important;
    }
    .ct-sm-fontsize{
        font-size: 18px !important;
    }
    .custom-table th, .custom-table td {
        font-size: 16px;
    }
}
@media screen and (max-width: 480px) {
    img.ct-last-img-data{
        max-width: 90% !important;
    }
}
@media screen and (max-width: 450px) {
    .ct-main-image {
            margin-top: -110px;
        }
    .ct-fs-18{
        font-size: 15px;
    }
    .ctc-btn-container{
        min-width: auto;
    }
}
@media screen and (max-width: 380px) {
    input.ct-form-input-calender {
        font-size: 18px !important;
    }
}
@media screen and (max-width: 350px) {
    input.ct-form-input-calender {
        font-size: 16px !important;
    }
    .ct-width-max-min-300 {
        max-width: 300px;
        min-width: 300px;
    }
}

.box-sticky{
    position: sticky;}