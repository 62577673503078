.searchbarCource .showSearchclass {
    display: block;
    transition: margin 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94) 0s;
}

.search-bar-innr {
    outline: none !important;
}

.search-bar-innr input,
.search-bar-innr button {
    outline: none !important;
}
.search-bar-innr input:focus {
    border: 1px solid #78ffd1;
}
.open-arrow {
    position: absolute;
    left: 0;
}
.open-arrow-inpt {
    margin-left: 130px;
    max-width: 500px;
}

.hamburger-menu {
    border: 0;
    margin: 0 auto;
    display: block;
    position: absolute;
    overflow: hidden;
    padding: 0;
    width: 36px;
    height: 36px;
    font-size: 0;
    cursor: pointer;
    z-index: 9999;
    cursor: pointer;
    background: transparent;
}
.open-arrow > a {
    font-size: 50px !important;
    line-height: 100px !important;
    top: -23px !important;
    position: relative !important;
    width: 100px !important;
    height: auto !important;
    color: #013368 !important;
}
.menu-overlay {
    transition: margin 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: url(../../assets/images/popup-bg.png) !important;
    height: 100vh;
    width: 100%;
    background-size: cover !important;
    z-index: 9999;
}

@media only screen and (max-width: 767px) {
    .hamburger-menu-mb {
        margin-top: 60%;
    }
    .menu {
        position: relative;
        min-width: 220px;
        text-align: center;
        top: -100px;
    }
    .menu-list a {
        color: #013368;
        text-decoration: none;
        font-size: 18px;
        display: inline-block;
        margin: 15px 0;
        transition: all 0.5s ease;
    }
    .menu-list {
        display: none;
        position: absolute;
        top: calc(100% + 103px);
        width: 100%;
        text-align: center;
        z-index: 9999;
    }
    .new-mn-mb {
        position: absolute;
        top: 135px;
        width: 100%;
        left: 0px;
        bottom: auto;
        height: 100vh;
    }
    .menu-overlay {
        display: none;
    }
    .top-bg #menu-overlay1 {
        top: -135px;
    }
    .menu-overlay-mb {
        transition: margin 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
        display: none;
        position: absolute;
        top: -100px;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: #78ffd1;
        height: 100vh;
        width: 100%;
        background-size: cover !important;
        z-index: 9999;
        overflow: scroll;
    }
    .term-blk-mb {
        margin: 25px 50px;
    }
    .card-new1 .side1 {
        height: 100%;
        position: relative;
        width: 110%;
        left: -5%;
    }

    .green-ng-mg {
        position: relative;
        color: #013368;
        font-family: Arial, sans-serif;
        font-size: 18px;
        line-height: 25px;
        background: #ffea78;
        padding: 2px 13px;
        border-radius: 20px;
        left: -14px;
        bottom: 9px;
    }

    .open-arrow-mb {
    }
    .open-arrow-mb > a {
        left: 0px;
        font-size: 50px !important;
        line-height: 100px !important;
        top: -44px !important;
        position: relative !important;
        height: auto !important;
        color: #013368 !important;
    }
    .open-arrow-inpt-mb {
        margin-left: 130px;
        max-width: 80% !important;
        max-height: 10% !important;
        float: right;
    }
    .hamburger-menu-mb {
        border: 0;
        margin: 0 auto;
        display: block;
        position: absolute;
        overflow: hidden;
        background-color: white;
        padding: 0;
        height: 36px;
        font-size: 0;
        cursor: pointer;
    }
}
