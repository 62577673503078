@media only screen and (max-width: 767px) {
    .bottombar_fix {
        display: block !important;
        background: #002e6a;
    }
    .bottombar_fix .topbar {
        text-align: center;
        margin: 0 auto;
        font-size: 11px;
        font-weight: bold;
        padding: 14px 16px;
        text-transform: uppercase;
        color: #fff;
    }
    .bottombar_fix .topbar a {
        font-size: 11px;
        font-weight: bold;
        padding: 0;
        display: block;
        text-transform: uppercase;
        color: #fff;
    }
    .bottombar_fix .topbar.courses,
    .bottombar_fix .topbar.brochure {
        width: 50%;
    }
    .bottombar_fix .topbar.offers {
        width: 50%;
        font-weight: 12px;
        background: #00b2eb;
        color: #002e6a;
        border-right: 1px solid #fff;
        border-left: 1px solid #fff;
    }
    .bottombar_fix .topbar a i {
        font-size: 18px;
    }
    .bottombar_fix .topbar.brochure {
        background: #0ee874;
    }
}
