/* .three-blk.blue-blk.outdiv.h-auto:hover > .innerdiv.innerdiv-2 {
    display: block;
    overflow: hidden;
    height: 345px;
    padding: 0px 15px 15px;
    margin: 10px 0px 0px;
    width: 232px;
} */

.animation-box:hover > .animation-inner-box {
    display: block;
    overflow: hidden;
    height: max-height;
    padding: 0px 15px 15px;
    margin: 10px 0px 0px;
    width: 232px;
    transition-timing-function: 300;
    animation-timing-function: ease-in;
    animation-name: drive;
    animation-duration: 0.5s;
}
.animation-box:hover > .course-card-text {
    display: block;
    height: auto;
}
.owl-silder .owl-carousel .owl-nav button {
    animation: shadow-pulse 2s infinite;
    background: #ffea78 !important;
    height: 32px;
    width: 32px;
    border-radius: 50%;
}

@keyframes drive {
    0% {
        width: 0px;
        height: 0px;
        opacity: 0;
        margin: 0px 0px 0px;
    }

    100% {
        width: max-width;
        height: 345px;
        opacity: 1;
        margin: 10px 0px 0px;
    }
}
.animation-box:hover > .animation-inner-box2 {
    display: block;
    overflow: hidden;
    height: max-height;
    padding: 0px 15px 15px;
    margin: 10px 0px 0px;
    width: 232px;
    transition-timing-function: 300;
    animation-timing-function: ease-in;
    animation-name: drive2;
    animation-duration: 0.5s;
}

@keyframes drive2 {
    0% {
        width: 0px;
        height: 0px;
        opacity: 0;
        margin: 0px 0px 0px;
    }

    100% {
        width: max-width;
        height: 80px;
        opacity: 1;
        margin: 10px 0px 0px;
    }
}

/* 23-07-2020 */
.error-msg {
    color: red;
    position: absolute;
    margin-top: 50px;
}

.success-msg {
    color: green;
    font-weight: 600;
    font-size: 20px;
    margin-top: 50px;
}

/* @media only screen and (max-width: 767px) {
  li.business_anl {
    width: 50px !important;
    margin-right: 10px;
  }
} */
