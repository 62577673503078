@media only screen and (max-width: 767px) {
    .topbar_fix {
        display: block !important;
        background: #002e6a;
    }
    .topbar_fix .topbar {
        text-align: center;
        margin: 0 auto;
        font-size: 11px;
        font-weight: bold;
        padding: 12px;
        text-transform: uppercase;
        color: #fff;
    }
    .topbar_fix .topbar a {
        font-size: 11px;
        font-weight: bold;
        padding: 0;
        display: block;
        text-transform: uppercase;
        color: #fff;
    }
    .topbar_fix .topbar.courses,
    .topbar_fix .topbar.brochure {
        width: 35%;
    }
    .topbar_fix .topbar.offers {
        width: 30%;
        font-weight: bold;
        background: #00ffd5;
        color: #002e6a;
        border-right: 1px solid #fff;
        border-left: 1px solid #fff;
    }
    .offer_color {
        font-weight: bold;
        color: #002e6a;
    }
}
